import React from "react";
import {BasePageParameters, LdData, PageRoute} from "@reapptor-apps/reapptor-react-common";
import {PageContainer, PageRow, TwoColumns, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import ProductCard, {ProductCardMode} from "@/components/ProductCard/ProductCard";
import AnonymousPage from "@/models/base/AnonymousPage";
import PageDefinitions from "@/providers/PageDefinitions";
import Product from "@/models/server/products/Product";
import ListProductsResponse from "@/models/server/responses/ListProductsResponse";
import PortalController from "@/pages/PortalController";
import Localizer from "@/localization/Localizer";

import styles from "./Products.module.scss";

interface IProductsProps extends BasePageParameters {
}

interface IProductsState {
    products: Product[];
    productsLdData: LdData | null;
}

export default class Products extends AnonymousPage<IProductsProps, IProductsState> {
    
    state: IProductsState = {
        products: [],
        productsLdData: null,
    };

    private get products(): Product[] {
        return this.state.products;
    }

    private get productsLdData(): LdData | null {
        return this.state.productsLdData;
    }

    public async listProductsAsync(): Promise<ListProductsResponse> {
        return await this.postAsync("/api/product/listProducts");
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const response: ListProductsResponse = await this.listProductsAsync();

        await this.setState({products: response.products ?? [], productsLdData: response.productsLdData});
    }

    public renderProducts(products: Product[], index: number): React.ReactNode {
        return (
            <TwoColumns className={"mb-5"} key={index}>
                {
                    products.map((product: Product) =>
                        <ProductCard key={product.id}
                                     mode={ProductCardMode.Preview}
                                     product={product}
                        />
                    )
                }
            </TwoColumns>
        );
    }

    public static routeResolver(pathname: string): PageRoute | null {
        const page: string = "/products";
        pathname = pathname.toLowerCase();

        if ((pathname == page) || (pathname == `${page}/`)) {
            return PageDefinitions.productsRoute;
        }

        return null;
    }

    public getTitle(): string {
        return PortalController.getFullPageTitle(Localizer.topNavProducts);
    }

    public render(): React.ReactNode {
        
        const products: Product[] = [...this.products];
        const productRows = [], size = 2;
        while (products.length > 0) {
            productRows.push(products.splice(0, size));
        }

        return (
            <PageContainer className={styles.products} fullHeight>

                <LdDataHeader data={this.productsLdData}
                              canonical={"/products"}
                />

                <PageRow>
                
                    {
                        (productRows) &&
                        (
                            <div id="products" className={"w-100 justify-content-center"}>
                                {
                                    productRows.map((products: Product[], index: number) => this.renderProducts(products, index))
                                }
                            </div>
                        )
                    }
                    
                </PageRow>

            </PageContainer>
        );
    }
}