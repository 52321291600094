import {PageRouteProvider, ServerError} from "@reapptor-apps/reapptor-react-common";
import PortalConstants from "@/PortalConstants";

export class AvoinDataResponse {
    public results: AvoinDataResponseItem[] = [];
}

export class AvoinDataResponseItem {
    public businessId: string = "";

    public companyForm: string = "";

    public detailsUri: string = "";

    public registrationDate: string = "";

    public name: string = "";

    public addresses: AvoinAddress[] | null = null;
}

enum ExecutionStatus {
    NotStarted,
    
    Ongoing,
    
    Completed,
}

class ExecutionItem {
    public endpoint: string = "";
    
    public httpRequest: RequestInit;
    
    public status: ExecutionStatus = ExecutionStatus.NotStarted;
    
    constructor(endpoint: string, httpRequest: RequestInit) {
        this.endpoint = endpoint;
        this.httpRequest = httpRequest;
    }
}

class AvoinAddress {
    public careOf: string | null = null;
    
    public city: string | null = null;
    
    public country: string | null = null;
    
    public endDate: string | null = null;
    
    public language: string | null = null;
    
    public postCode: string | null = null;
    
    public street: string | null = null;
    
    public registrationDate: string | null = null;
    
    public source: number = 0;
    
    public type: number = 0;
    
    public version: number = 0;
}

export default class AvoinDataClient {
    
    private static queue: ExecutionItem[] = [];
    
    public static async listCompaniesByNameAsync(name: string): Promise<AvoinDataResponse> {

        const endpoint: string = "https://avoindata.prh.fi/bis/v1?" + "name=" + name;
        const httpRequest = { url: endpoint,  method: "GET"} as RequestInit;
        
        return await AvoinDataClient.avoinDataCallAsync(endpoint, httpRequest);
    }

    public static async listCompaniesByVatIdAsync(vatId: string): Promise<AvoinDataResponse> {

        const endpoint: string = "https://avoindata.prh.fi/bis/v1?" + "businessId=" + vatId;
        const httpRequest = {url: endpoint, method: "GET"} as RequestInit;

        return await AvoinDataClient.avoinDataCallAsync(endpoint, httpRequest);
    }

    public static async extractAddressAsync(item: AvoinDataResponseItem | null): Promise<string | null> {
        
        if (item) {

            if ((item.addresses) && (item.addresses.length > 0)) {
                let address: AvoinAddress | null = item.addresses.find(item => (item) && (item.type == 1) && (!item.endDate)) || null;
                address = address || item.addresses.find(item => (item) && (item.type == 2) && (!item.endDate)) || null;
                if (address) {
                    const country: string = address.country || "Finland";
                    return `${address.street}, ${address.postCode} ${address.city}, ${country}`;
                }
                
                return null;
            }

            if (item.detailsUri) {
                try {
                    let detailsUri = item.detailsUri;
                    if (detailsUri.startsWith("http:")) {
                        detailsUri = detailsUri.replace("http:", "https:");
                    }
                    const httpResponse: Response = await fetch(detailsUri);

                    if (httpResponse.status == PortalConstants.okStatusCode) {
                        const textResponse: string = await httpResponse.text();

                        if (textResponse) {
                            const response: AvoinDataResponse | null = JSON.parse(textResponse);

                            const itemWithDetails: AvoinDataResponseItem | null = response?.results[0] ?? null;

                            if (itemWithDetails) {
                                itemWithDetails.detailsUri = "";

                                return AvoinDataClient.extractAddressAsync(itemWithDetails);
                            }
                        }
                    }
                } catch (e) {
                    await PageRouteProvider.exception(e as Error);

                    return null;
                }
            }
        }
        
        return null;
    }
    
    private static async avoinDataCallAsync(endpoint: string, httpRequest: RequestInit): Promise<AvoinDataResponse> {
        let apiResponse: any | null = null;
        let avoinDataResponse: AvoinDataResponse = new AvoinDataResponse();

        const executionItem: ExecutionItem = new ExecutionItem(endpoint, httpRequest);
        this.queue.push(executionItem);

        while (this.queue.length > 0) {
            
            if (this.queue.find(item => item.status == ExecutionStatus.Ongoing)) {
                await this.timeout(100);
                continue;
            }

            const item: ExecutionItem = this.queue[0];
            item.status = ExecutionStatus.Ongoing;

            try {
                const httpResponse: Response = await fetch(item.endpoint, item.httpRequest);
                
                this.queue.remove(item);
                apiResponse = await this.processServerResponseAsync(httpResponse);
            } catch (e) {
                return new AvoinDataResponse();
            }

            avoinDataResponse = apiResponse as AvoinDataResponse;
            return avoinDataResponse;
        }
        
        return avoinDataResponse;
    }    
    
    private static timeout(delay: number): Promise<void> {
        return new Promise( handler => setTimeout(handler, delay) );
    }

    private static async processServerResponseAsync(httpResponse: Response): Promise<any | null> {

        const textResponse: string = await httpResponse.text();
        const endpoint: string = httpResponse.url;

        const hasResponse = (textResponse.length > 0);
        const isHtml = (hasResponse) && ((textResponse.startsWith("<!DOCTYPE html>") || (textResponse.startsWith("<!doctype html>"))));
        if (isHtml) {
            const debugDetails: string = `Server returned HTML instead of JSON response, probably requested api action not found.`;

            const serverError: ServerError = {
                requestId: "",
                debugDetails: debugDetails + `\nEndpoint: "${endpoint}"\nServer response code: "${httpResponse.status}".\nServer response:\n${textResponse.trim()}}`
            };

            await PageRouteProvider.error(serverError);
        }

        const jsonResponse: any | null = (hasResponse) ? JSON.parse(textResponse) : null;
        return jsonResponse;
    }
}