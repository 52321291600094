// autogenerated
import {IIconProps} from "@reapptor-apps/reapptor-react-components";
import {InfraEnvironmentType, EnvironmentStatus} from "@/models/Enums";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import CustomerApplicationEnvironment from "@/models/server/infraautomation/CustomerApplicationEnvironment";
import OrganizationContract from "@/models/server/OrganizationContract";

export default class CustomerApplication {

    public id: string = "";

    public name: string = "";

    public fullName: string | null = null;

    public createdAt: Date = new Date();

    public modifiedAt: Date = new Date();

    public deleted: boolean = false;

    public parameters: CustomerApplicationParameters | null = null;

    public environments: CustomerApplicationEnvironment[] | null = null;

    public organizationContract: OrganizationContract | null = null;

    public manuallyBoundAwsResourceIds: string[] | null = null;

    public readonly isCustomerApplication: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplication) || ((from as any).isCustomerApplication === true));
    }

    public static as(from?: object | null): CustomerApplication | null {
        return (CustomerApplication.is(from)) ? from as CustomerApplication : null;
    }

    // #region Placeholder

    public static getEnvironmentStatusIcon(application: CustomerApplication, type: InfraEnvironmentType): IIconProps {
        const environment: CustomerApplicationEnvironment | null = application.environments?.find(item => item.type == type) || null;
        const status: EnvironmentStatus = environment?.status || EnvironmentStatus.NotInitialized;

        switch (status) {
            case EnvironmentStatus.InProgress:
                return {name: "far spinner", className: "text-primary", tooltip: "{0:EnvironmentStatus}".format(EnvironmentStatus.InProgress)};
            case EnvironmentStatus.Success:
                return {name: "far check", className: "text-success", tooltip: "{0:EnvironmentStatus}".format(EnvironmentStatus.Success)};
            case EnvironmentStatus.Failed:
                return {name: "far times", className: "text-danger", tooltip: "{0:EnvironmentStatus}".format(EnvironmentStatus.Failed)};
            default:
                return {name: "far minus", className: "text-black", tooltip: "{0:EnvironmentStatus}".format(EnvironmentStatus.NotInitialized)};
        }
    }

    // #endregion
}