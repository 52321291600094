import React from "react";
import {Button, Form, TextAreaInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import {ch} from "@reapptor-apps/reapptor-react-common";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface IApplicationSummaryProps {
}

interface IApplicationSummaryState {
}

export default class ApplicationSummary extends CustomerApplicationWizardPage<IApplicationSummaryProps, IApplicationSummaryState> {

    state: IApplicationSummaryState = {
    };
    
    private get summary(): string {
        const application: string = (!!this.customerParameters.applicationGroup)
            ? `${this.customerParameters.applicationGroup}.${this.customerParameters.applicationShortName}`
            : this.customerParameters.applicationShortName!;
        const organizationName: string = (!!this.customerParameters.organizationShortName)
            ? `${this.customerParameters.organizationName}(${this.customerParameters.organizationShortName})`
            : `${this.customerParameters.organizationName}`;
        const companyInfo: string = this.wizard.isNewOrganization ? 
            `\n${Localizer.wizardSummaryStepVatId} ${this.wizard.newOrganization.vatId}\n`+
            `${Localizer.wizardSummaryStepAddress} ${this.removeCoordinatesFromAddress(this.wizard.newOrganization.formattedAddress)}\n` : "";
        const socialMediaLinks = this.socialMediaLinksSummary ? `${Localizer.wizardSummaryStepSocialMediaLinks} ${this.socialMediaLinksSummary}` : ``;
        
        return `${Localizer.wizardSummaryStepCompany} {0}{1}\n${Localizer.wizardSummaryStepApplication} {2}\n{3}\n{4}`.format(
            organizationName,
            companyInfo,
            application,
            this.customerParameters.applicationDescription,
            socialMediaLinks
        );
    }
    
    private removeCoordinatesFromAddress(fullAddress: string | null): string {
        return fullAddress ? fullAddress.split(",").slice(0, -2).join(",") : "";
    }
    
    private get socialMediaLinksSummary(): string {
        return (this.customerParameters.links?.facebookUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepFacebook} ${this.customerParameters.links.facebookUrl}` : ``) +
            (this.customerParameters.links?.instagramUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepInstagram} ${this.customerParameters.links.instagramUrl}` : ``) +
            (this.customerParameters.links?.youtubeUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepYouTube} ${this.customerParameters.links.youtubeUrl}` : ``) +
            (this.customerParameters.links?.linkedinUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepLinkedIn} ${this.customerParameters.links.linkedinUrl}` : ``) +
            (this.customerParameters.links?.twitterUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepTwitter} ${this.customerParameters.links.twitterUrl}` : ``) +
            (this.customerParameters.links?.discordUrl ? `\n    • ${Localizer.wizardSocialMediaLinksStepDiscord} ${this.customerParameters.links.discordUrl}` : ``);
    }

    public getTitle(): string {
        return Localizer.wizardApplicationSummaryStepTitle;
    }

    public async nextAsync(): Promise<void> {
        if (await ch.confirmAsync(Localizer.wizardSummaryStepAppCreate)) {
            await super.nextAsync();
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerParameters(): CustomerApplicationParameters {
        return PortalController.wizard.customerParameters;
    }

    public renderButtons(): React.ReactNode {
        return (
            <React.Fragment>
                <Button block
                        className={wizardStyles.outlinedButton}
                        label={Localizer.genericBack?.toUpperCase()}
                        onClick={() => this.prevAsync()}
                        icon={ {name: "fa-angle-left"} }
                />
                <Button block
                        className={wizardStyles.filledButton}
                        label={Localizer.genericCreate?.toUpperCase()}
                        onClick={() => this.nextAsync()}
                />
            </React.Fragment>
        );
    }


    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.applicationSummary}>

                <TextAreaInput readonly 
                               rows={20}
                               className={wizardStyles.item}
                               value={this.summary}
                />

            </Form>
        );
    }
}