import {BaseTransformProvider, Utility, GeoLocation, TFormat, ILanguage} from "@reapptor-apps/reapptor-toolkit";
import {CountryInfo} from "@reapptor-apps/reapptor-react-common";
import {SelectListGroup, SelectListItem, StatusListItem, ITitleModel} from "@reapptor-apps/reapptor-react-components";
import Organization from "../models/server/Organization";
import User from "../models/server/User";
import UserRole from "../models/server/UserRole";
import OrganizationContract from "../models/server/OrganizationContract";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../localization/Localizer";

import daFlag from "../images/flags/da.png";
import deFlag from "../images/flags/de.png";
import enFlag from "../images/flags/en.png";
import fiFlag from "../images/flags/fi.png";
import nbFlag from "../images/flags/nb.png";
import noFlag from "../images/flags/no.png";
import plFlag from "../images/flags/pl.png";
import svFlag from "../images/flags/sv.png";
import ruFlag from "../images/flags/ru.png";
import ukFlag from "../images/flags/uk.png";
import frFlag from "../images/flags/fr.png";
import itFlag from "../images/flags/it.png";
import usFlag from "../images/flags/us.png";

const flags: any = {
    da: daFlag,
    de: deFlag,
    en: enFlag,
    fi: fiFlag,
    fr: frFlag,
    it: itFlag,
    nb: nbFlag,
    no: noFlag,
    pl: plFlag,
    ru: ruFlag,
    se: svFlag,
    sv: svFlag,
    uk: ukFlag,
    us: usFlag,
};

class TransformProvider extends BaseTransformProvider {

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public constructor() {
        super();
    }

    public toImage(language: ILanguage): string {
        return flags[language.code];
    }

    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {
            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public userToString(item: User): string {
        const firstname: string = (item.firstname != null) ? item.firstname : "";
        const lastName: string = (item.lastName != null) ? item.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.username;
    }

    public userRoleToString(item: UserRole): string {
        const languageTerm: string = `RoleName.${item.roleName}`;
        return Localizer.contains(languageTerm)
            ? Localizer.get(languageTerm)
            : item.roleName;
    }

    public countryToString(item: CountryInfo, defaultName: string | null = null): string {
        const countryLanguageTerm: string = `Country.${item.englishName}`;
        return Localizer.contains(countryLanguageTerm)
            ? Localizer.get(countryLanguageTerm)
            : defaultName || item.englishName || item.name;
    }

    public organizationToString(item: Organization): string {
        return (item.vatId)
            ? `${item.name}, ${item.vatId}`
            : item.name;
    }

    public organizationContractToString(item: OrganizationContract): string {
        return (item.externalId)
            ? `${item.name}, ${item.externalId}`
            : item.name;
    }

    public locationToString(location: GeoLocation | null) {
        return (location != null)
            ? [location.address, location.postalCode, location.city].filter(item => !!item).join(", ")
            : "";
    }
    
    public toString(item: any, format?: TFormat | null): string {
        
        if (item == null) {
            return "";
        }

        if ((item instanceof Organization) || item.isOrganization) {
           return this.organizationToString(item as Organization);
        }

        if ((item instanceof OrganizationContract) || item.isOrganizationContract) {
            return this.organizationContractToString(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.userToString(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole === true)) {
            return this.userRoleToString(item as UserRole);
        }

        if ((item instanceof CountryInfo) || (item.isCountryInfo === true)) {
            return this.countryToString(item as CountryInfo);
        }

        if ((item instanceof GeoLocation) || item.isGeoLocation) {
            return this.locationToString(item as GeoLocation);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item instanceof Organization) || (item.isOrganization)) {
            return this.toOrganizationListItem(item as Organization);
        }

        if ((item instanceof OrganizationContract) || (item.isOrganizationContract)) {
            return this.toOrganizationContractListItem(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.toUserRoleListItem(item as UserRole);
        }

        if ((item instanceof CountryInfo) || (item.isCountryInfo)) {
            return this.toCountryInfoListItem(item as CountryInfo);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public toUserListItem(item: User): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.subtext = item.email ?? item.phone ?? "";
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;
        if(item.role != null && item.role.group != null){
            selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.role.group));
        }

        return selectedItem;
    }

    public toUserRoleListItem(item: UserRole): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.roleName;
        selectedItem.text = Localizer.get(`RoleName.${item.roleName}`);
        selectedItem.group = SelectListGroup.create(EnumProvider.getUserRoleGroupName(item.group));
        return selectedItem;
    }

    public toCountryInfoListItem(item: CountryInfo): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.code;
        selectedItem.text = this.countryToString(item, item.name);
        selectedItem.subtext = `${item.englishName} (${item.code})`;
        return selectedItem;
    }

    public toOrganizationListItem(item: Organization): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.vatId;
        selectedItem.subtext = item.vatId;
        selectedItem.favorite = item.favorite;        
        return selectedItem;
    }
    
    public toOrganizationContractListItem(item: OrganizationContract): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.externalId;
        selectedItem.subtext = item.externalId;
        return selectedItem;
    }
}

export default new TransformProvider();