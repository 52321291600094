import {Utility} from "@reapptor-apps/reapptor-toolkit";
import CustomerApplicationWizardContext from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardContext";

export default class Context {

    public wizard: CustomerApplicationWizardContext = new CustomerApplicationWizardContext();

    public static parse(json: string): Context {
        const to: Context = new Context();
        const from: Context = JSON.parse(json);
        Utility.restoreDate(from);
        Utility.copyTo(from, to);
        return to;
    }
}