
export enum MonitorEventType {
    Down,

    Up,

    NewRelease,
}

export enum ProductAccessoryType {
    /// <summary>
    /// Expendable, auxiliary products (the products are needed for that product)
    /// </summary>
    RelatedProduct,

    /// <summary>
    /// The similar products (the products are similar to that product).
    /// </summary>
    CorrespondingProduct
}

export enum ProductPeriodType {
    OneTime,

    Hourly,

    Monthly
}

export enum ProductInvoicingModel {
    /// <summary>
    /// Customer receives an invoice at the end of each month for product he used for the previous month. 
    /// </summary>
    Monthly,

    /// <summary>
    /// Customer receives an invoice after a service (product) has been provided to be paid withing a given period of time.
    /// </summary>
    Instant,

    /// <summary>
    /// Customer must register a payment method before using a service.
    /// </summary>
    DirectPayment
}

export enum ProductUnit {
    //Enum.ProductUnit.Hour
    Hour,

    Feature,

    Package,

    Project,

    Prototype,

    License,

    Application
}

export enum InvoicingType {
    Monthly,

    OneTimeFee,

    EachTransaction
}

export enum DayOfWeek {
    Sunday,

    Monday,

    Tuesday,

    Wednesday,

    Thursday,

    Friday,

    Saturday
}

export enum EnvironmentStatus {
    NotInitialized,

    InProgress,

    Failed,

    Success
}

export enum RepositoryType {
    Application,

    Deploy,

    TerraformBase,

    TerraformApplication
}

export enum AccessPermissions {
    Read,

    Write
}

export enum BitbucketBranchName {
    Master,

    Release,

    Staging,

    Production
}

export enum TerraformVariableCategory {
    Environment,

    Terraform
}

export enum InfraEnvironmentType {
    Test,

    Staging,

    Production
}

export enum InfraTaskType {
    CreateDevelopment,

    CreateStaging,

    CreateProduction
}

export enum InfraAutomationStepType {
    CreateBitbucketProject,

    CloneBitbucketRepository,

    RefactorSourceCode,

    CreateRepositoryDeploymentsEnvironmentVariables,

    CreateRepositoryGlobalVariables,

    CreateTerraformWorkspace,

    PushTerraformVariables,

    RunTerraform,

    RunBuiLdDeployInBitbucket
}

export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp,

    /**
     * Token already used
     */
    TokenUsed,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired
}

export enum WorkDayState {
    Normal = 0,

    /**
     * Sairas
     */
    SickLeave = 1,

    /**
     * Loma
     */
    Vacation = 2,

    /**
     * Vko lepo
     */
    WeeklyRest = 3,

    /**
     * Maksullinen vapaapäivä
     */
    PaidDayOff = 4,

    /**
     * Pekkanen
     */
    FlexHours = 5,
}

export enum UserRoleGroup {
    Admins,

    Managers,

    Employees,

    ContactPersons
}

export enum InvitationType {
    Invitation,

    ResetPassword,

    ForgotPassword
}

export enum NotificationType {
    Sms,

    Application,

    Push,

    Email
}

export enum AuthType {
    Email,

    Phone
}

export enum AuditTimestamp {
    CreatedAt,

    ModifiedAt
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum OrganizationContractLevel {
    Operator = 0,

    Company = 1,

    SubCompany = 2
}

export enum OrganizationContractType {
    Customer = 0,

    Subcontractor = 1
}

export enum ApplicationLinkType {
    BitbucketProject,

    BitbucketApplicationRepository,

    BitbucketDeployRepository,

    OnePassword,

    TerraformBase,

    TerraformApplication,

    AWS,

    Application,

    Splunk,
}

export enum ResourceCostType
{
    Daily,

    Monthly
}

export enum ResourceCostGroup
{
    Undefined,

    Database,

    Redis,

    S3Bucket,

    CloudTrail,

    CloudWatch,

    Firewall,

    ElasticComputeCloud,

    ElasticLoadBalancing,

    ElasticContainerService,

    GuardDuty,

    Lightsail,

    Pinpoint,

    XRay,

    OnePassword,

    Bitbucket,

    Splunk
}

export enum ResourceCostCloud
{
    Undefined,

    Aws,

    OnePassword,

    Bitbucket,

    Splunk
}

export enum ResourceCostShare
{
    Generic,

    Customer,

    Application
}

export enum ResourceType {
    Daily,

    Monthly
}

export enum ResourceGroup {
    Undefined,

    Database,

    Redis,

    S3Bucket,

    CloudTrail,

    CloudWatch,

    Firewall,

    ElasticComputeCloud,

    ElasticLoadBalancing,

    ElasticContainerService,

    GuardDuty,

    Lightsail,

    Pinpoint,

    XRay,

    OnePassword,

    Bitbucket,

    Splunk
}

export enum ResourceCloud {
    Undefined,

    Aws,

    OnePassword,

    Bitbucket,

    Splunk
}

export enum ResourceShare {
    Generic,

    Customer,

    Application
}

export enum AwsResourceGroup {
    Rds,

    Redis,

    S3Bucket,

    Ec2,

    CloudFront,

    CloudWatch,

    Other,
}

export enum AwsResourceSharingStatus
{
    Application,

    Customer,

    Platform,
}

export enum ResourceEnvironment {
    Dev,

    Prod
}

export enum MonitorType
{
    HealthCheck,
}

export enum MonitorOccurrenceState
{
    Started,

    Completed
}