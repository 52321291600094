import {ApiProvider, ch, IBasePage, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {IFooterLink, ITopNavProfile, IWizardStep, IWizardSteps} from "@reapptor-apps/reapptor-react-components";
import UserContext from "../models/server/UserContext";
import User from "@/models/server/User";
import PortalConstants from "@/PortalConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import CustomerApplicationWizardContext from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardContext";
import Context from "@/pages/Context";
import Comparator from "@/helpers/Comparator";
import CreateApplicationRequest from "@/models/server/requests/CreateApplicationRequest";
import CreateApplicationResponse from "@/models/server/responses/CreateApplicationResponse";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import ApplicationContext from "@/models/server/ApplicationContext";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

export interface IWizardNextStep {
    nextRoute: PageRoute;

    firstStep: boolean;

    lastStep: boolean;
}

class PortalController {
    private _key: string | null = null;
    private _initialized: boolean = false;
    private _initializing: boolean = false;
    private _context: Context | null = null;

    private get key(): string {
        if (this._key == null) {
            this._key = `${PortalConstants.applicationShortName}.${ch.getSessionId()}.${PortalConstants.name}`;
        }
        return this._key!;
    }

    private indexOfCurrentStep(route: PageRoute): number {
        const wizard: IWizardSteps | null = this.getSteps();
        return (wizard)
            ? wizard.steps.findIndex(step => Comparator.isEqual(step.route, route))
            : -1;
    }

    private extractRawDataFromFileSrc(value: string | null): string | null {
        return value?.split(",")[1] ?? null;
    }
    
    private formatFilesSourceStrings(params: CustomerApplicationParameters): void {
        // Removing data:image/png;base64, from base64 string
        if (params.images) {
            params.images.companyLogo = this.extractRawDataFromFileSrc(params.images.companyLogo);
            params.images.applicationLogoLight = this.extractRawDataFromFileSrc(params.images.applicationLogoLight);
            params.images.applicationLogoDark = this.extractRawDataFromFileSrc(params.images.applicationLogoDark);
            params.images.backgroundLandscapeLarge = this.extractRawDataFromFileSrc(params.images.backgroundLandscapeLarge);
            params.images.backgroundLandscapeSmall = this.extractRawDataFromFileSrc(params.images.backgroundLandscapeSmall);
            params.images.backgroundPortraitLarge = this.extractRawDataFromFileSrc(params.images.backgroundPortraitLarge);
            params.images.backgroundPortraitSmall = this.extractRawDataFromFileSrc(params.images.backgroundPortraitSmall);
            params.images.favicon = this.extractRawDataFromFileSrc(params.images.favicon);
            params.images.logoIconLarge = this.extractRawDataFromFileSrc(params.images.logoIconLarge);
            params.images.logoIconSmall = this.extractRawDataFromFileSrc(params.images.logoIconSmall);
        }
        if (params.fonts?.primaryFontSourceFile) {
            params.fonts.primaryFontSourceFile = this.extractRawDataFromFileSrc(params.fonts.primaryFontSourceFile.toString());
        }
        if (params.fonts?.secondaryFontSourceFile) {
            params.fonts.secondaryFontSourceFile = this.extractRawDataFromFileSrc((params.fonts.secondaryFontSourceFile.toString()));
        }
    }

    public async authorizeAsync(): Promise<void> {
        if (!this._initializing) {
            this._key = null;
            this._initialized = false;
            await this.initializeAsync();
        }
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            try {
                // Init All Dependencies
                EnumProvider.initialize();
                TransformProvider.initialize();
                Localizer.initialize();
                PageDefinitions.initialize();
                
                this._initialized = true;
            } finally {
                this._initializing = false;
            }
        }
    }

    public async onLogoClickAsync(): Promise<void> {
        const route: PageRoute = (ch.isAuthenticated) ? PageDefinitions.dashboardRoute : PageDefinitions.homeRoute;
        await PageRouteProvider.redirectAsync(route, false, true);
    }

    public get context(): Context {
        if (this._context === null) {
            const json: string | null = window.localStorage.getItem(this.key);
            this._context = (json)
                ? Context.parse(json!)
                : new Context();
        }

        return this._context!;
    }

    public get wizard(): CustomerApplicationWizardContext {
        return this.context.wizard;
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    public get user(): User {
        return ch.getUser();
    }

    public get footerLinks(): IFooterLink[] {
        return [
            {href: "https://reapptor.com/#contact", label: Localizer.componentFooterContactLanguageItemName},
        ];
    }
    
    public get profile(): ITopNavProfile | null {
        if (ch.isAuthenticated) {
            return {
                userProfile: false,
                items: [
                    {
                        route: PageDefinitions.accountRoute,
                        icon: "fal fa-cog",
                        label: Localizer.topNavAccountLanguageItemName,
                    },
                    {
                        route: PageDefinitions.logoutRoute,
                        icon: "fal fa-sign-out-alt",
                        label: Localizer.topNavLogoutLanguageItemName,
                    }
                ]
            };
        }
        return null;
    }

    public async startActionAsync(): Promise<boolean> {
        const page: IBasePage = ch.getPage();

        const initialPageRoute: PageRoute = page.route;

        this.context.wizard = new CustomerApplicationWizardContext();
        this.context.wizard.actionInitialPageRoute = initialPageRoute;

        this.saveContext();

        const nextStep: IWizardNextStep = await this.getNextStepAsync(initialPageRoute);

        await PageRouteProvider.redirectAsync(nextStep.nextRoute);

        return true;
    }

    public async completeActionAsync(): Promise<void> {

        this.formatFilesSourceStrings(this.wizard.customerParameters);

        const request = new CreateApplicationRequest();
        request.parameters = this.wizard.customerParameters;

        if (this.wizard.isNewOrganization) {
            request.createOrganization = this.wizard.newOrganization;
        } else {
            request.organizationContractId = this.wizard.organizationContractId;
        }

        const response: CreateApplicationResponse = await ApiProvider.postAsync("/api/managementConsole/createApplication", request);

        const route: PageRoute = await PageDefinitions.applicationLogs(response.applicationId);

        await PageRouteProvider.redirectAsync(route);

        //TODO: Process wizard actions
        this.context.wizard = new CustomerApplicationWizardContext();

        this.saveContext();
    }

    public getFullPageTitle(title: string): string {
        const context: ApplicationContext | null = ch.findContext();
        const name: string = context?.applicationName || PortalConstants.applicationTitle;
        return (!!title)
            ? `${title} - ${name}`
            : name;
    }

    public saveContext(): void {
        const json: string = JSON.stringify(this.context);
        window.localStorage.setItem(this.key, json);
    }

    public getSteps(): IWizardSteps | null {
        const selectOrganizationStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardSelectOrganizationRoute, title: Localizer.wizardSelectOrganizationStepTitle};
        const selectCountryStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardSelectCountryRoute, title: Localizer.wizardSelectCountryStepTitle};
        const createOrganizationStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardCreateOrganizationRoute, title: Localizer.wizardCreateOrganizationStepTitle};
        const applicationStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardApplicationRoute, title: Localizer.wizardApplicationStepTitle};
        const companyLogoStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardCompanyLogoRoute, title: Localizer.wizardCompanyLogoStepTitle};
        const applicationLogoStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardApplicationLogoRoute, title: Localizer.wizardApplicationLogoStepTitle};
        const backgroundPicturesStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardBackgroundPicturesRoute, title: Localizer.wizardBackgroundPicturesStepTitle};
        const iconsStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardIconsRoute, title: Localizer.wizardIconsStepTitle};
        const colorsStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardColorsRoute, title: Localizer.wizardColorsStepTitle};
        const fontsStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardFontsRoute, title: Localizer.wizardFontsStepTitle};
        const languagesStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardLanguagesRoute, title: Localizer.wizardLanguagesStepTitle};
        const socialMediaLinksStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardSocialMediaLinksRoute, title: Localizer.wizardSocialMediaLinksStepTitle};
        const applicationSettingsStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardApplicationSettingsRoute, title: Localizer.wizardApplicationSettingsStepTitle};
        const applicationSummaryStep: IWizardStep = {route: PageDefinitions.customerApplicationWizardApplicationSummaryRoute, title: Localizer.wizardApplicationSummaryStepTitle};

        const wizard: CustomerApplicationWizardContext = this.wizard;

        const isNewOrganization: boolean = wizard.isNewOrganization;

        const steps: IWizardStep[] = (isNewOrganization)
            ? [selectOrganizationStep, selectCountryStep, createOrganizationStep, applicationStep, companyLogoStep, applicationLogoStep,
                backgroundPicturesStep, iconsStep, colorsStep, fontsStep, languagesStep, socialMediaLinksStep, applicationSettingsStep, applicationSummaryStep]
            : [selectOrganizationStep, applicationStep, companyLogoStep, applicationLogoStep,
                backgroundPicturesStep, iconsStep, colorsStep, fontsStep, languagesStep, socialMediaLinksStep, applicationSettingsStep, applicationSummaryStep];
        
        return {steps: steps};
    }

    public async getPrevStepAsync(route: PageRoute): Promise<PageRoute> {
        const index: number = this.indexOfCurrentStep(route);
        const steps: IWizardStep[] = this.getSteps()!.steps;
        const unknownOrFirstStep: boolean = (index === -1) || (index === 0);
        if (unknownOrFirstStep) {
            return this.wizard.actionInitialPageRoute || PageDefinitions.customerApplicationWizardDashboardRoute;
        }
        const previousStepIndex: number = index - 1;
        return steps[previousStepIndex].route;
    }

    public async getNextStepAsync(route: PageRoute): Promise<IWizardNextStep> {
        const index: number = this.indexOfCurrentStep(route);
        const steps: IWizardStep[] = this.getSteps()!.steps;
        const unknownOrFirstStep: boolean = (index === -1) && (steps.length > 0);
        if (unknownOrFirstStep) {
            return {nextRoute: steps[0].route, firstStep: true, lastStep: false};
        }
        const lastStep: boolean = (index === -1) || (index === steps.length - 1);
        if (lastStep) {
            const wizard: CustomerApplicationWizardContext = this.wizard;
            const nextRoute: PageRoute | null = (!Comparator.isEqualPageRoute(wizard.actionInitialPageRoute, route))
                ? wizard.actionInitialPageRoute
                : null;
            return {nextRoute: nextRoute || PageDefinitions.customerApplicationWizardDashboardRoute, firstStep: false, lastStep: true};
        }
        return {nextRoute: steps[index + 1].route, firstStep: false, lastStep: false};
    }

    public async convertImageAsync(file: FileModel): Promise<FileModel> {
        return await ApiProvider.postAsync("/api/files/convertImage", file);
    }
}

//Singleton
export default new PortalController();