import React from "react";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, WidgetContainer, RouteWidget} from "@reapptor-apps/reapptor-react-components";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import portalStyles from "../../../portal.module.scss";

interface IDashboardProps  {
}

interface IDashboardState {
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {
    
    private async newCustomerAsync(): Promise<void> {
        await PortalController.startActionAsync();
    }
    
    public getManual(): string {
        return Localizer.rentaTasksDashboardGetManual;
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent={this.desktop} className={portalStyles.pageContainer} alertClassName={portalStyles.alert}>

                <WidgetContainer>
                    
                    {/*<RouteWidget icon={{ name: "users" }} route={PageDefinitions.contactSupportRoute} label={"Contact support"} description={"Description"} />*/}
                    
                    <RouteWidget icon={{ name: "fad turtle" }}
                                 label={Localizer.dashboardPageCreateNewApplication}
                                 description={Localizer.dashboardPageCreateNewApplicationDescription}
                                 onClick={() => this.newCustomerAsync()}
                    />
                    
                    <RouteWidget icon={{ name: "users-cog" }} route={PageDefinitions.adminRoute} label={Localizer.topNavAdmin} description={Localizer.dashboardPageAdminDescription} />
                    
                </WidgetContainer>

            </PageContainer>
        );
    }
}