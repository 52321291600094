import React from "react";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnDefinition,
    ColumnType,
    Grid,
    IconSize,
    InlineType,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    Tab,
    TabContainer, TabRenderType,
    TextInput,
    ToolbarContainer, ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import GetApplicationsRequest from "@/models/server/requests/GetApplicationsRequest";
import CustomerApplication from "@/models/server/CustomerApplication";
import {ActionType, IBaseComponent, PageRoute, PageRouteProvider, TextAlign} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import CreateProductionRequest from "@/models/server/requests/CreateProductionRequest";
import CreateProductionResponse from "@/models/server/responses/CreateProductionResponse";
import {EnvironmentStatus, InfraEnvironmentType} from "@/models/Enums";
import CreateStagingResponse from "@/models/server/responses/CreateStagingResponse";
import CreateStagingRequest from "@/models/server/requests/CreateStagingRequest";
import RetryCreateApplicationRequest from "@/models/server/requests/RetryCreateApplicationRequest";
import ApplicationLinksPanel from "@/pages/Applications/ApplicationLinksPanel/ApplicationLinksPanel";
import ApplicationLogsPanel from "@/pages/Applications/ApplicationLogsPanel/ApplicationLogsPanel";
import CustomerApplicationEnvironment from "@/models/server/infraautomation/CustomerApplicationEnvironment";
import PortalController from "@/pages/PortalController";
import Localizer from "@/localization/Localizer";

import styles from "./Applications.module.scss";

interface IApplicationsProps {
}

interface IApplicationsState {
    showDeleted: boolean,
    search: string,
    selectedApplicationCell: CellModel<CustomerApplication> | null,
}

export default class Applications extends AuthorizedPage<IApplicationsProps, IApplicationsState> {

    state: IApplicationsState = {
        showDeleted: false,
        search: "",
        selectedApplicationCell: null,
    };
    
    private readonly _applicationsGridRef: React.RefObject<Grid<CustomerApplication>> = React.createRef();
    private readonly _appDetailsTabRef: React.RefObject<TabContainer> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 40,
            actions: [
                {
                    type: ActionType.Info,
                    icon: "fa-info-square far",
                    callback: async (cell) => await this.toggleApplicationDetailsAsync(cell)
                } as ColumnActionDefinition,
                // {
                //     name: "details",
                //     title: Localizer.applicationsPageButtonApplicationDetailsPageLanguageItemName,
                //     icon: "far info-circle",
                //     type: ActionType.Default,
                //     callback: (cell) => this.redirectToDetailsPageAsync(cell)
                // } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridApplicationLanguageItemName,
            header: Localizer.applicationsPageGridApplicationTitleLanguageItemName,
            accessor: "parameters.applicationName",
            minWidth: 200,
            settings: {
                infoAccessor: "parameters.applicationShortName",
            },
            route: (cell: CellModel<CustomerApplication>) => PageDefinitions.applicationLogs(cell.model.id)
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridApplicationLanguageItemName,
            header: Localizer.applicationsPageGridApplicationGroupLanguageItemName,
            accessor: "parameters.applicationGroup",
            minWidth: 150
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridCompanyLanguageItemName,
            header: Localizer.applicationsPageGridCompanyTitleLanguageItemName,
            accessor: "parameters.organizationName",
            minWidth: 200,
            settings: {
                infoAccessor: "parameters.organizationShortName",
            }
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridCompanyLanguageItemName,
            header: Localizer.applicationsPageGridCompanyVatidLanguageItemName,
            accessor: "organizationContract.organization.vatId",
            minWidth: 120,
        } as ColumnDefinition,
        {
            group: "DEV",
            header: "FE/BE Ports",
            accessor: "parameters.devFrontendPort",
            minWidth: 100,
            settings: {
                infoAccessor: "parameters.devBackendPort",
            }
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridEnvironmentStatusLanguageItemName,
            //header: "fas signal-1",
            header: "T",
            title: "{0:InfraEnvironmentType}".format(InfraEnvironmentType.Test),
            minWidth: 50,
            textAlign: TextAlign.Center,
            type: ColumnType.Icon,
            accessor: (model) => CustomerApplication.getEnvironmentStatusIcon(model, InfraEnvironmentType.Test),
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridEnvironmentStatusLanguageItemName,
            //header: "fas signal-2",
            header: "S",
            title: "{0:InfraEnvironmentType}".format(InfraEnvironmentType.Staging),
            minWidth: 50,
            textAlign: TextAlign.Center,
            type: ColumnType.Icon,
            accessor: (model) => CustomerApplication.getEnvironmentStatusIcon(model, InfraEnvironmentType.Staging),
        } as ColumnDefinition,
        {
            group: Localizer.applicationsPageGridEnvironmentStatusLanguageItemName,
            //header: "fas signal-3",
            header: "P",
            title: "{0:InfraEnvironmentType}".format(InfraEnvironmentType.Production),
            minWidth: 50,
            textAlign: TextAlign.Center,
            type: ColumnType.Icon,
            accessor: (model) => CustomerApplication.getEnvironmentStatusIcon(model, InfraEnvironmentType.Production),
        } as ColumnDefinition,
        {
            header: Localizer.genericActionsLanguageItemName,
            minWidth: 90,
            removable: false,
            init: (cell) => this.initApplicationOperations(cell),
            actions: [
                {
                    name: "production",
                    title: Localizer.applicationsPageButtonCreateEnvironmentLanguageItemName,
                    icon: "fab product-hunt",
                    type: ActionType.Create,
                    confirm: Localizer.applicationsPageCreateProductionEnvironmentConfirmLanguageItemName,
                    callback: async (cell, action) => await this.processApplicationOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "staging",
                    title: Localizer.applicationsPageButtonCreateEnvironmentLanguageItemName,
                    icon: "fab fa-stripe-s",
                    type: ActionType.Create,
                    confirm: Localizer.applicationsPageCreateStagingEnvironmentConfirmLanguageItemName,
                    callback: async (cell, action) => await this.processApplicationOperationAsync(cell, action)
                } as ColumnActionDefinition,
                {
                    name: "retry",
                    title: Localizer.applicationsPageButtonRetryEnvironmentLanguageItemName,
                    icon: "fas fa-registered",
                    type: ActionType.Edit,
                    confirm: Localizer.applicationsPageRetryCreateEnvironmentConfirmLanguageItemName,
                    callback: async (cell, action) => await this.processApplicationOperationAsync(cell, action)
                } as ColumnActionDefinition
            ]
        }
    ];

    private async fetchApplicationsAsync(sender: IBaseComponent, pageNumber: number, pageSize: number): Promise<CustomerApplication[]> {
        const request = new GetApplicationsRequest();
        request.showDeleted = this.state.showDeleted;
        request.search = this.state.search;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        return await sender.postAsync("/api/managementConsole/getApplications", request);
    }

    private async toggleApplicationDetailsAsync(cell: CellModel<CustomerApplication>): Promise<void> {
        if (this.state.selectedApplicationCell != null && this.state.selectedApplicationCell.model.id != cell.model.id && this.state.selectedApplicationCell.row.expanded) {
            await this.state.selectedApplicationCell.row.toggleAsync();
        }

        await this.setState({selectedApplicationCell: cell});
        await cell.row.toggleAsync();

        if (cell.row.expanded) {
            await this._appDetailsTabRef.current?.model.activateTabAsync("links");
        }
    }

    private async redirectToDetailsPageAsync(cell: CellModel<CustomerApplication>): Promise<void> {
        const customerApplication: CustomerApplication = cell.model;

        await PageRouteProvider.redirectAsync(PageDefinitions.applicationDetails(customerApplication.id));
    }

    private async addApplicationAsync(): Promise<void> {
        await PortalController.startActionAsync();
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async setSearchAsync(search: string): Promise<void> {
        await this.setState({search});
    }

    private initApplicationOperations(cell: CellModel<CustomerApplication>): void {
        const model: CustomerApplication = cell.row.model;
        const deleted: boolean = cell.row.deleted;

        const productionAction: CellAction<CustomerApplication> = cell.actions[0];
        const stagingAction: CellAction<CustomerApplication> = cell.actions[1];
        const retryAction: CellAction<CustomerApplication> = cell.actions[2];

        const testEnvironment: CustomerApplicationEnvironment | null = model.environments?.find(item => item.type == InfraEnvironmentType.Test) || null;
        const stagingEnvironment: CustomerApplicationEnvironment | null = model.environments?.find(item => item.type == InfraEnvironmentType.Staging) || null;
        const productionEnvironment: CustomerApplicationEnvironment | null = model.environments?.find(item => item.type == InfraEnvironmentType.Production) || null;

        const anyFailedEnvironment: boolean = model.environments?.some(item => item.status == EnvironmentStatus.Failed) ?? false;
 
        productionAction.visible = (
            (!deleted) && (testEnvironment?.status == EnvironmentStatus.Success) && (stagingEnvironment?.status == EnvironmentStatus.Success) &&
            ((productionEnvironment?.status != EnvironmentStatus.Success)) && (!anyFailedEnvironment)
        );
        stagingAction.visible = (testEnvironment?.status == EnvironmentStatus.Success) && (stagingEnvironment?.status != EnvironmentStatus.Success) && (!anyFailedEnvironment);
        retryAction.visible = (anyFailedEnvironment);
    }

    private async processApplicationOperationAsync(cell: CellModel<CustomerApplication>, action: CellAction<CustomerApplication>): Promise<void> {
        const model: CustomerApplication = cell.model;
        const customerApplicationId: string = model.id;
        let taskId: string;

        switch (action.action.name) {
            case "production":
                const productionRequest = new CreateProductionRequest(customerApplicationId);
                const productionResponse: CreateProductionResponse = await cell.grid.postAsync("/api/managementConsole/createProduction", productionRequest);
                taskId = productionResponse.applicationId
                break;

            case "staging":
                const stagingRequest = new CreateStagingRequest(customerApplicationId);
                const stagingResponse: CreateStagingResponse = await cell.grid.postAsync("/api/managementConsole/createStaging", stagingRequest);
                taskId = stagingResponse.taskId;
                break;

            case "retry":
                const retryRequest = new RetryCreateApplicationRequest(customerApplicationId);
                const retryResponse: CreateProductionResponse = await cell.grid.postAsync("/api/managementConsole/RetryCreateApplication", retryRequest);
                taskId = retryResponse.taskId;
                break;

            default:
                throw new TypeError(`Action name value '${action.action.name}' does not exist`);
        }

        const route: PageRoute = await PageDefinitions.applicationLogs(customerApplicationId, taskId);

        await PageRouteProvider.redirectAsync(route);
    }

    private async reloadAsync(): Promise<void> {
        if (this._applicationsGridRef.current) {
            await this._applicationsGridRef.current.reloadAsync();
        }
    }

    public getTitle(): string {
        return Localizer.applicationsPageTitle;
    }

    private renderApplicationDetailsContent(): React.ReactNode {
        return (
            <div>
                <TabContainer id={"applicationDetails"} ref={this._appDetailsTabRef} renderType={TabRenderType.ActiveOnly}>

                    <Tab id={"links"} title={Localizer.applicationTabApplicationLinks}>

                        <div>

                            <ApplicationLinksPanel customerApplicationId={this.state.selectedApplicationCell?.model?.id!}/>

                        </div>

                    </Tab>

                    <Tab id={"logs"} title={Localizer.applicationTabApplicationLogs}>

                        <div>

                            <ApplicationLogsPanel customerApplicationId={this.state.selectedApplicationCell?.model?.id!}/>

                        </div>

                    </Tab>

                </TabContainer>
            </div>
        );
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.applications}>

                <PageHeader title={Localizer.applicationsPageTitle}/>
                
                <PageRow>

                    <ToolbarContainer>

                        <ToolbarRow justify={JustifyContent.Start}>

                            <TextInput inline autoFocus noAutoComplete
                                       width="15rem"
                                       placeholder={Localizer.genericSearch}
                                       value={this.state.search}
                                       onChange={(_, value) => this.setSearchAsync(value)}
                            />

                            <Checkbox inline
                                      label={Localizer.applicationsPageToolbarShowDeleted}
                                      inlineType={InlineType.Right}
                                      value={this.state.showDeleted}
                                      onChange={async (sender, value) => await this.setShowDeletedAsync(value)}
                            />

                            <Button title={Localizer.applicationsPageToolbarReload}
                                    icon={{name: "far history", size: IconSize.Large}}
                                    small
                                    type={ButtonType.Info}
                                    onClick={async () => await this.reloadAsync()}
                            />

                            <Button icon={{name: "plus", size: IconSize.Large}}
                                    title={Localizer.applicationsPageToolbarPlus}
                                    small
                                    onClick={async () => await this.addApplicationAsync()}
                            />

                            <Button icon={{name: "fas arrow-alt-circle-left"}}
                                    title={Localizer.genericBack}
                                    small
                                    type={ButtonType.Primary}
                                    route={PageDefinitions.adminRoute}
                            />

                        </ToolbarRow>

                    </ToolbarContainer>
                    
                </PageRow>
                
                <PageRow>

                    <Grid id="applications" ref={this._applicationsGridRef} responsive
                          columns={this._columns}
                          pagination={10}
                          noDataText={Localizer.applicationsPageGridNoDataTextLanguageItemName}
                          renderDetails={() => this.renderApplicationDetailsContent()}
                          fetchData={(sender, pageNumber: number, pageSize: number) => this.fetchApplicationsAsync(sender, pageNumber, pageSize)}
                    />                    
                    
                </PageRow>

            </PageContainer>
        );
    }    
}