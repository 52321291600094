// autogenerated
import {UserRoleGroup} from "@/models/Enums";

export default class UserRole {

    public roleName: string = "";

    public group: UserRoleGroup = UserRoleGroup.Admins;

    public organizationContractId: string = "";

    public readonly isUserRole: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof UserRole) || ((from as any).isUserRole === true));
    }

    public static as(from?: object | null): UserRole | null {
        return (UserRole.is(from)) ? from as UserRole : null;
    }

    // #region Placeholder

    constructor(roleName: string = "", organizationContractId: string = "", group: UserRoleGroup = UserRoleGroup.Admins) {
        this.roleName = roleName;
        this.organizationContractId = organizationContractId;
        this.group = group;
    }

    // #endregion
}