import React from "react";
import {Form, ImageInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationImages from "@/models/server/infraautomation/CustomerApplicationImages";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface IBackgroundPicturesProps {
}

interface IBackgroundPicturesState {
}

export default class CompanyLogo extends CustomerApplicationWizardPage<IBackgroundPicturesProps, IBackgroundPicturesState> {

    state: IBackgroundPicturesState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync();
    }

    public getTitle(): string {
        return Localizer.wizardBackgroundPicturesStepTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerImages(): CustomerApplicationImages {
        return PortalController.wizard.customerParameters.images!;
    }

    public async setLandscapeLargePictureAsync(value: FileModel[]): Promise<void> {
        this.customerImages.backgroundLandscapeLarge = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }
    
    public async setLandscapeSmallPictureAsync(value: FileModel[]): Promise<void> {
        this.customerImages.backgroundLandscapeSmall = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }
    
    public async setPortraitLargePictureAsync(value: FileModel[]): Promise<void> {
        this.customerImages.backgroundPortraitLarge = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPortraitSmallPictureAsync(value: FileModel[]): Promise<void> {
        this.customerImages.backgroundPortraitSmall = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={wizardStyles.wizardStepForm}>
                
                <p>{Localizer.wizardBackgroundStepLandscapeLargePlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.backgroundLandscapeLarge || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxBackgroundImageSizeInBytes}
                            onChange={(sender, value) => this.setLandscapeLargePictureAsync(value)}
                />
                
                <p>{Localizer.wizardBackgroundStepLandscapeSmallPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.backgroundLandscapeSmall || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxBackgroundImageSizeInBytes}
                            onChange={(sender, value) => this.setLandscapeSmallPictureAsync(value)}
                />
                
                <p>{Localizer.wizardBackgroundStepPortraitLargePlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.backgroundPortraitLarge || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxBackgroundImageSizeInBytes}
                            onChange={(sender, value) => this.setPortraitLargePictureAsync(value)}
                />
                
                <p>{Localizer.wizardBackgroundStepPortraitSmallPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.backgroundPortraitSmall || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxBackgroundImageSizeInBytes}
                            onChange={(sender, value) => this.setPortraitSmallPictureAsync(value)}
                />
            </Form>
        );
    }
}