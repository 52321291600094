// autogenerated

export default class CustomerApplicationImages {

    public companyLogo: string | null = null;

    public applicationLogoLight: string | null = null;

    public applicationLogoDark: string | null = null;

    public backgroundLandscapeLarge: string | null = null;

    public backgroundLandscapeSmall: string | null = null;

    public backgroundPortraitLarge: string | null = null;

    public backgroundPortraitSmall: string | null = null;

    public favicon: string | null = null;

    public logoIconLarge: string | null = null;

    public logoIconSmall: string | null = null;

    public readonly isCustomerApplicationImages: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplicationImages) || ((from as any).isCustomerApplicationImages === true));
    }

    public static as(from?: object | null): CustomerApplicationImages | null {
        return (CustomerApplicationImages.is(from)) ? from as CustomerApplicationImages : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}