import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, WidgetContainer, RouteWidget} from "@reapptor-apps/reapptor-react-components";
import PortalController from "@/pages/PortalController";
import Localizer from "../../localization/Localizer";

import styles from "./Dashboard.module.scss";

interface IDashboardProps {
    name: string | null;
}

interface IDashboardState {
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {

    private async newCustomerAsync(): Promise<void> {
        await PortalController.startActionAsync();
    }

    public getManual(): string {
        return Localizer.dashboardGetManual;
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }
    
    public render(): React.ReactNode {
        
        return (
            <PageContainer transparent className={styles.dashboard} alertClassName={styles.alert}>
                
                <WidgetContainer controller="Dashboard" async>

                    <RouteWidget id="newApplication"
                                 icon={{ name: "shoe-prints" }}
                                 label={Localizer.dashboardPageCreateNewApplication}
                                 description={Localizer.dashboardPageCreateNewApplicationDescription}
                                 onClick={() => this.newCustomerAsync()}
                    />

                    <RouteWidget id="contactSupport"
                                 icon={{ name: "headset" }}
                                 route={PageDefinitions.contactSupportRoute}
                                 label={Localizer.topNavContactSupport}
                                 description={Localizer.dashboardPageContactSupportDescription} />

                    <RouteWidget id="admin"
                                 icon={{ name: "users-cog" }}
                                 route={PageDefinitions.adminRoute}
                                 label={Localizer.topNavAdmin}
                                 description={Localizer.dashboardPageAdminDescription}
                    />

                    <RouteWidget id="products"
                                 icon={{ name: "parachute-box" }}
                                 route={PageDefinitions.productsRoute}
                                 label={Localizer.topNavProducts}
                                 description={Localizer.widgetProductsDescription}
                    />

                    <RouteWidget id="monitors"
                                 icon={{name: "fa-monitor-heart-rate"}}
                                 route={PageDefinitions.monitorsRoute}
                                 label={Localizer.monitorsTitle}
                                 description={Localizer.widgetMonitorsDescription}
                    />

                </WidgetContainer>
                
            </PageContainer>
        );
    }
}