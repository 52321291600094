import {PageRoute, BasePageDefinitions, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {IApplicationLogsParameters} from "@/pages/ApplicationLogs/ApplicationLogs";

export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }
    
    constructor() {
        super();
        PageRouteProvider.sendParametersOnRedirect = true;
    }

    public static readonly logoutRouteName: string = "Logout";

    public static readonly dummyRouteName: string = "Dummy";

    public static readonly dummyRoute: PageRoute = new PageRoute(PageDefinitions.dummyRouteName);

    public static readonly accountRouteName: string = "Account";

    public static readonly accountRoute: PageRoute = new PageRoute(PageDefinitions.accountRouteName);

    public static readonly loginRouteName: string = "Login";

    public static readonly loginRoute: PageRoute = new PageRoute(PageDefinitions.loginRouteName);

    public static readonly adminRouteName: string = "Admin";

    public static readonly adminRoute: PageRoute = new PageRoute(PageDefinitions.adminRouteName);

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly errorRouteName: string = "Error";

    public static readonly errorRoute: PageRoute = new PageRoute(PageDefinitions.errorRouteName);

    public static readonly contactSupportRouteName: string = "ContactSupport";

    public static readonly contactSupportRoute: PageRoute = new PageRoute(PageDefinitions.contactSupportRouteName);

    public static readonly offlineRouteName: string = "Offline";

    public static readonly offlineRoute: PageRoute = new PageRoute(PageDefinitions.offlineRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";
    
    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);
    
    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);

    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);
    
    public static readonly resourcesCostsRouteName: string = "ResourcesCosts";

    public static readonly resourcesCostsRoute: PageRoute = new PageRoute(PageDefinitions.resourcesCostsRouteName);

    public static readonly applicationSettingsRouteName: string = "ApplicationSettings";

    public static readonly applicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.applicationSettingsRouteName);

    public static readonly applicationDetailsRouteName: string = "ApplicationDetails";

    public static readonly applicationDetailsRoute: PageRoute = new PageRoute(PageDefinitions.applicationDetailsRouteName);

    public static readonly applicationDetails: (customerApplicationId: string) => PageRoute = (customerApplicationId: string) => new PageRoute(PageDefinitions.applicationDetailsRouteName, null, customerApplicationId);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly applicationsRouteName: string = "Applications";

    public static readonly applicationsRoute: PageRoute = new PageRoute(PageDefinitions.applicationsRouteName);

    public static readonly applicationLogsRouteName: string = "ApplicationLogs";

    public static readonly applicationLogsRoute: PageRoute = new PageRoute(PageDefinitions.applicationLogsRouteName);

    public static readonly applicationLogs: (customerApplicationId: string, taskId?: string) => PageRoute = (customerApplicationId: string, taskId: string | null = null) => new PageRoute(PageDefinitions.applicationLogsRouteName, null, customerApplicationId, {customerApplicationId, taskId} as IApplicationLogsParameters);

    public static readonly monitorsRouteName: string = "Monitors";

    public static readonly monitorsRoute: PageRoute = new PageRoute(PageDefinitions.monitorsRouteName);
    
    public static readonly organizationContractsRouteName: string = "OrganizationContracts";

    public static readonly organizationContractsRoute: PageRoute = new PageRoute(PageDefinitions.organizationContractsRouteName);

    public static readonly productsRouteName: string = "Products";

    public static readonly productsRoute: PageRoute = new PageRoute(PageDefinitions.productsRouteName);

    public static readonly productDetailsRouteName: string = "ProductDetails";

    public static readonly productDetailsRoute: PageRoute = new PageRoute(PageDefinitions.productDetailsRouteName);

    public static readonly productDetails: (productIdOrPath: string) => PageRoute = (productIdOrPath: string) => new PageRoute(PageDefinitions.productDetailsRouteName, null, productIdOrPath);

    // #region New Customer Application Wizard

    public static readonly customerApplicationWizardDashboardRouteName: string = "CustomerApplicationWizard/Dashboard";

    public static readonly customerApplicationWizardDashboardRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardDashboardRouteName);

    public static readonly customerApplicationWizardSelectOrganizationRouteName: string = "CustomerApplicationWizard/SelectOrganization";

    public static readonly customerApplicationWizardSelectOrganizationRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardSelectOrganizationRouteName);

    public static readonly customerApplicationWizardSelectCountryRouteName: string = "CustomerApplicationWizard/SelectCountry";

    public static readonly customerApplicationWizardSelectCountryRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardSelectCountryRouteName);

    public static readonly customerApplicationWizardCreateOrganizationRouteName: string = "CustomerApplicationWizard/CreateOrganization";

    public static readonly customerApplicationWizardCreateOrganizationRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardCreateOrganizationRouteName);
    
    public static readonly customerApplicationWizardApplicationRouteName: string = "CustomerApplicationWizard/Application";

    public static readonly customerApplicationWizardApplicationRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardApplicationRouteName);
    
    public static readonly customerApplicationWizardSocialMediaLinksRouteName: string = "CustomerApplicationWizard/SocialMediaLinks";

    public static readonly customerApplicationWizardSocialMediaLinksRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardSocialMediaLinksRouteName);

    public static readonly customerApplicationWizardCompanyLogoRouteName: string = "CustomerApplicationWizard/CompanyLogo";
    
    public static readonly customerApplicationWizardCompanyLogoRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardCompanyLogoRouteName);
    
    public static readonly customerApplicationWizardApplicationLogoRouteName: string = "CustomerApplicationWizard/ApplicationLogo";

    public static readonly customerApplicationWizardApplicationLogoRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardApplicationLogoRouteName);
    
    public static readonly customerApplicationWizardBackgroundPicturesRouteName: string = "CustomerApplicationWizard/BackgroundPictures";

    public static readonly customerApplicationWizardBackgroundPicturesRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardBackgroundPicturesRouteName);
    
    public static readonly customerApplicationWizardIconsRouteName: string = "CustomerApplicationWizard/Icons";

    public static readonly customerApplicationWizardIconsRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardIconsRouteName);

    public static readonly customerApplicationWizardColorsRouteName: string = "CustomerApplicationWizard/Colors";

    public static readonly customerApplicationWizardColorsRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardColorsRouteName);

    public static readonly customerApplicationWizardFontsRouteName: string = "CustomerApplicationWizard/Fonts";

    public static readonly customerApplicationWizardFontsRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardFontsRouteName);

    public static readonly customerApplicationWizardLanguagesRouteName: string = "CustomerApplicationWizard/Languages";

    public static readonly customerApplicationWizardLanguagesRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardLanguagesRouteName);
    
    public static readonly customerApplicationWizardApplicationSettingsRouteName: string = "CustomerApplicationWizard/ApplicationSettings";

    public static readonly customerApplicationWizardApplicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardApplicationSettingsRouteName);

    public static readonly customerApplicationWizardApplicationSummaryRouteName: string = "CustomerApplicationWizard/ApplicationSummary";

    public static readonly customerApplicationWizardApplicationSummaryRoute: PageRoute = new PageRoute(PageDefinitions.customerApplicationWizardApplicationSummaryRouteName);

    // #endregion
}

new PageDefinitions();