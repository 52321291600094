import React from "react";
import {
    PageContainer,
    PageHeader, PageRow,
} from "@reapptor-apps/reapptor-react-components";
import Monitor from "@/pages/Monitors/Monitor/Monitor";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import CustomerApplicationMonitorStatus from "@/models/server/CustomerApplicationMonitorStatus";
import Localizer from "@/localization/Localizer";

import styles from "./Monitors.module.scss";

interface IMonitorsProps {
}

interface IMonitorsState {
    monitors: CustomerApplicationMonitorStatus[];
}

export default class Monitors extends AuthorizedPage<IMonitorsProps, IMonitorsState> {

    state: IMonitorsState = {
        monitors: []
    };

    private get statuses(): CustomerApplicationMonitorStatus[] {
        return this.state.monitors;
    }

    private get applications(): CustomerApplicationMonitorStatus[][] {
        return this.statuses.groupBy(item => item.applicationId);
    }
    
    public getTitle(): string {
        return Localizer.monitorsTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const monitors: CustomerApplicationMonitorStatus[] = await this.postAsync("/api/monitors/listMonitors");
        
        await this.setState({monitors});
    }

    private renderMonitor(monitor: CustomerApplicationMonitorStatus): React.ReactNode {
        return (
            <Monitor key={monitor.monitorId}
                     status={monitor}
            />
        )
    }

    private renderApplication(statuses: CustomerApplicationMonitorStatus[]): React.ReactNode {
        const first: CustomerApplicationMonitorStatus = statuses.first();
        statuses.sortByDescending(item => item.environmentType);

        return (
            <div key={first.applicationId} className={styles.application}>

                <span>{first.applicationName}</span>

                <div className={styles.statuses}>
                    {statuses.map(item => this.renderMonitor(item))}
                </div>

            </div>
        )
    }

    public render(): React.ReactNode {
        const applications: CustomerApplicationMonitorStatus[][] = this.applications;

        return (
            <PageContainer className={styles.monitors}>

                <PageHeader title={this.getTitle()}/>

                <PageRow>
                    <div className="col">

                        <div className={styles.applications}>

                            {
                                (applications.map(item => this.renderApplication(item)))
                            }

                        </div>
                        
                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}