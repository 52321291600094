import React from "react";
import {
    Button,
    ButtonType,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    ToolbarContainer, ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "@/localization/Localizer";

import styles from "./ApplicationDetails.module.scss";

interface IApplicationDetailsProps {
    customerApplicationId: string;
}
interface IApplicationDetailsState {
}

export default class ApplicationDetails extends AuthorizedPage<IApplicationDetailsProps, IApplicationDetailsState> {

    public getTitle(): string {
        return Localizer.applicationDetailsPageTitle;
    }

    public async initializeAsync(): Promise<void> {
        const params = this.parameters as IApplicationDetailsProps | null;

        const customerApplicationId: string | null = params?.customerApplicationId || "";
        if (customerApplicationId) {
            //TODO: call backend
        }

        await super.initializeAsync();
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.applicationDetails}>

                <PageHeader title={this.getTitle()}/>
                
                <PageRow>

                    <ToolbarContainer>

                        <ToolbarRow justify={JustifyContent.Start}>

                            <Button icon={{name: "fas arrow-alt-circle-left"}}
                                    small
                                    title={Localizer.genericBack}
                                    type={ButtonType.Primary}
                                    route={PageDefinitions.applicationsRoute}
                            />

                        </ToolbarRow>

                    </ToolbarContainer>
                    
                </PageRow>

            </PageContainer>
        );
    }    
}