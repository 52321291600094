// autogenerated

export default class GetApplicationsRequest {

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public showDeleted: boolean = false;

    public search: string | null = null;

    public readonly isGetApplicationsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetApplicationsRequest) || ((from as any).isGetApplicationsRequest === true));
    }

    public static as(from?: object | null): GetApplicationsRequest | null {
        return (GetApplicationsRequest.is(from)) ? from as GetApplicationsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}