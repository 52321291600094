import React from "react";
import {Form, UrlInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationLinks from "@/models/server/infraautomation/CustomerApplicationLinks";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface ISocialMediaLinksProps {
}

interface ISocialMediaLinksState {
}

export default class SocialMediaLinksState extends CustomerApplicationWizardPage<ISocialMediaLinksProps, ISocialMediaLinksState> {

    state: ISocialMediaLinksState = {
    };


    public getTitle(): string {
        return Localizer.wizardSocialMediaLinksStepTitle;
    }

    public async setFacebookUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.facebookUrl = value;
    }

    public async setInstagramUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.instagramUrl = value;
    }

    public async setYoutubeUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.youtubeUrl = value;
    }

    public async setLinkedinUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.linkedinUrl = value;
    }

    public async setTwitterUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.twitterUrl = value;
    }

    public async setDiscordUrlAsync(value: string): Promise<void> {
        this.socialMediaLinks.discordUrl = value;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get socialMediaLinks(): CustomerApplicationLinks {
        return PortalController.wizard.customerParameters.links ?? new CustomerApplicationLinks();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>

                <UrlInput id={"facebookUrl"}
                          label={Localizer.wizardSocialMediaLinksStepFacebook}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.facebookUrl}
                          onChange={(sender, value) => this.setFacebookUrlAsync(value)}
                />

                <UrlInput id={"instagramUrl"}
                          label={Localizer.wizardSocialMediaLinksStepInstagram}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.instagramUrl}
                          onChange={(sender, value) => this.setInstagramUrlAsync(value)}
                />

                <UrlInput id={"youtubeUrl"}
                          label={Localizer.wizardSocialMediaLinksStepYouTube}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.youtubeUrl}
                          onChange={(sender, value) => this.setYoutubeUrlAsync(value)}
                />

                <UrlInput id={"linkedinUrl"}
                          label={Localizer.wizardSocialMediaLinksStepLinkedIn}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.linkedinUrl}
                          onChange={(sender, value) => this.setLinkedinUrlAsync(value)}
                />

                <UrlInput id={"twitterUrl"}
                          label={Localizer.wizardSocialMediaLinksStepTwitter}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.twitterUrl}
                          onChange={(sender, value) => this.setTwitterUrlAsync(value)}
                />

                <UrlInput id={"discordUrl"}
                          label={Localizer.wizardSocialMediaLinksStepDiscord}
                          className={wizardStyles.item}
                          value={this.socialMediaLinks.discordUrl}
                          onChange={(sender, value) => this.setDiscordUrlAsync(value)}
                />

            </Form>
        );
    }
}