import React from "react";
import CustomerApplicationWizardPage from "../CustomerApplicationWizardPage";
import {
    Checkbox,
    Dropdown,
    Form,
    InlineType
} from "@reapptor-apps/reapptor-react-components";
import OrganizationContract from "../../../models/server/OrganizationContract";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage.module.scss";

export interface ISelectOrganizationProps {
}

interface ISelectOrganizationState {
    contracts: OrganizationContract[]
}

export default class SelectOrganization extends CustomerApplicationWizardPage<ISelectOrganizationProps, ISelectOrganizationState> {

    state: ISelectOrganizationState = {
        contracts: []
    }
    
    private get isNewOrganization(): boolean {
        return (this.wizard.isNewOrganization);
    }
    
    private get organizationContractId(): string | null {
        return this.wizard.organizationContractId;
    }

    protected get customerParameters(): CustomerApplicationParameters {
        return PortalController.wizard.customerParameters;
    }

    private async setCreateNewAsync(isNewOrganization: boolean): Promise<void> {
        this.wizard.isNewOrganization = isNewOrganization;
        this.saveContext();

        await this.reRenderAsync();
    }

    private async selectedItemChanged(item: OrganizationContract): Promise<void> {
        this.customerParameters.organizationName = item.name;
        this.customerParameters.organizationShortName = item.shortName;
        this.wizard.organizationContractId = item.id;
        this.saveContext();
    }

    public getTitle(): string {
        return Localizer.wizardSelectOrganizationStepTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const contracts: OrganizationContract[] = await this.getAsync("/api/createOrganization/getCompanyContracts");

        await this.setState({ contracts });
    }

    public get canNext(): boolean {
        return ((this.isNewOrganization) || (!!this.organizationContractId));
    }

    public async nextAsync(): Promise<void> {
        if (this.isNewOrganization) {
            this.wizard.organizationContractId = null;
            this.customerParameters.organizationName = null;
            this.customerParameters.organizationShortName = null;
        }
        await super.nextAsync();
    }

    public renderContent(): React.ReactNode {
        
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <Dropdown required
                          className={wizardStyles.item}
                          label={Localizer.wizardSelectOrganizationStepDropdownLabel}
                          disabled={this.isNewOrganization}
                          items={this.state.contracts}
                          selectedItem={this.organizationContractId}
                          onChange={(sender, item) => this.selectedItemChanged(item!)}
                />
                <Checkbox inline
                          className={wizardStyles.item}
                          inlineType={InlineType.Right}
                          label={Localizer.wizardSelectOrganizationStepCheckboxLabel}
                          value={this.isNewOrganization}
                          onChange={(sender, value) => this.setCreateNewAsync(value)}
                />
                
            </Form>
        );

    }
}


