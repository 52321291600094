import React from "react";
import {BaseComponent, ch, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, Layout} from "@reapptor-apps/reapptor-react-components";
import PortalConstants from "@/PortalConstants";
import ProductDetails from "@/pages/ProductDetails/ProductDetails";
import Products from "@/pages/Products/Products";
import PortalController from "@/pages/PortalController";

import appLogo from "./app-logo-white.svg";
import companyLogo from "./company-logo.svg";

class App extends BaseComponent {

    public render(): React.ReactNode {
        return (
            <Layout topNavLogo={appLogo}
                    topNavLogoText={PortalConstants.applicationShortName}
                    profile={() => PortalController.profile}
                    noFooter={ch.mobile}
                    footerName={PortalConstants.organizationShortName}
                    footerLogo={companyLogo}
                    footerLinks={() => PortalController.footerLinks}
                    onLogoClick={PortalController.onLogoClickAsync}
            />
        );
    }
    
}

//Register initialize events
ch.registerInitializeCallback(async () => await AddressHelper.loadGoogleLibraryAsync());
ch.registerInitializeCallback(async () => await PortalController.initializeAsync());
//Register authorize events
ch.registerAuthorizeCallback(async () => await UserInteractionDataStorage.onAuthorize());
ch.registerAuthorizeCallback(async () => await PortalController.authorizeAsync());
// Register route resolver
ch.registerRouteResolver((pathname: string) => Products.routeResolver(pathname));
ch.registerRouteResolver((pathname: string) => ProductDetails.routeResolver(pathname));

export default App;