import React from "react";
import {BasePageParameters, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {PageContainer, PageHeader, PageRow, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "../../models/base/AnonymousPage";
import PortalController from "@/pages/PortalController";
import Localizer from "../../localization/Localizer";

import styles from "./PrivacyPolicy.module.scss";

export interface IPrivacyPolicyParameters extends BasePageParameters {
}

export default class PrivacyPolicy extends AnonymousPage<IPrivacyPolicyParameters> {
    
    public getTitle(): string {
        return PortalController.getFullPageTitle(Localizer.privacyPolicyPageTitle);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.privacyPolicy}>

                <LdDataHeader canonical={"/privacy"} />

                <PageHeader title={Localizer.privacyPolicyPageTitle}
                            subtitle={Localizer.myAccountPageAcceptancePrivacyNoticeTitle}
                />

                <PageRow>
                    <div className="col-lg-12">
                        
                        <span className={styles.container}>
                            {ReactUtility.toMultiLines(Localizer.myAccountPageAcceptancePrivacyNoticeContent)}
                        </span>

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}