// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {IUser} from "@reapptor-apps/reapptor-react-common";
import {AuthType} from "@/models/Enums";
import UserInvitation from "@/models/server/UserInvitation";
import UserRole from "@/models/server/UserRole";

export default class User implements IUser {

    public id: string = "";

    public employeeNumber: string | null = null;

    public username: string = "";

    public email: string = "";

    public securityStamp: string = "";

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public isLocked: boolean = false;

    public isDeleted: boolean = false;

    public hasPassword: boolean = false;

    public invitations: UserInvitation[] | null = null;

    public authType: AuthType = AuthType.Email;

    public firstname: string | null = null;

    public lastName: string | null = null;

    public middleName: string | null = null;

    public language: string | null = null;

    public phone: string | null = null;

    public role: UserRole | null = null;

    public roles: UserRole[] = [];

    public homeLocation: GeoLocation | null = null;

    public address: string | null = null;

    public city: string | null = null;

    public postalCode: string | null = null;

    public externalId: string | null = null;

    public external: boolean = false;

    public contractSalaryHourlyPrice: number = 0;

    public fullName: string | null = null;

    public isAdmin: boolean = false;

    public isManager: boolean = false;

    public isCustomerManager: boolean = false;

    public isBusinessManager: boolean = false;

    public isContactPerson: boolean = false;

    public readonly isUser: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof User) || ((from as any).isUser === true));
    }

    public static as(from?: object | null): User | null {
        return (User.is(from)) ? from as User : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}