import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import DailyResourcesCosts from "@/models/server/DailyResourcesCosts";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {
    ColumnDefinition, Dropdown,
    Grid,
    Tab,
    TabContainer,
    TabRenderType, ToolbarContainer, ToolbarRow,
} from "@reapptor-apps/reapptor-react-components";
import GetResourcesCostsRequest from "@/models/server/requests/GetResourcesCostsRequest";
import {ResourceEnvironment} from "@/models/Enums";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./DailyResourcesCostsPanel.module.scss";

interface IDailyResourcesCostsPanelProps {
}

interface IDailyResourcesCostsPanelState {
    resourcesCosts: DailyResourcesCosts[];
    environmentGroup: ResourceEnvironment | null;
    initialized: boolean;
}

export default class DailyResourcesCostsPanel extends BaseComponent<IDailyResourcesCostsPanelProps, IDailyResourcesCostsPanelState> {

    state: IDailyResourcesCostsPanelState = {
        resourcesCosts: [],
        environmentGroup: null,
        initialized: false,
    };

    private readonly _resourcesCostsColumns: ColumnDefinition[] = [
        {
            header: Localizer.adminResourcesCostsPageDateLanguageItemName,
            accessor: nameof<DailyResourcesCosts>(o => o.day),
            format: "D",
            textAlign: TextAlign.Center,
            minWidth: 90,
        } as ColumnDefinition,
        {
            group: Localizer.adminResourcesCostsPageCostLanguageItemName,
            header: Localizer.adminResourcesCostsPageCostTotalLanguageItemName,
            accessor: nameof<DailyResourcesCosts>(o => o.totalCost),
            format: "c",
            minWidth: 90
        } as ColumnDefinition,
        {
            group: Localizer.adminResourcesCostsPageCostLanguageItemName,
            header: Localizer.adminResourcesCostsPageCostKnownLanguageItemName,
            accessor: nameof<DailyResourcesCosts>(o => o.knownCost),
            format: "c",
            minWidth: 90
        } as ColumnDefinition,
        {
            group: Localizer.adminResourcesCostsPageCostLanguageItemName,
            header: Localizer.adminResourcesCostsPageCostUnknownLanguageItemName,
            accessor: nameof<DailyResourcesCosts>(o => o.unknownCost),
            format: "c",
            minWidth: 90
        } as ColumnDefinition
    ];

    private get resourcesCosts(): DailyResourcesCosts[] {
        return this.state.resourcesCosts;
    }

    private async setEnvironmentGroupAsync(value: ResourceEnvironment | null): Promise<void> {
        await this.setState({environmentGroup: value});
        this.initializeAsync();
    }

    private get averageDailyCost(): number {
        let averageDailyCost: number = 0;

        if ((this.state.initialized) && (this.resourcesCosts != null) && (this.resourcesCosts.count() > 0))
        {
            averageDailyCost = this.resourcesCosts.average(item => item.totalCost);
        }
        
        return averageDailyCost;
    }

    private get averageMonthlyCost(): number {
        let averageMonthlyCost: number = 0;

        if (this.state.initialized)
        {
            averageMonthlyCost = Utility.getDaysInMonth(Utility.today()) * this.averageDailyCost;
        }

        return averageMonthlyCost;
    }

    public async initializeAsync(): Promise<void> {
        const request = new GetResourcesCostsRequest();
        request.environmentGroup = this.state.environmentGroup;

        const resourcesCosts: DailyResourcesCosts[] = await this.postAsync("/api/costExplorer/getResourcesCosts", request);

        await this.setState({resourcesCosts, initialized: true});
    }

    public render(): React.ReactNode {
        const dataAscendingByDay = [...this.resourcesCosts].reverse();

        return (
            <React.Fragment>

                <div className={styles.info}>

                    <span>{Localizer.adminResourcesCostsPageInfoDailyAverageCost} {this.averageDailyCost.format("c")}</span>
                    <span>{Localizer.adminResourcesCostsPageInfoMonthlyAverageCost} {this.averageMonthlyCost.format("c")}</span>

                </div>

                <TabContainer id="resourcesCostsTabs" renderType={TabRenderType.ActiveOnly}
                              className={styles.dailyResourcesCosts}>

                    <Tab id="report" icon={{name: "table"}}>

                        <ToolbarContainer className={styles.toolbar}>

                            <ToolbarRow>

                                <Dropdown id="environmentGroupFilter" inline
                                          width={210}
                                          label={Localizer.adminResourcesCostsPageFilterEnvironmentGroupLabel}
                                          nothingSelectedText={Localizer.adminResourcesCostsPageFilterEnvironmentGroupNothingSelected}
                                          items={EnumProvider.getResourceEnvironmentItems()}
                                          selectedItem={this.state.environmentGroup}
                                          onChange={(_, item) => this.setEnvironmentGroupAsync(item ? parseInt(item.value) : null)}
                                />

                            </ToolbarRow>

                        </ToolbarContainer>

                        <div className={styles.grid}>

                            <Grid columns={this._resourcesCostsColumns}
                                  data={this.resourcesCosts}
                                  noDataText={Localizer.genericNoData}
                            />

                        </div>

                    </Tab>

                    <Tab id="lineChart" icon={{name: "fa-chart-line"}}>

                        <ResponsiveContainer height={600}>

                            <LineChart data={dataAscendingByDay}
                                       margin={{top: 50, right: 80, left: 80, bottom: 50}}
                            >

                                <CartesianGrid strokeDasharray="10"/>

                                <XAxis dataKey={"day"}
                                       tick={{textAnchor: 'end'}}
                                       tickFormatter={(tick) => tick.format("dd.MM")}
                                       label={{
                                           value: Localizer.adminResourcesCostsPageDate,
                                           position: "insideBottomRight",
                                           dy: 25,
                                           dx: 10,
                                           style: {fontWeight: "bold"},
                                       }}
                                />

                                <YAxis label={
                                    {
                                        value: Localizer.adminResourcesCostsPageCost,
                                        position: "insideTopLeft",
                                        dy: -10,
                                        dx: -80,
                                        style: {fontWeight: "bold"},
                                    }}
                                />

                                <Tooltip wrapperClassName={styles.tooltip}
                                         formatter={(value) => (value as number).toFixed(2)}
                                />

                                <Legend/>

                                <Line dataKey="totalCost" name={Localizer.adminResourcesCostsPageCostTotal} type="monotone" stroke="lightblue"/>
                                <Line dataKey="knownCost" name={Localizer.adminResourcesCostsPageCostKnown} type="monotone" stroke="green"/>
                                <Line dataKey="unknownCost" name={Localizer.adminResourcesCostsPageCostUnknown} type="monotone" stroke="red"/>

                            </LineChart>

                        </ResponsiveContainer>

                    </Tab>

                </TabContainer>

            </React.Fragment>
        );
    }
}