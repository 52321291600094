// autogenerated

export default class CustomerApplicationColors {

    public primaryColor: string | null = null;

    public primaryHoverColor: string | null = null;

    public primaryDisableColor: string | null = null;

    public secondaryColor: string | null = null;

    public secondaryHoverColor: string | null = null;

    public secondaryDisableColor: string | null = null;

    public readonly isCustomerApplicationColors: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplicationColors) || ((from as any).isCustomerApplicationColors === true));
    }

    public static as(from?: object | null): CustomerApplicationColors | null {
        return (CustomerApplicationColors.is(from)) ? from as CustomerApplicationColors : null;
    }

    // #region Placeholder

    constructor() {
        this.primaryColor = "#0d244b";
        this.primaryHoverColor = "#334f7e";
        this.primaryDisableColor = "#6b81a5";
        this.secondaryColor = "#a7ceca";
        this.secondaryHoverColor = "#72d0c8";
        this.secondaryDisableColor = "#b0b6ba";
    }

    // #endregion
}