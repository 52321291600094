// autogenerated

export default class CreateStagingRequest {

    public customerApplicationId: string = "";

    public readonly isCreateStagingRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CreateStagingRequest) || ((from as any).isCreateStagingRequest === true));
    }

    public static as(from?: object | null): CreateStagingRequest | null {
        return (CreateStagingRequest.is(from)) ? from as CreateStagingRequest : null;
    }

    // #region Placeholder

    constructor(customerApplicationId: string) {
        this.customerApplicationId = customerApplicationId;
    }

    // #endregion
}