import React from "react";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import {Dropdown, DropdownOrderBy, Form} from "@reapptor-apps/reapptor-react-components";
import {CountryInfo} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";

import wizardStyles from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage.module.scss";

export interface ISelectCountryProps {
}

interface ISelectCountryState {
    countries: CountryInfo[];
}

export default class SelectCountry extends CustomerApplicationWizardPage<ISelectCountryProps, ISelectCountryState> {

    state: ISelectCountryState = {
        countries: [],
    }
    
    private async onCountrySelectedAsync(value: CountryInfo): Promise<void> {
        this.wizard.newOrganization.country = value.code;
        this.saveContext();
    }
    
    private get country(): string | null {
        return this.wizard.newOrganization.country;
    }

    public getTitle(): string {
        return Localizer.wizardSelectCountryStepTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const countries: CountryInfo[] = await this.getAsync("/api/createOrganization/listSupportedCountries");
        
        await this.setState({countries});
    }

    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <Dropdown required noSubtext
                          label={Localizer.wizardSelectCountryStepDropdownLabel}
                          className={wizardStyles.item}
                          orderBy={DropdownOrderBy.None}
                          items={this.state.countries}
                          selectedItem={this.country}
                          onChange={(sender, item) => this.onCountrySelectedAsync(item!)}
                />
                
            </Form>
        );

    }
}