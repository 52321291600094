import {AthenaeumConstants, GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class PortalConstants extends AthenaeumConstants {

    public static readonly organizationShortName: string = "ReApptor";

    public static readonly applicationShortName: string = "Portal";

    /**
     /* "ReApptor Portal"
     */
    public static readonly applicationTitle: string = "ReApptor Portal";

    public static readonly maxHoursPerDay = 24;

    public static readonly canModifyHoursRange = 24;

    // 1 MB
    public static readonly maxBackgroundImageSizeInBytes: number = 1024 * 1024;

    // 10 MB
    public static readonly maxFileUploadSizeInBytes: number = 10 * 1024 * 1024;

    // 50 MB
    public static readonly maxImageRequestSizeInBytes: number = 50 * 1024 * 1024;

    // 20 KB
    public static readonly maxFaviconSizeInBytes: number = 20 * 1024;

    // 500 KB
    public static readonly maxFontSizeInBytes: number = 500 * 1024;

    // 200 KB
    public static readonly maxLogoIconSizeInBytes: number = 200 * 1024;

    // 200 KB
    public static readonly maxLogoSizeInBytes: number = 200 * 1024;
    
    // 10 chars
    public static readonly maxApplicationShortNameLength: number = 10;

    // 11 chars
    public static readonly maxCompanyShortNameLength: number = 11;

    // 21 chars
    public static readonly maxCompanyAndApplicationShortNameLength: number = PortalConstants.maxApplicationShortNameLength + PortalConstants.maxCompanyShortNameLength;

    public static readonly imageFileTypes: string[] = ["image/gif", "image/jpeg", "image/png"];

    public static readonly fontFileTypes: string[] = [".woff", ".woff2", ".otf", ".ttf"];

    // Regular expression to check application short name is acceptable variable name in C#
    public static readonly applicationShortNameValidationRegex: string = "^[a-zA-Z_$][a-zA-Z_$0-9]*$";
    
    // Regular expression for company and application titles
    public static readonly titleValidationRegex: string = "^[^~`@#$%^*+=?{};№.|[\\]]+$";

    // Regular expression to check company short name is acceptable variable name in C#
    public static readonly organizationShortNameValidationRegex: string = "^[a-zA-Z_$]+[a-zA-Z_$0-9]*$";
    
    public static readonly fontNameValidationRegex: string = "^[^~`@#$%^*+=?{};№.|[\\]]+$";
    
    public static readonly alertCloseDelay = 5000;

    public static readonly alertAnimationDelay = 500;

    public static readonly defaultLocation: GeoCoordinate = new GeoCoordinate(60.192059, 24.945831);

    // "If user has been signed in more than 15 hours straight throw user out"
    public static readonly signOutExpirationTimeOut: number = 15;

    // 255
    public static readonly maxTitleDescriptionLength: number = 255;

    public static readonly finlandCode: string = "fi";

    public static ProductionExecutionTimeMilliseconds: number = 2041110;

    public static StagingExecutionTimeMilliseconds: number = 900000;

    public static TestExecutionTimeMilliseconds: number = 1200000;
    
    public static defaultLanguages: string[] = ["en", "fi"];
}