import React from "react";
import {Form, ImageInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationImages from "@/models/server/infraautomation/CustomerApplicationImages";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

import reapptorLogoLight from "./Images/reapptor-logo-white.png";
import reapptorLogoDark from "./Images/reapptor-logo-black.png";
import boatLogoLight from "./Images/boat-logo-white.png";
import athenaeumLogoDark from "./Images/athenaeum-logo-black.png"

export interface IApplicationLogoProps {
}

interface IApplicationLogoState {
}

export default class ApplicationLogo extends CustomerApplicationWizardPage<IApplicationLogoProps, IApplicationLogoState> {

    state: IApplicationLogoState = {
    };

    public getTitle(): string {
        return Localizer.wizardApplicationLogoStepTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerImages(): CustomerApplicationImages {
        return PortalController.wizard.customerParameters.images!;
    }
    
    public async setApplicationLogoLightAsync(value: FileModel[]): Promise<void> {
        this.customerImages.applicationLogoLight = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setApplicationLogoDarkAsync(value: FileModel[]): Promise<void> {
        this.customerImages.applicationLogoDark = value[0]?.src;
        this.saveContext();
        await this.reRenderAsync();
    }


    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <p>{Localizer.wizardLogoStepLightLogoPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.applicationLogoLight  || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxLogoSizeInBytes}
                            onChange={(sender, value) => this.setApplicationLogoLightAsync(value)}
                />
                
                <p>{Localizer.wizardLogoStepExamplesPlace}</p>
                <div className={wizardStyles.imageItem}>
                    <img src={reapptorLogoLight} alt={Localizer.wizardLogoStepReapptorLogoLightAlt}/>
                </div>
                <div className={wizardStyles.imageItem}>
                    <img src={boatLogoLight} alt={Localizer.wizardLogoStepBoutLogoLightAlt}/>
                </div>
                <hr/>
                
                <p>{Localizer.wizardLogoStepDarkLogoPlace}</p>  
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.applicationLogoDark  || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxLogoSizeInBytes}
                            onChange={(sender, value) => this.setApplicationLogoDarkAsync(value)}
                            convertImage={async (image: FileModel) => await PortalController.convertImageAsync(image)}
                />
                
                <p>{Localizer.wizardLogoStepExamplesPlace}</p>
                <div className={wizardStyles.imageItem}>
                    <img src={reapptorLogoDark} alt={Localizer.wizardLogoStepReapptorLogoDarkAlt}/>
                </div>
                <div className={wizardStyles.imageItem}>
                    <img src={athenaeumLogoDark} alt={Localizer.wizardLogoStepAthenaeumLogoDarkAlt}/>
                </div>
                
            </Form>
        );
    }
    
}