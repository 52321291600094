import React from "react";
import {ActionType, BaseComponent, IBaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {
    CellModel,
    ColumnActionDefinition,
    ColumnDefinition,
    Dropdown,
    Grid, 
    ToolbarContainer
} from "@reapptor-apps/reapptor-react-components";
import InfraTaskStepResult from "@/models/server/infraautomation/InfraTaskStepResult";
import InfraTaskResult from "@/models/server/infraautomation/InfraTaskResult";
import GetInfraTaskResultRequest from "@/models/server/requests/infraAutomation/GetInfraTaskResultRequest";
import Localizer from "@/localization/Localizer";

import styles from "@/pages/Applications/Applications.module.scss";

interface IApplicationLogsPanelProps {
    customerApplicationId: string,
}

interface IApplicationLogsPanelState {
    infraTasks: InfraTaskResult[],
    selectedInfraTask: InfraTaskResult | null,    
    selectedInfraStepCell: CellModel<InfraTaskStepResult> | null,
}

export default class ApplicationLogsPanel extends BaseComponent<IApplicationLogsPanelProps, IApplicationLogsPanelState> {
    state: IApplicationLogsPanelState = {
        infraTasks: [],
        selectedInfraTask: null,
        selectedInfraStepCell: null,
    };

    private readonly _appLogsGridRef: React.RefObject<Grid<InfraTaskStepResult>> = React.createRef();

    private readonly _logsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "stepNumber",
            minWidth: 40,
            actions: [
                {
                    type: ActionType.Info,
                    icon: "fa-info-square far",
                    callback: async (cell) => await this.toggleStepDetailsAsync(cell)
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            header: Localizer.applicationLogsPanelGridStepLanguageItemName,
            accessor: "stepName",
            minWidth: 500,
        } as ColumnDefinition,
    ]

    private async toggleStepDetailsAsync(cell: CellModel<InfraTaskStepResult>): Promise<void> {
        if (this.state.selectedInfraStepCell != null && this.state.selectedInfraStepCell.model.stepId != cell.model.stepId) {
            await this.state.selectedInfraStepCell.row.toggleAsync();
        }
        await this.setState({selectedInfraStepCell: cell});
        await cell.row.toggleAsync();
    }

    private async selectInfraTaskAsync(task: InfraTaskResult): Promise<void> {
        await this.setState({selectedInfraTask: task});
        await this._appLogsGridRef.current?.reloadAsync();
    }    
    
    private async fetchApplicationInfraTasksAsync(sender: IBaseComponent): Promise<void> {
        if (this.props.customerApplicationId != null) {
            
            const request = new GetInfraTaskResultRequest();
            request.customerApplicationId = this.props.customerApplicationId;
            
            const infraTasks: InfraTaskResult[] = await sender.postAsync("/api/managementConsole/listApplicationInfraTasks", request);

            await this.setState({infraTasks});
        }
    }

    private async fetchApplicationInfraTaskStepsAsync(sender: IBaseComponent): Promise<InfraTaskStepResult[]> {
        if (this.state.selectedInfraTask != null) {
            
            const response: InfraTaskResult = await sender.postAsync("/api/managementConsole/getApplicationInfraTask", this.state.selectedInfraTask.taskId);
            
            return response.steps ?? [];
        }

        return [];
    }
    
    public async componentDidMount(): Promise<void> {
        await this.fetchApplicationInfraTasksAsync(this);
        return await super.componentDidMount();
    }

    private renderStepDetailsContent(step: InfraTaskStepResult): React.ReactNode {
        return (

            <div>
                <p>{ReactUtility.toMultiLines(step.message)}</p>
            </div>

        );
    }    
    
    public render(): React.ReactNode {

        return (

            <div>

                <ToolbarContainer>
                    
                    <Dropdown label={Localizer.applicationLogsPanelDropdownSelectInfraTask}
                              minWidth="150px"
                              noWrap 
                              className={styles.selectInfraTask}
                              items={this.state.infraTasks}
                              selectedItem={this.state.selectedInfraTask}
                              onItemClick={(async (sender, item) => await this.selectInfraTaskAsync(item!))}
                    />
                    
                </ToolbarContainer>

                <div className={styles.table}>

                    <Grid ref={this._appLogsGridRef} responsive
                          columns={this._logsColumns}
                          noDataText={Localizer.applicationLogsPanelGridNoDataTextLanguageItemName}
                          renderDetails={(row) => this.renderStepDetailsContent(row.model)}
                          fetchData={async (sender) => await this.fetchApplicationInfraTaskStepsAsync(sender)}
                    />                    
                    
                </div>

            </div>

        );

    }    
}