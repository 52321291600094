import React from "react";
import {Accordion, Button, ButtonContainer, ButtonType, Icon, PageContainer, PageHeader, PageRow, Spinner} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {BasePageParameters, PageRouteProvider, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import CustomerApplication from "@/models/server/CustomerApplication";
import GetInfraTaskResultRequest from "@/models/server/requests/infraAutomation/GetInfraTaskResultRequest";
import InfraTaskResult from "@/models/server/infraautomation/InfraTaskResult";
import InfraTaskStepResult from "@/models/server/infraautomation/InfraTaskStepResult";
import {TimeSpan, Utility} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import {InfraTaskType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import Localizer from "../../localization/Localizer";

import styles from "./ApplicationLogs.module.scss";

export interface IApplicationLogsParameters extends BasePageParameters {
    customerApplicationId?: string;
    taskId?: string;
}

interface IApplicationLogsState {
    application: CustomerApplication | null;
    expandedSteps: InfraTaskStepResult[];
    progress: number;
    estimatedTime: TimeSpan;
}

export default class ApplicationLogs extends AuthorizedPage<IApplicationLogsParameters, IApplicationLogsState> {

    state: IApplicationLogsState = {
        application: null,
        expandedSteps: [],
        progress: 0,
        estimatedTime: TimeSpan.fromMilliseconds(0),
    };

    private _executing: boolean = false;
    private _taskStatus: string = "";
    private _taskId: string | null = null;
    private _taskMessage: string = "";
    private _taskResult: InfraTaskResult | null = null;

    private async fetchApplicationAsync(applicationId: string): Promise<CustomerApplication> {
        return await this.postAsync("/api/managementConsole/getApplication", applicationId);
    }

    private setEstimatedTime(taskCompleted: boolean, type: InfraTaskType, createStagingEnvironment: boolean): void {
        if (this._taskResult != null) {
            if (taskCompleted) {
                this.state.progress = 100;
                this.state.estimatedTime = TimeSpan.fromMilliseconds(0);
            } else {
                const elapsedMilliseconds: number = Utility.diff(Utility.now(), this._taskResult.startAt).totalMilliseconds;

                let executionTime: number = 0;

                switch (type) {
                    case InfraTaskType.CreateDevelopment:
                        if (createStagingEnvironment) {
                            executionTime = (PortalConstants.TestExecutionTimeMilliseconds + PortalConstants.StagingExecutionTimeMilliseconds);
                        } else {
                            executionTime = PortalConstants.TestExecutionTimeMilliseconds;
                        }
                        break;
                    case InfraTaskType.CreateStaging:
                        executionTime = PortalConstants.StagingExecutionTimeMilliseconds;
                        break;
                    case InfraTaskType.CreateProduction:
                        executionTime = PortalConstants.ProductionExecutionTimeMilliseconds;
                        break;
                }

                const progress: number = 100 * (elapsedMilliseconds / executionTime);

                this.state.progress = 100 * (elapsedMilliseconds / executionTime);
                this.state.progress = (progress < 100) ? progress : 99.9;

                this.state.estimatedTime = TimeSpan.fromMilliseconds(executionTime - elapsedMilliseconds);
            }
        }
    }

    private async invokeTaskAsync(): Promise<void> {
        if ((!this._executing) && (this.application) && (this.application.id)) {
            try {
                let taskResult: InfraTaskResult;
                
                this._executing = true;
                this._taskStatus = "";
                this._taskMessage = "";

                await this.reRenderAsync();

                while (true) {
                    const request = new GetInfraTaskResultRequest();
                    request.customerApplicationId = this.application.id;
                    request.taskId = this._taskId;

                    taskResult = await this.getInfraTaskResult(request);

                    this._taskResult = taskResult;

                    this._taskId = taskResult.taskId;

                    if (taskResult.completed) {
                        if (!taskResult.failed) {
                            this._taskStatus = Localizer.applicationLogsPageInfraTaskHasCompletedSuccessfully;
                        } else {
                            this._taskStatus = Localizer.applicationLogsPageInfraTaskFailed
                            if (!!taskResult.message) {
                                const errorDetailsIndex = taskResult.message.indexOf("Type:");
                                const failedStepNumber = taskResult.steps?.length;
                                taskResult.message = taskResult.message.substring(0, errorDetailsIndex);
                                taskResult.message += `Failed step number: ${failedStepNumber}.`;
                            }
                        }

                        // Stop progress bar counter. Case when execution is finished before the counter count down.
                        this.state.progress = 100;

                        this._taskMessage = taskResult.message || "";
                        break;
                    }

                    this.setEstimatedTime(taskResult.completed, taskResult.type, this.application.parameters!.createStagingEnvironment);

                    const displayEstimatedTime: string = `${Utility.pad(this.state.estimatedTime.hours)}:${Utility.pad(this.state.estimatedTime.minutes)}:${Utility.pad(this.state.estimatedTime.seconds)}`

                    this._taskStatus = (this.state.progress >= 100)
                        ? Localizer.applicationLogsPagePleaseWait
                        : Localizer.applicationLogsPageInfraTaskExecuting.format(displayEstimatedTime);

                    this._taskMessage = taskResult.message || "";

                    await this.reRenderAsync();

                    await Utility.wait(1000);
                }
            } finally {
                this._executing = false;
                await this.reRenderAsync();
            }
        }
    }

    private async getInfraTaskResult(request: GetInfraTaskResultRequest): Promise<InfraTaskResult> {
        return await this.postAsync("/api/managementConsole/getInfraTaskResult", request);
    }

    private get application(): CustomerApplication | null {
        return this.state.application;
    }
    
    public getTitle(): string {
        return Localizer.wizardApplicationStepTitle;
    }

    public get subtitle(): string {
        return this.application
            ? (!!this.application.id)
                ? this.application.name
                : "{0} *".format(this.application.name)
            : "...";
    }

    public hasSpinner(): boolean {
        return (this._executing);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        const params: IApplicationLogsParameters | null = this.parameters;
        const customerApplicationId: string | null = params?.customerApplicationId || this.routeId;
        
        if (!customerApplicationId) {
            await PageRouteProvider.redirectAsync(PageDefinitions.dashboardRoute, true, true);
        }

        this._taskId = params?.taskId || null;

        const application: CustomerApplication = await this.fetchApplicationAsync(customerApplicationId!);

        await this.setState({application});

        await this.invokeTaskAsync();
    }

    private getDurationTime(duration: TimeSpan): string {
        const minutes: string = Utility.pad(duration.minutes);
        const seconds: string = Utility.pad(duration.seconds);
        return "{0}:{1}".format(minutes, seconds);
    }

    private async onStepExpandAsync(step: InfraTaskStepResult, expanded: boolean): Promise<void> {
        const existingStep: InfraTaskStepResult | undefined = this.state.expandedSteps.find(item => item.stepId == step.stepId);

        if (expanded) {
            if (existingStep == null) {
                this.state.expandedSteps.push(step);
            }
        } else {
            if (existingStep != null) {
                this.state.expandedSteps.remove(existingStep);
            }
        }

        await this.reRenderAsync();
    }

    private isStepExpanded(step: InfraTaskStepResult): boolean {
        return this.state.expandedSteps.some(item => item.stepId == step.stepId);
    }

    private renderInfraTaskStatus(): React.ReactNode {
        const pendingStyle: any = (this._executing) && styles.pending;
        return (
            <div>
                <div className={this.css(styles.taskStatusPanel, pendingStyle)}>
                    <p className={styles.title}>{ReactUtility.toMultiLines(this._taskStatus)}</p>
                    <div className={styles.spinner}>
                        {(this.renderProgressBar())}
                    </div>
                </div>
                <hr/>
                <div className={styles.taskStatusPanel}>
                    <p className={styles.title}>{ReactUtility.toMultiLines(this._taskMessage)}</p>
                </div>
            </div>
        );
    }

    private renderInfraTaskStep(step: InfraTaskStepResult): React.ReactNode {
        return (
            <div key={step.stepId}>
                <Accordion className={this.css(styles.accordion, this.isStepExpanded(step) && styles.expanded)}
                           header={this.renderInfraTaskStepHeader(step)}
                           onToggle={async (accordion) => this.onStepExpandAsync(step, accordion.expanded)}
                           autoCollapse={false}
                >
                    {this.renderInfraTaskStepContent(step)}
                </Accordion>
            </div>
        )
    }

    private renderInfraTaskResult(): React.ReactNode {
        return this._taskResult?.steps?.map(step => this.renderInfraTaskStep(step));
    }

    private renderInfraTaskStepHeader(step: InfraTaskStepResult): React.ReactNode {
        const duration: TimeSpan | null = (step.completedAt != null && step.startAt != null)
            ? Utility.diff(step.completedAt, step.startAt)
            : null;
        
        const pendingStyle: any = step.pending && styles.pending;
        
        const icon: string = (step.failed)
            ? "fas fa-times"
            : step.completed
                ? "fas fa-check"
                : "fas fa-play";

        return (
            <div className={this.css(styles.logRowHeaderContainer, pendingStyle)}>
                <div className={styles.title}>
                    <Icon name={icon} className={"mr-3"} />
                    <span>#</span>
                    <span>{step.stepNumber + 1}</span>
                    <span> </span>
                    <span>{step.stepName}</span>
                </div>
                <div className={styles.duration}>
                    {
                        (duration != null)
                            ? (<span>{this.getDurationTime(duration)}</span>)
                            : (<Spinner />)
                    }
                </div>
            </div>
        );
    }

    private renderInfraTaskStepContent(step: InfraTaskStepResult): React.ReactNode {
        return (
            <div className={styles.logRowContentContainer}>
                <p>{ReactUtility.toMultiLines(step.message)}</p>
            </div>
        );
    }

    private renderProgressBar(): React.ReactNode {
        return (
            <div>
                <div className={styles.progressBar}>
                    <div className={styles.progressBarContent} style={{width: `${this.state.progress}%`}}>
                        <span>{"{0}%".format(this.state.progress.toFixed(1))}</span>
                    </div>
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.applicationLogs}>

                <PageHeader title={this.getTitle()} subtitle={this.subtitle}/>

                <PageRow>
                    <div className="col">

                        <ButtonContainer>
                            <Button icon={{name: "fas arrow-alt-circle-left"}}
                                    title={Localizer.genericBack}
                                    type={ButtonType.Primary}
                                    onClick={async () => PageRouteProvider.back()}
                            />
                        </ButtonContainer>

                        {
                            (this.application) &&
                            (
                                <div>
                                    <p>{this.application?.name}</p>
                                </div>
                            )
                        }

                    </div>
                </PageRow>

                <div className={styles.logContent}>

                    {this.renderInfraTaskStatus()}

                    {this.renderInfraTaskResult()}

                </div>

            </PageContainer>
        );
    }
}