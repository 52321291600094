// autogenerated

export default class GetInfraTaskResultRequest {

    public taskId: string | null = null;

    public customerApplicationId: string = "";

    public startAt: Date = new Date();

    public readonly isGetInfraTaskResultRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetInfraTaskResultRequest) || ((from as any).isGetInfraTaskResultRequest === true));
    }

    public static as(from?: object | null): GetInfraTaskResultRequest | null {
        return (GetInfraTaskResultRequest.is(from)) ? from as GetInfraTaskResultRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}