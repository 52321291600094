import React from "react";
import {Form, ImageInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationImages from "@/models/server/infraautomation/CustomerApplicationImages";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

import weAreLogo from "./Images/weare-logo.png";
import boatLogo from "./Images/boat-logo.png";

export interface ICompanyLogoProps {
}

interface ICompanyLogoState {
}

export default class CompanyLogo extends CustomerApplicationWizardPage<ICompanyLogoProps, ICompanyLogoState> {

    state: ICompanyLogoState = {
    };

    public getTitle(): string {
        return Localizer.wizardCompanyLogoStepTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        console.log(PortalController.wizard)
        await super.initializeAsync();
    }

    protected get customerImages(): CustomerApplicationImages {
        return PortalController.wizard.customerParameters.images!;
    }

    public async setCompanyLogoAsync(value: FileModel[]): Promise<void> {
        this.customerImages.companyLogo = value[0]?.src;
        this.saveContext();
        await this.reRenderAsync();
    }
    
    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <p>{Localizer.wizardCompanyLogoStepCompanyLogoPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.companyLogo || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxLogoSizeInBytes}
                            onChange={(sender, value) => this.setCompanyLogoAsync(value)}
                            convertImage={async (image: FileModel) => await PortalController.convertImageAsync(image)}
                />
                
                <p>{Localizer.wizardCompanyLogoStepExamplesPlace}</p>
                <div className={wizardStyles.imageItem}>
                    <img src={weAreLogo} alt={Localizer.wizardCompanyLogoStepCompanyLogoAlt}/>
                </div>
                <div className={wizardStyles.imageItem}>
                    <img src={boatLogo} alt={Localizer.wizardCompanyLogoStepCompanyLogoAlt}/>
                </div>
                
            </Form>
        );
    }

}