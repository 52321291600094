// autogenerated
import {ApplicationLinkType} from "@/models/Enums";

export default class ListApplicationLinksRequest {

    public customerApplicationId: string = "";

    public linkType: ApplicationLinkType | null = null;

    public readonly isListApplicationLinksRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListApplicationLinksRequest) || ((from as any).isListApplicationLinksRequest === true));
    }

    public static as(from?: object | null): ListApplicationLinksRequest | null {
        return (ListApplicationLinksRequest.is(from)) ? from as ListApplicationLinksRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}