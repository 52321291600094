import React from "react";
import {Checkbox, Form, InlineType, NumberInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface IApplicationSettingsProps {
}

interface IApplicationSettingsState {
}

export default class ApplicationSettings extends CustomerApplicationWizardPage<IApplicationSettingsProps, IApplicationSettingsState> {

    state: IApplicationSettingsState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync();
    }

    public getTitle(): string {
        return Localizer.wizardApplicationSettingsStepTitle;
    }

    public samePortsValidator(): string | null {
        return (this.customerParameters.devFrontendPort === this.customerParameters.devBackendPort)
            ? Localizer.wizardApplicationSettingsStepsamePortsValidator
            : null;
    }
    
    public async setDevFrontendPortAsync(value: number): Promise<void> {
        this.customerParameters.devFrontendPort = value;
        await this.validateFormAsync()
        this.saveContext();
    }

    public async setDevBackendPortAsync(value: number): Promise<void> {
        this.customerParameters.devBackendPort = value;
        await this.validateFormAsync()
        this.saveContext();
    }

    public async setCreateTestEnvironmentAsync(value: boolean): Promise<void> {
        this.customerParameters.createTestEnvironment = value;
        this.saveContext();
    }

    public async setCreateStagingEnvironmentAsync(value: boolean): Promise<void> {
        this.customerParameters.createStagingEnvironment = value;
        this.saveContext();
    }

    public async nextAsync(): Promise<void> {
        this.saveContext();
        await super.nextAsync();
    }

    public get canNext(): boolean {
        return (!!this.customerParameters.applicationShortName) && (!!this.customerParameters.applicationName) && (this.customerParameters.createTestEnvironment);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerParameters(): CustomerApplicationParameters {
        return PortalController.wizard.customerParameters;
    }
    

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={wizardStyles.wizardStepForm}>

                <NumberInput required
                             label={Localizer.wizardSettingStepDevFrontendPort}
                             className={wizardStyles.item}
                             value={this.customerParameters.devFrontendPort || undefined}
                             validators={[() => this.samePortsValidator()]}
                             onChange={(sender, value) => this.setDevFrontendPortAsync(value)}
                />

                <NumberInput required
                             className={wizardStyles.item}
                             label={Localizer.wizardSettingStepDevBackendPort}
                             value={this.customerParameters.devBackendPort || undefined}
                             validators={[() => this.samePortsValidator()]}
                             onChange={(sender, value) => this.setDevBackendPortAsync(value)}
                />
                
                <Checkbox inline inlineType={InlineType.Right}
                          label={Localizer.wizardSettingStepTestEnvironment}
                          value={this.customerParameters.createTestEnvironment}
                          onChange={(sender, value) => this.setCreateTestEnvironmentAsync(value)}
                />
                
                <Checkbox inline inlineType={InlineType.Right}
                          label={Localizer.wizardSettingStepStagingEnvironment}
                          value={this.customerParameters.createStagingEnvironment}
                          onChange={(sender, value) => this.setCreateStagingEnvironmentAsync(value)}
                />

            </Form>
        );
    }
}