import React from "react";
import {BaseComponent, IBaseComponentProps } from "@reapptor-apps/reapptor-react-common";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {InfraEnvironmentType} from "@/models/Enums";
import {Spinner} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationMonitorStatus from "@/models/server/CustomerApplicationMonitorStatus";
import Localizer from "@/localization/Localizer";

import styles from "./Monitor.module.scss";

const WarningDelayInMinutes: number = 5;
const ReloadInterval: number = 5000;

export interface IMonitorProps extends IBaseComponentProps {
    status: CustomerApplicationMonitorStatus;
}

interface IMonitorState {
    status: CustomerApplicationMonitorStatus;
    loading: boolean;
}

export default class Monitor extends BaseComponent<IMonitorProps, IMonitorState> {

    state: IMonitorState = {
        status: this.props.status,
        loading: false,
    };
    
    private async openApplicationAsync(): Promise<void> {
    }
    
    private async reloadAsync(): Promise<void> {
        await Utility.wait(ReloadInterval);
        
        try {
            await this.setState({ loading: true });

            await Utility.wait(300);
            
            const status: CustomerApplicationMonitorStatus = await this.postAsync("/api/monitors/getMonitorStatus", this.monitorId);

            await this.setState({ status });
        }
        finally {
            await this.setState({ loading: false });

            if (this.isMounted) {
                await this.reloadAsync();
            }
        }
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
        await Utility.wait(ReloadInterval * Math.random())
        
        await this.reloadAsync();
    }
    
    public get status(): CustomerApplicationMonitorStatus {
        return this.state.status;
    }
    
    public get monitorId(): string {
        return this.status.monitorId;
    }
    
    public get failed(): boolean {
        return (!this.status.healthy);
    }
    
    public get warning(): boolean {
        return(
            (!this.failed) &&
            (this.status.healthy) && 
            (
                (this.status.lastCheckAt == null) ||
                (Utility.diff(Utility.now(), this.status.lastCheckAt).totalMinutes >= WarningDelayInMinutes)
            )
        );
    }
    
    public get success(): boolean {
        return (!this.failed) && (!this.warning);
    }
    
    public get loading(): boolean {
        return this.state.loading;
    }
    
    public get lastCheckAtLabel(): string {
        if (this.status.lastCheckAt != null) {
            const minutes: number = Utility.diff(Utility.now(), this.status.lastCheckAt).totalMinutes;
            return (minutes < 1)
                ? Localizer.monitorsLessThanAMinute
                : Utility.format(Localizer.monitorsMinutesAgo, minutes);
        }
        return "-";
    }
    
    public get uptime(): string {
        return ((this.status.totalSeconds > 0) && (this.status.downtimeSeconds < this.status.totalSeconds))
            ? "{0:0.00}%".format(100 * (this.status.totalSeconds - this.status.downtimeSeconds) / this.status.totalSeconds)
            : "?";
    }

    public render(): React.ReactNode {
        const failedStyle = this.failed && styles.failed;
        const warningStyle = this.warning && styles.warning;
        const successStyle = this.success && styles.success;
        
        return (
            <div id={this.id} className={this.css(this.props.className, styles.monitor, failedStyle, warningStyle, successStyle)}
                 onClick={() => this.openApplicationAsync()}
            >
                
                <span className={styles.title}>
                    {Utility.formatValue(this.status.environmentType, nameof<InfraEnvironmentType>())}
                </span>
                
                <div className={styles.lastCheck}>
                    <span>{Localizer.monitorsLastCheck}</span>
                    <span>{this.lastCheckAtLabel}</span>
                </div>

                <div className={styles.uptime}>
                    <span>{Localizer.monitorsUptime}:</span>
                    <span>{this.uptime}</span>
                </div>

                <div className={styles.spinner}>
                    { this.loading && <Spinner noDelay noShading /> }
                </div>
                
            </div>
        );
    }
}