import React from "react";
import {
    BaseComponent,
    ch,
    IBaseComponentProps,
    LocalizationString,
    PageRouteProvider,
    ReactUtility
} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, Spinner} from "@reapptor-apps/reapptor-react-components";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import ImageProvider from "@/providers/ImageProvider";
import {ProductAccessoryType, ProductUnit} from "@/models/Enums";
import GetProductResponse from "@/models/server/responses/GetProductResponse";
import GetProductRequest from "@/models/server/requests/GetProductRequest";
import LocalizationTextInput, {LocalizationTextInputType} from "@/components/LocalizationTextInput/LocalizationTextInput";
import PageDefinitions from "@/providers/PageDefinitions";
import Product from "@/models/server/products/Product";
import Localizer from "@/localization/Localizer";

import styles from "./ProductCard.module.scss";
import noImage from '../Images/NoImage.png';

export enum ProductCardMode {
    /**
     * The basic product information to be populated e.g. on the Catalog page.
     */
    Preview,

    /**
     * The full view of the product in read-only mode.
     */
    Details,

    /**
     * Editable product view to be used on the ProductManagement page.
     */
    Management,
}

export interface IProductCardProps extends IBaseComponentProps {
    product: Product | string;
    mode: ProductCardMode;
    flipping?: boolean;
    onFinishEdit?(): Promise<void>
}

interface IProductCardState {
    product: Product | null;
    mode: ProductCardMode | null;
    flipped: boolean;
}

export default class ProductCard extends BaseComponent<IProductCardProps, IProductCardState> {

    state: IProductCardState = {
        product: null,
        mode: null,
        flipped: false,
    };

    private get mode(): ProductCardMode {
        return this.state.mode || this.props.mode;
    }

    private get flipping(): boolean {
        return this.props.flipping || false;
    }

    private get flipped(): boolean {
        return this.state.flipped;
    }

    private get product(): Product {
        return this.state.product!;
    }

    private get title(): string {
        return LocalizationString.value(this.product.name);
    }

    private get category(): string {
        return LocalizationString.value(this.product.productGroup?.name);
    }

    private get variants(): string {
        return LocalizationString.value(this.product.variants);
    }

    private get description(): string {
        return LocalizationString.value(this.product.description);
    }

    private getRelatedProductIds(): string[] {
        return this
                .product
                .accessories
                ?.where(item => (item.type == ProductAccessoryType.RelatedProduct))
                ?.select(item => item.relatedProductId)
                .take(5)
            ?? [];
    }

    private getCorrespondingProductIds(): string[] {
        return this
                .product
                .accessories
                ?.where(item => (item.type == ProductAccessoryType.CorrespondingProduct) && (item.product != null))
                ?.select(item => item.relatedProductId)
                .take(5)
            ?? [];
    }

    private getImage(): string | null {
        const images: FileModel[] = this.product.images || [];
        return ImageProvider.getImageSrc(images.firstOrDefault());
    }

    private getCurrency(): string {
        switch (ch.country) {
            case "en":
                return "$";
            case "fi":
                return "€";
            case "se":
                return "kr";
            case "no":
                return "kr";
            case "dk":
                return "kr";
            case "pl":
                return "zł";
            case "ua":
                return "₴";
            case "ru":
                return "₽";
            default:
                throw new Error(`Unsupported country ${ch.country}`);
        }
    }

    private getProductPrice(): string | null {
        const price: number | null = this.product.prices?.firstOrDefault(price => price.country == ch.country)?.amount ?? null;

        if (!price) {
            return null;
        }

        return Utility.toCurrencyString(price);
    }
    
    private getInTouch(): void {
        window.location.href = "https://reapptor.com/#contact";
    }

    private async onPreviewProductClickAsync(event: React.MouseEvent<HTMLAnchorElement>, productId: string): Promise<void> {
        event.preventDefault();
        await PageRouteProvider.redirectAsync(PageDefinitions.productDetails(productId));
    }

    private async onFinishEditAsync(): Promise<void> {
        if (this.props.onFinishEdit) {
            await this.props.onFinishEdit();
        }
    }
    
    private async setNameAsync(name: LocalizationString): Promise<void> {
        this.product.name = name;
    }
    
    private async setDescriptionAsync(description: LocalizationString): Promise<void> {
        this.product.description = description;
    }
    
    private async setVariantsAsync(variants: LocalizationString): Promise<void> {
        this.product.variants = variants;
    }
    
    private async saveAsync(): Promise<void> {
        await this.onFinishEditAsync();
    }
    
    public async initializeAsync(): Promise<void> {
        
        await super.initializeAsync();

        let product: Product;
        if (typeof this.props.product === "string") {
            
            const request = new GetProductRequest();
            request.productId = this.props.product;

            const response: GetProductResponse = await this.postAsync("/api/product/getProduct", request);
            
            product = response.product!;
            
        } else {
            
            product = this.props.product;
            
        }
        
        await this.setState({product});
    }
    
    private renderImage(): React.ReactNode {
        const imageSrc: string | null = this.getImage();

        return (
            <div className={styles.image}>
                <img alt={this.title} src={imageSrc ?? noImage}/>
            </div>
        )
    }

    public renderRelatedProduct(productId: string): React.ReactNode {
        return (
            <ProductCard key={productId}
                         product={productId}
                         mode={ProductCardMode.Preview}
            />
        );
    }
    
    public renderProductPrice(): React.ReactNode {
        const productPrice: string | null = this.getProductPrice();

        return (
            (productPrice) &&
            (
                <h4 className={styles.price}>
                    {productPrice} {this.getCurrency()}/{Utility.formatValue(this.product.unit, nameof(ProductUnit))}
                </h4>
            )
        )
    }

    public renderPreviewMode(): React.ReactNode {
        const product: Product = this.product;
        const title: string = LocalizationString.value(product.name);
        const path: string = Product.getPath(product);
        
        return (
            <a className={styles.preview}
               href={path}
               title={title}
               hrefLang={Localizer.defaultLanguage}
               onClick={(event) => this.onPreviewProductClickAsync(event, product.id)}
            >
                
                <div className={styles.left}>

                    {
                        this.renderImage()
                    }

                </div>
                
                <div className={styles.right}>

                    <h1>{title}</h1>

                    { this.renderProductPrice() }
                    
                </div>

            </a>
        );
    }

    public renderManagementMode(): React.ReactNode {
        return (
            <div className={styles.management}>

                <LocalizationTextInput id={"name"}
                                       label={Localizer.productCardName}
                                       value={this.product.name}
                                       onChange={(_, value) => this.setNameAsync(value)}
                />

                <LocalizationTextInput id={"description"}
                                       type={LocalizationTextInputType.TextAreaInput}
                                       label={Localizer.productCardDescription}
                                       value={this.product.description}
                                       onChange={(_, value) => this.setDescriptionAsync(value)}
                />

                <LocalizationTextInput id={"variants"}
                                       label={Localizer.productCardVariants}
                                       value={this.product.variants}
                                       onChange={(_, value) => this.setVariantsAsync(value)}
                />

                <ButtonContainer className={"mt-5"}>
                    
                    <Button submit
                            label={Localizer.genericSave}
                            onClick={() => this.saveAsync()}
                    />

                    <Button label={Localizer.genericCancel}
                            onClick={() => this.onFinishEditAsync()}
                    />
                    
                </ButtonContainer>

            </div>
        );
    }

    public renderDetailsMode(): React.ReactNode {
        const relatedProductIds: string[] = this.getRelatedProductIds();
        //const correspondingProductIds: string[] = this.getCorrespondingProductIds();

        return (
            <div className={styles.details}>

                <div className={styles.left}>

                    <h1>{this.title}</h1>

                    <h4>{this.variants}</h4>

                    {this.renderProductPrice()}

                    <Button className={styles.getInTouch}
                            title={Localizer.productCardGetInTouch}
                            label={Localizer.productCardGetInTouch}
                            onClick={async () => this.getInTouch()}
                    />

                    <h2 className={"mt-5 mb-4"}>{Localizer.productCardAbout}</h2>

                    <p className={styles.description}>{ReactUtility.toMultiLines(this.description)}</p>
                    
                    <div className={styles.expander}/>

                    <p className={styles.code}>{ReactUtility.toTags(Localizer.productCardCode.format(this.product.code))}</p>
                    
                    <p className={styles.poweredBy}>{ReactUtility.toMultiLines(Localizer.productCardPoweredByReApptor)}</p>
                    
                </div>

                <div className={styles.right}>

                    {
                        this.renderImage()
                    }

                    {
                        ((relatedProductIds) && (relatedProductIds.length > 0)) &&
                        (
                            <div className={styles.related}>
                                <h3>{Localizer.productCardRelated}</h3>
                                {
                                    relatedProductIds.map(productId => this.renderRelatedProduct(productId))
                                }
                            </div>
                        )
                    }

                </div>

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(this.props.className, styles.productCard)}>

                {
                    (this.state.product)
                        ?
                        (
                            <>
                                {
                                    (this.mode == ProductCardMode.Preview) && (this.renderPreviewMode())
                                }

                                {
                                    (this.mode == ProductCardMode.Details) && (this.renderDetailsMode())
                                }

                                {
                                    (this.mode == ProductCardMode.Management) && (this.renderManagementMode())
                                }
                            </>
                        )
                        :
                        (
                            <Spinner/>
                        )
                }

            </div>
        );
    }
}