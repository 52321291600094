import {PageRoute} from "@reapptor-apps/reapptor-react-common";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import CreateOrganizationRequest from "@/models/server/requests/CreateOrganizationRequest";

export default class CustomerApplicationWizardContext {
    public actionInitialPageRoute: PageRoute | null = null;
    
    public customerParameters: CustomerApplicationParameters = new CustomerApplicationParameters();

    public newOrganization: CreateOrganizationRequest = new CreateOrganizationRequest();
    
    public isNewOrganization: boolean = false;
    
    public organizationContractId: string | null = null;
}