// autogenerated

export default class GetInfraResourcesRequest {

    public readonly isGetInfraResourcesRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetInfraResourcesRequest) || ((from as any).isGetInfraResourcesRequest === true));
    }

    public static as(from?: object | null): GetInfraResourcesRequest | null {
        return (GetInfraResourcesRequest.is(from)) ? from as GetInfraResourcesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}