// autogenerated
import CustomerApplicationImages from "@/models/server/infraautomation/CustomerApplicationImages";
import CustomerApplicationLinks from "@/models/server/infraautomation/CustomerApplicationLinks";
import CustomerApplicationBoard from "@/models/server/infraautomation/CustomerApplicationBoard";
import CustomerApplicationColors from "@/models/server/infraautomation/CustomerApplicationColors";
import CustomerApplicationFonts from "@/models/server/infraautomation/CustomerApplicationFonts";
import BaseInfraParameters from "@/models/server/infraautomation/BaseInfraParameters";
import PortalConstants from "@/PortalConstants";

export default class CustomerApplicationParameters extends BaseInfraParameters {

    public applicationName: string | null = null;

    public applicationShortName: string | null = null;

    public applicationDescription: string | null = null;

    public applicationGroup: string | null = null;

    public organizationName: string | null = null;

    public organizationShortName: string | null = null;

    public createTestEnvironment: boolean = false;

    public createStagingEnvironment: boolean = false;

    public testEnvironmentExists: boolean = false;

    public stagingEnvironmentExists: boolean = false;

    public productionEnvironmentExists: boolean = false;

    public devFrontendPort: number = 0;

    public devBackendPort: number = 0;

    public languages: string[] | null = null;

    public defaultLanguage: string | null = null;

    public images: CustomerApplicationImages | null = null;

    public links: CustomerApplicationLinks | null = null;

    public scrumBoard: CustomerApplicationBoard | null = null;

    public colors: CustomerApplicationColors | null = null;

    public fonts: CustomerApplicationFonts | null = null;

    public isWeAre: boolean = false;

    public isReApptor: boolean = false;

    public readonly isCustomerApplicationParameters: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplicationParameters) || ((from as any).isCustomerApplicationParameters === true));
    }

    public static as(from?: object | null): CustomerApplicationParameters | null {
        return (CustomerApplicationParameters.is(from)) ? from as CustomerApplicationParameters : null;
    }

    // #region Placeholder

    constructor() {
        super();
        this.devFrontendPort = 38080;
        this.devBackendPort = 48080;
        this.images = new CustomerApplicationImages();
        this.links = new CustomerApplicationLinks();
        this.colors = new CustomerApplicationColors();
        this.fonts = new CustomerApplicationFonts();
        this.languages = PortalConstants.defaultLanguages;
        this.defaultLanguage = this.languages[0];
        this.createTestEnvironment = true;
    }

    // #endregion
}