// autogenerated
import {ResourceEnvironment} from "@/models/Enums";

export default class GetResourcesCostsRequest {

    public environmentGroup: ResourceEnvironment | null = null;

    public readonly isGetResourcesCostsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetResourcesCostsRequest) || ((from as any).isGetResourcesCostsRequest === true));
    }

    public static as(from?: object | null): GetResourcesCostsRequest | null {
        return (GetResourcesCostsRequest.is(from)) ? from as GetResourcesCostsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}