import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {
    PageContainer,
    PageRow,
    RouteWidget,
    WidgetContainer
} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./Admin.module.scss"

export default class Admin extends AuthorizedPage {

    public getTitle(): string {
        return Localizer.topNavAdmin;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent className={styles.admin}>

                <PageRow className={styles.header}>

                    <label className={styles.title}>{Localizer.topNavAdminManagement.toUpperCase()}</label>

                </PageRow>

                <PageRow className={styles.subheader}>

                    <label className={styles.user}>{this.getContext().username}</label>

                </PageRow>

                <WidgetContainer className={styles.athenaeumWidgetContainerWidgetContainer}>

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "laptop"}}
                                 route={PageDefinitions.applicationsRoute}
                                 label={Localizer.applicationsPageTitle}
                                 description={Localizer.applicationsPageDescription}
                    />

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "users-cog"}}
                                 route={PageDefinitions.userManagementRoute}
                                 label={Localizer.userManagementPageTitle}
                                 description={Localizer.userManagementPageDescription}
                    />

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "cogs"}}
                                 route={PageDefinitions.applicationSettingsRoute}
                                 label={Localizer.applicationSettingsPageTitle}
                                 description={Localizer.applicationSettingsPageDescription}
                    />

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "code"}}
                                 route={PageDefinitions.adminConsoleRoute}
                                 label={Localizer.adminConsolePageTitle}
                                 description={Localizer.adminConsolePageDescription}
                    />

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "file-alt"}}
                                 route={PageDefinitions.organizationContractsRoute}
                                 label={Localizer.organizationsContractsPageTitle}
                                 description={Localizer.organizationsContractsPageDescription}
                    />

                    <RouteWidget className={styles.athenaeumWidgetContainerWidget}
                                 icon={{name: "table"}}
                                 route={PageDefinitions.resourcesCostsRoute}
                                 label={Localizer.adminResourcesCostsPageTitle}
                                 description={Localizer.adminResourcesCostsPageDescription}
                    />

                </WidgetContainer>

            </PageContainer>
        );
    }
}