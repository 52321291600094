// autogenerated

export default class RetryCreateApplicationRequest {

    public customerApplicationId: string = "";

    public readonly isRetryCreateApplicationRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof RetryCreateApplicationRequest) || ((from as any).isRetryCreateApplicationRequest === true));
    }

    public static as(from?: object | null): RetryCreateApplicationRequest | null {
        return (RetryCreateApplicationRequest.is(from)) ? from as RetryCreateApplicationRequest : null;
    }

    // #region Placeholder

    constructor(customerApplicationId: string) {
        this.customerApplicationId = customerApplicationId;
    }

    // #endregion
}