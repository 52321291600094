import React from "react";
import {AlertModel, ApiProvider, IBasePage, PageRoute} from "@reapptor-apps/reapptor-react-common";
import WizardPage from "@/models/base/WizardPage";
import {Button, IWizardStep, IWizardSteps, PageContainer} from "@reapptor-apps/reapptor-react-components";
import Comparator from "@/helpers/Comparator";
import CustomerApplicationWizardContext from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardContext";
import {TBasePageParameters} from "@reapptor-apps/reapptor-react-common/src/base/BasePage";
import PortalController, {IWizardNextStep} from "@/pages/PortalController";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerApplicationWizardPage.module.scss";
import portalStyles from "../../portal.module.scss";
import wizardStyles from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage.module.scss";

export default abstract class CustomerApplicationWizardPage<TProps extends TBasePageParameters = {}, TState = {}> extends WizardPage<TProps, TState> {
    
    private _canNext: boolean | null = null;
    
    private get steps(): IWizardStep[] {
        const steps: IWizardSteps | null = PortalController.getSteps();
        return steps?.steps ?? [];
    }

    private get currentIndex(): number {
        const page: IBasePage = this.getPage();
        const route: PageRoute = page.route;
        return this.steps.findIndex(step => Comparator.isEqual(step.route, route));
    }

    protected saveContext(): void {
        PortalController.saveContext();
    }

    protected async validateAsync(): Promise<boolean> {
        const canNext: boolean = this.canNext;
        if (canNext !== this._canNext) {
            this._canNext = canNext;
            await this.reRenderAsync();
        }
        return canNext;
    }
    
    protected get wizard(): CustomerApplicationWizardContext {
        return PortalController.wizard;
    }

    protected get canPrev(): boolean {
        return true;
    }
    
    protected get canNext(): boolean {
        return true;
    }
    
    public async prevAsync(): Promise<void> {
        if (this.canPrev) {
            const prevRoute: PageRoute = await PortalController.getPrevStepAsync(this.route);

            await this.redirectPrevAsync(prevRoute);
        }
    }
    
    private async invokeRedirectAsync(nextRoute: PageRoute): Promise<void> {
        const alert: AlertModel | null = this.alert;

        await this.redirectNextAsync(nextRoute);

        if (alert != null) {
            await this.alertAsync(alert);
        }
    }
    
    private async completeAsync(nextRoute: PageRoute): Promise<void> {
        await PortalController.completeActionAsync();

        await this.invokeRedirectAsync(nextRoute);
    }

    public async nextAsync(): Promise<void> {

        const canNext: boolean = await this.validateAsync();

        if (canNext) {
            this.saveContext();
            const nextStep: IWizardNextStep = await PortalController.getNextStepAsync(this.route);

            const nextRoute: PageRoute = nextStep.nextRoute;

            if (nextStep.lastStep) {
                await ApiProvider.invokeWithForcedSpinnerAsync(() => this.completeAsync(nextRoute));
            } else {
                await this.invokeRedirectAsync(nextRoute);
            }
        }
    }

    public get title(): string | null {
        const step: IWizardStep = this.steps[this.currentIndex];
        return step.title;
    }
    
    public get navigation(): boolean {
        return true;
    }
    
    public get wide(): boolean {
        return false;
    }

    public abstract renderContent(): React.ReactNode;
    
    public renderButtonsContainer(): React.ReactNode {
        return (
            <div className={styles.buttons}>
                {this.renderButtons()}
            </div>
        );
    }
    
    public renderButtons(): React.ReactNode {
        return (
            <React.Fragment>
                <Button block 
                        className={wizardStyles.outlinedButton}
                        label={Localizer.genericBack?.toUpperCase()} 
                        onClick={() => this.prevAsync()} 
                        icon={ {name: "fa-angle-left"} }
                />
                <Button block
                        className={wizardStyles.filledButton}
                        label={Localizer.genericNext?.toUpperCase()} 
                        onClick={() => this.nextAsync()}
                        icon={ {name: "fa-angle-right"} }
                />
            </React.Fragment>
        );
    }

    public renderStepsCounter(): React.ReactNode {
        return (
            <React.Fragment>
                {
                    <div className={styles.stepCounter}>
                        {Localizer.customerApplicationWizardPageStep} {this.currentIndex + 1}/{this.steps.length}
                    </div>
                }
            </React.Fragment>
        )
    }

    public renderStepsNavigation(): React.ReactNode {
        return (
            <React.Fragment>
                {
                    (this.navigation) &&
                    (
                        <div className={styles.navigation}>
                            {
                                this.steps.map((item, index) => {
                                    const itemStyle = index == this.currentIndex ? styles.active : index > this.currentIndex ? styles.disabled : "";
                                    return (
                                        <div key={index} className={this.css(styles.navigationItem, itemStyle)}/>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </React.Fragment>
        )
    }

    public renderContentContainer(title: string | null, renderContent: () => React.ReactNode): React.ReactNode {
        return (
            <React.Fragment>

                <div className={styles.content}>

                    <div>

                        {
                            (title) &&
                            (
                                <h1 className={portalStyles.title}>{title}</h1>
                            )
                        }

                        { this.renderStepsCounter() }
                        { renderContent() }

                    </div>

                </div>

            </React.Fragment>
        )
    }
    
    public renderPageContent(): React.ReactNode {
        return (
            <React.Fragment>

                { this.renderContentContainer(this.title?.toUpperCase() ?? "", () => this.renderContent()) }

                { this.renderButtonsContainer() }

                { this.renderStepsNavigation() }

            </React.Fragment>
        )
    }

    public render(): React.ReactNode {
        const wideStyle: any = this.wide && styles.wide;
        return (
            <PageContainer fullHeight
                           className={
                               this.css(
                                   portalStyles.pageContainer,
                                   portalStyles.smallContainer,
                                   styles.customerApplicationWizardPage, 
                                   wideStyle
                               )
                           }
                           alertClassName={portalStyles.alert}
            >
                
                { this.renderPageContent() }
                
            </PageContainer>
        );
    }
}