import React from "react";
import AuthorizedPage from "../../../models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";
import {PageContainer, WidgetContainer, RouteWidget} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../../localization/Localizer";

import portalStyles from "../../../portal.module.scss";

interface IDashboardProps  {
}

interface IDashboardState {
}

export default class Dashboard extends AuthorizedPage<IDashboardProps, IDashboardState> {
    
    public getManual(): string {
        return Localizer.rentaTasksDashboardGetManual;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent={this.desktop} className={portalStyles.pageContainer} alertClassName={portalStyles.alert}>

                <WidgetContainer>
                    
                    <RouteWidget icon={{ name: "users" }} route={PageDefinitions.contactSupportRoute} label={Localizer.topNavContactSupport} description={Localizer.dashboardPageContactSupportDescription} />
                    
                    <RouteWidget icon={{ name: "users-cog" }} route={PageDefinitions.adminRoute} label={Localizer.topNavAdmin} description={Localizer.dashboardPageAdminDescription} />
                    
                </WidgetContainer>

            </PageContainer>
        );
    }
}