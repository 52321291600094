import {BasePage, ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {TBasePageParameters} from "@reapptor-apps/reapptor-react-common/src/base/BasePage";
import PageDefinitions from "@/providers/PageDefinitions";
import ApplicationContext from "@/models/server/ApplicationContext";

export default abstract class AnonymousPage<TProps extends TBasePageParameters = {}, TState = {}>
    extends BasePage<TProps, TState, ApplicationContext> {

    protected async getHomeRouteAsync(): Promise<PageRoute> {
        return await this.postAsync("/api/account/getHomePage");
    }

    /**
     * Redirect to the home page.
     */
    protected async redirectHomeAsync(): Promise<void> {
        const route: PageRoute = await this.getHomeRouteAsync();

        await PageRouteProvider.redirectAsync(route, true, true);
    }

    public async initializeAsync(): Promise<void> {

        if (ch.isAuthenticated) {
            const routeName: string = ch.findRouteName();
            const routes: string[] = [
                PageDefinitions.errorRouteName, PageDefinitions.logoutRouteName,
                PageDefinitions.offlineRouteName, PageDefinitions.contactSupportRouteName,
                PageDefinitions.authorsRouteName, PageDefinitions.privacyPolicyRouteName,
                PageDefinitions.productDetailsRouteName, PageDefinitions.productsRouteName,
            ];

            if (!routes.includes(routeName)) {
                await this.redirectHomeAsync();
            }
        }

        await super.initializeAsync();
    }
}