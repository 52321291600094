import React from "react";
import {PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import { LoginResultStatus } from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import ForgotPasswordRequest from "../../models/server/requests/ForgotPasswordRequest";
import {Button, ButtonContainer, Form, IStringInputModel, PageContainer, TextInput} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./ForgotPassword.module.scss";
import portalStyles from "../../portal.module.scss";

export default class ForgotPassword extends AnonymousPage {

    public _usernameInput: IStringInputModel = { value: "" };
    public _formRef: React.RefObject<any> = React.createRef();

    public getTitle(): string {
        return Localizer.forgotPasswordPagePageTitle;
    }

    private async handleSubmitAsync(): Promise<void> {
        const request = new ForgotPasswordRequest(this._usernameInput.value);
        await this.postAsync<LoginResultStatus>("/api/Application/ForgotPassword", request);
    }

    private async redirectToLoginPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.loginRoute);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.container}>
                <h1 className={portalStyles.title}>{Localizer.forgotPasswordPagePageTitle}</h1>
                <p>{Localizer.forgotPasswordPageHelpText}</p>
                
                <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>
                            
                    <TextInput id="username" label={Localizer.forgotPasswordUsernameInput} model={this._usernameInput} required />

                    <ButtonContainer className={styles.buttonsContainer}>
                        
                        <Button className={"outlinedButton"} 
                                label={Localizer.genericBack?.toUpperCase()} 
                                onClick={() => this.redirectToLoginPage()}
                                icon={ {name: "fa-angle-left"} }
                        />
                        
                        <Button submit
                                label={Localizer.forgotPasswordPageResetButton?.toUpperCase()}
                        />
                        
                    </ButtonContainer>

                </Form>
            </PageContainer>
        );
    }
}