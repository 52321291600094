// autogenerated
import CreateOrganizationRequest from "./CreateOrganizationRequest";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";

export default class CreateApplicationRequest {

    public parameters: CustomerApplicationParameters | null = null;

    public createOrganization: CreateOrganizationRequest | null = null;

    public organizationContractId: string | null = null;

    public readonly isCreateApplicationRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CreateApplicationRequest) || ((from as any).isCreateApplicationRequest === true));
    }

    public static as(from?: object | null): CreateApplicationRequest | null {
        return (CreateApplicationRequest.is(from)) ? from as CreateApplicationRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}