import { BaseValidator,
    BaseInputValue,
    ValidatorCallback
} from "@reapptor-apps/reapptor-react-components";

export class MaxLengthValidator extends BaseValidator {
    public maxLength: number;

    constructor(maxLength: number = 0) {
        super();
        this.maxLength = maxLength;
    }

    public validate(value: BaseInputValue): string | null {
        const str: string | null = BaseValidator.toString(value);
        if ((this.maxLength > 0) && ((str == null) || (str.length > this.maxLength))) {
            //return BaseInputLocalizer.validatorsLength;
            return  "The input string length is too long";
        }
        return null;
    }

    public static readonly instance: MaxLengthValidator = new MaxLengthValidator();

    public static validator(maxLength: number = 0): ValidatorCallback<BaseInputValue> {
        return (value: BaseInputValue) => new MaxLengthValidator(maxLength).validate(value);
    }
}