// autogenerated
import {InfraEnvironmentType} from "@/models/Enums";

export default abstract class BaseInfraParameters {

    public environmentType: InfraEnvironmentType = InfraEnvironmentType.Test;

    
    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}