// autogenerated
import {AuthType} from "@/models/Enums";

export default class SaveUserRequest {

    public id: string | null = null;

    public authType: AuthType = AuthType.Email;

    public organizationContractIds: string[] | null = null;

    public email: string | null = null;

    public phone: string | null = null;

    public firstname: string | null = null;

    public middleName: string | null = null;

    public lastName: string | null = null;

    public employeeNumber: string | null = null;

    public externalId: string | null = null;

    public language: string | null = null;

    public formattedAddress: string | null = null;

    public roleName: string | null = null;

    public contractSalaryHourlyPrice: number = 0;

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public readonly isSaveUserRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveUserRequest) || ((from as any).isSaveUserRequest === true));
    }

    public static as(from?: object | null): SaveUserRequest | null {
        return (SaveUserRequest.is(from)) ? from as SaveUserRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}