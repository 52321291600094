// autogenerated

export default class CreateOrganizationRequest {

    public name: string | null = null;

    public vatId: string | null = null;

    public country: string | null = null;

    public formattedAddress: string | null = null;

    public readonly isCreateOrganizationRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CreateOrganizationRequest) || ((from as any).isCreateOrganizationRequest === true));
    }

    public static as(from?: object | null): CreateOrganizationRequest | null {
        return (CreateOrganizationRequest.is(from)) ? from as CreateOrganizationRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}