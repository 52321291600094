// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {LocalizationString, ch} from "@reapptor-apps/reapptor-react-common";
import {ProductUnit, ProductPeriodType, ProductInvoicingModel} from "@/models/Enums";
import ProductGroup from "@/models/server/products/ProductGroup";
import ProductAccessory from "@/models/server/products/ProductAccessory";
import ProductPrice from "@/models/server/products/ProductPrice";
import Localizer from "@/localization/Localizer";

export default class Product {

    public id: string = "";

    public productGroupId: string = "";

    public productGroup: ProductGroup | null = null;

    public code: string | null = null;

    public path: string | null = null;

    public name: LocalizationString | null = null;

    public description: LocalizationString | null = null;

    public variants: LocalizationString | null = null;

    public packageQuantity: number | null = null;

    public unit: ProductUnit = ProductUnit.Hour;

    public periodType: ProductPeriodType = ProductPeriodType.OneTime;

    public invoicingModels: ProductInvoicingModel[] | null = null;

    public deleted: boolean = false;

    public accessories: ProductAccessory[] | null = null;

    public images: FileModel[] | null = null;

    public prices: ProductPrice[] | null = null;

    public readonly isProduct: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Product) || ((from as any).isProduct === true));
    }

    public static as(from?: object | null): Product | null {
        return (Product.is(from)) ? from as Product : null;
    }

    // #region Placeholder

    private static trimUrl(url: string): string {
        while (url.endsWith("/")) {
            url = url.substring(0, url.length - 2);
        }

        return url;
    }

    public static getPath(product: Product): string {
        if (!!product.path) {
            const path: string = (Localizer.defaultLanguage != Localizer.language)
                ? `${this.trimUrl(product.path)}/?lang=${Localizer.language}`
                : this.trimUrl(product.path);

            return new URL(path, ch.applicationUrl).toString();
        }

        const title: string = LocalizationString.value(product.name);
        return `#${title}`;
    }

    // #endregion
}