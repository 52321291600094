import React from "react";
import {
    Button,
    ButtonType,
    CellModel,
    Checkbox,
    ColumnActionDefinition,
    ColumnDefinition,
    Dropdown,
    DropdownOrderBy,
    Grid,
    InlineType,
    PageContainer,
    PageHeader,
    PageRow,
    TextInput,
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ActionType, CountryInfo, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import OrganizationContract from "@/models/server/OrganizationContract";
import ListOrganizationContractsRequest from "../../models/server/requests/ListOrganizationContractsRequest";
import PageDefinitions from "../../providers/PageDefinitions";
import {OrganizationContractLevel} from "@/models/Enums";
import Localizer from "@/localization/Localizer";

import styles from "./OrganizationContracts.module.scss";

interface IOrganizationContractProps {
}

interface IOrganizationContractState {
    name: string,
    vatId: string,
    countryCode: string,
    active: boolean,
    organizationContracts: OrganizationContract[];
    countries: CountryInfo[];
    selectedCountry: CountryInfo | null;
}

export default class OrganizationContracts extends AuthorizedPage<IOrganizationContractProps, IOrganizationContractState> {
    
    state: IOrganizationContractState = {
        name: "",
        vatId: "",
        countryCode: "",
        active: false,
        organizationContracts: [],
        countries: [],
        selectedCountry: null,
    }
    
    private readonly _pageSize: number = 20;

    private readonly _settingsGridRef: React.RefObject<Grid<OrganizationContract>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 30,
        } as ColumnDefinition,
        {
            header: Localizer.organizationContractsPageGridActiveLanguageItemName,
            accessor: nameof<OrganizationContract>(o => o.active),
            minWidth: 30,
            render: (cell: CellModel<any>): React.ReactNode => this.renderActiveCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.organizationContractsPageGridCountryLanguageItemName,
            accessor: "organization.country",
            minWidth: 70,             
        } as ColumnDefinition,
        {
            header: Localizer.organizationContractsPageGridNameLanguageItemName,
            accessor: nameof<OrganizationContract>(o => o.name),
            minWidth: 150,
        } as ColumnDefinition,
        {
            header: Localizer.organizationsContractsPageGridVatLanguageItemName,
            //accessor: nameof<OrganizationContract>(o => o.organization?.vatId),
            accessor: "organization.vatId",
            minWidth: 120,
        } as ColumnDefinition,
        {
            header: Localizer.organizationContractsPageGridAddressLanguageItemName,
            accessor: nameof<OrganizationContract>(o => o.externalAddress),
            minWidth: 300,
        } as ColumnDefinition,
        {
            header: Localizer.organizationContractsPageGridContractLevelLanguageItemName,
            accessor: nameof<OrganizationContract>(o => o.level),            
            minWidth: 70,
            transform: (cell): string => this.transformOrganizationContractLevelCell(cell),
        } as ColumnDefinition,
        {
            header: Localizer.applicationsPageGridChangeStatusLanguageItemName,
            minWidth: 70,
            removable: false,
            reRenderRow: true,
            init: (cell) => this.initOrganizationContractActions(cell),
            actions: [
                {
                    name: "activate",
                    title: Localizer.organizationsContractsPageButtonActivateLanguageItemName,
                    type: ActionType.Create,
                    icon: "fa-lg fas fa-check-circle text-primary",
                    confirm: Localizer.organizationsContractsPageActivateConfirm,
                    callback: async (cell) => await this.activateOrganizationContractAsync(cell)
                } as ColumnActionDefinition,
                {
                    name: "deactivate",
                    title: Localizer.organizationsContractsPageButtonDeactivateLanguageItemName,
                    type: ActionType.Default,
                    icon: "fa-lg fas fa-ban text-danger",
                    confirm: Localizer.organizationsContractsPageDeactivateConfirm,
                    callback: async (cell) => await this.deactivateOrganizationContractAsync(cell)
                } as ColumnActionDefinition    
            ]
        } as ColumnDefinition
    ];

    private async setNameAsync(name: string): Promise<void> {
        await this.setState({ name });
    }

    private async setVatIdAsync(vatId: string): Promise<void> {
        await this.setState({ vatId });
    }

    private async setCountryAsync(value: CountryInfo): Promise<void> {
        await this.setState({selectedCountry: value});
    }    
    
    private async setActiveAsync(active: boolean): Promise<void> {
        await this.setState({ active });
    }

    private transformOrganizationContractLevelCell(cellModel: CellModel<OrganizationContractLevel[]>): string {
        return OrganizationContractLevel[cellModel.value];
    }

    public renderActiveCell(cellModel: CellModel<OrganizationContract>): React.ReactNode {
        const model: OrganizationContract = cellModel.model;

        return (
            <span className="fa-lg">
                {
                    model.active 
                        ? <i className="fas fa-check-circle text-primary"/>
                        : <i className="fas fa-ban text-danger"/>
                }                
            </span>
        );
    }
    
    private initOrganizationContractActions(cell: CellModel<OrganizationContract>): void {
        const model: OrganizationContract = cell.model;

        cell.actions[0].visible = !model.active;
        cell.actions[1].visible = model.active;
    }    
    
    private async activateOrganizationContractAsync(cell: CellModel<OrganizationContract>): Promise<void> {
        const model: OrganizationContract = cell.model;
        
        await this.changeOrganizationContractStatusAsync(model.id, true);
        
        model.active = true;
        
        await cell.row.reRenderAsync(false);
    }

    private async deactivateOrganizationContractAsync(cell: CellModel<OrganizationContract>): Promise<void> {
        const model: OrganizationContract = cell.model;
        
        await this.changeOrganizationContractStatusAsync(model.id, false);
        
        model.active = false;
        
        await cell.row.reRenderAsync(false);
    }    
    
    private async changeOrganizationContractStatusAsync(organizationContractId: string, setActive: boolean): Promise<void> {
        if (setActive) {
            return await this.postAsync("/api/organizationContracts/SetOrganizationContractActive", organizationContractId);
        } else {
            return await this.postAsync("/api/organizationContracts/SetOrganizationContractInactive", organizationContractId);
        }
    }
    
    private async fetchOrganizationContractsAsync(sender: IBaseComponent, pageNumber: number, pageSize: number): Promise<OrganizationContract[]> {
        const request = new ListOrganizationContractsRequest();
        request.active = this.state.active;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.countryCode = this.state.selectedCountry ? this.state.selectedCountry.code : null;
        request.vatId = this.state.vatId;
        request.name = this.state.name;
        
        return await sender.postAsync("/api/organizationContracts/listOrganizationContracts", request);
    }

    private async reloadAsync(): Promise<void> {
        await this._settingsGridRef.current?.reloadAsync();
    }

    public getTitle(): string {
        return Localizer.organizationsContractsPageTitle;
    }

    public get organizationContracts(): OrganizationContract[] {
        return this.state.organizationContracts;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const countries: CountryInfo[] = await this.getAsync("/api/createOrganization/listSupportedCountries");

        await this.setState({countries});
    }    

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.organizationContracts}>

                <PageHeader title={this.getTitle()} />

                <PageRow>
                    <div className="col">

                        <div >
                            
                            <div className={styles.toolbar}>
                                
                                <div className={styles.toolbarItem}>

                                    <TextInput autoFocus
                                               className={styles.name}
                                               placeholder={Localizer.organizationsContractsPageToolbarNameSearch}
                                               value={this.state.name}
                                               onChange={(sender, value) => this.setNameAsync(value)}
                                    />

                                </div>

                                <div className={styles.toolbarItem}>

                                    <Button icon={{name: "fa-lg fas fa-eraser"}}
                                            className={styles.eraseBtn}
                                            onClick={async () => await this.setNameAsync("")}
                                    />

                                </div>
                                

                                <div className={styles.toolbarItem}>
                                    <TextInput className={styles.vatId}
                                               placeholder={Localizer.organizationsContractsPageToolbarVatSearch}
                                               value={this.state.vatId}
                                               onChange={(sender, value) => this.setVatIdAsync(value)}
                                    />                                    
                                </div>

                                <div className={styles.toolbarItem}>
                                    <Button icon={{name: "fa-lg fas fa-eraser"}}
                                            className={styles.eraseBtn}
                                            onClick={async () => await this.setVatIdAsync("")}
                                    />                                    
                                </div>

                                <div className={styles.toolbarItem}>
                                    <Dropdown noSubtext
                                              nothingSelectedText={Localizer.organizationsContractsPageDropdownNothingSelectedText}
                                              className={styles.country}
                                              orderBy={DropdownOrderBy.None}
                                              items={this.state.countries}
                                              selectedItem={this.state.selectedCountry}
                                              onChange={(sender, item) => this.setCountryAsync(item!)}
                                    />                                    
                                </div>

                                <div className={styles.toolbarItem}>
                                    <Checkbox label={Localizer.organizationsContractsPageToolbarShowActive}
                                              className={styles.showDeleted}
                                              inlineType={InlineType.Right}
                                              value={this.state.active}
                                              onChange={async (sender, value) => await this.setActiveAsync(value)}
                                              inline
                                    />
                                </div>

                                <div className={styles.toolbarItem}>
                                    
                                    <Button label={Localizer.genericSearch}
                                            className={styles.searchBtn}
                                            onClick={async () => await this.reloadAsync()}
                                    />
                                    
                                </div>

                                <div className={styles.toolbarItem}>
                                    
                                    <Button icon={{name: "fas arrow-alt-circle-left"}}
                                            className={styles.backBtn}
                                            title={Localizer.genericBack}
                                            route={PageDefinitions.adminRoute}
                                    />
                                    
                                </div>

                            </div>

                            <div className={styles.container}>

                                <Grid responsive
                                      id="organizationContracts" ref={this._settingsGridRef}
                                      pagination={this._pageSize}
                                      columns={this._columns}
                                      fetchData={async (sender, pageNumber: number, pageSize: number) => await this.fetchOrganizationContractsAsync(sender, pageNumber, pageSize)}
                                />                                

                            </div>

                        </div>

                    </div>
                    
                </PageRow>

            </PageContainer>
        );
    }    
}