import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import User from "../../models/server/User";
import Setting from "../../models/server/Setting";
import {
    Button, ButtonContainer, ButtonType,
    CellModel,
    ColumnDefinition,
    ColumnType,
    Form,
    Grid,
    PageContainer,
    PageHeader,
    PageRow
} from "@reapptor-apps/reapptor-react-components";
import Localizer from "../../localization/Localizer";

import styles from "./ApplicationSettings.module.scss";

interface IApplicationSettingsProps {
}

interface IApplicationSettingsState {
    settingsModified: boolean;
}

export default class ApplicationSettings extends AuthorizedPage<IApplicationSettingsProps, IApplicationSettingsState> {

    state: IApplicationSettingsState = {
        settingsModified: false
    };

    private readonly _settingsGridRef: React.RefObject<Grid<Setting>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.adminSettingsKeyLanguageItemName,
            accessor: "key",
            transform: (cell): string => this.transformKeyCell(cell),
            minWidth: 300,
        } as ColumnDefinition,
        {
            header: Localizer.adminSettingsValueLanguageItemName,
            accessor: "value",
            editable: true,
            type: ColumnType.Text,
            minWidth: 150,
            callback: async (cell) => await this.onChangeSettingsAsync(cell)
        } as ColumnDefinition
    ];

    private get settings(): Setting[] {
        return this.settingsGrid.model.items;
    }

    private get hasSettingsAccess(): boolean {
        const user: User | null = this.findUser();
        return ((user != null) && (user.isAdmin));
    }

    private transformKeyCell(cell: CellModel<Setting>): string {
        const setting: Setting = cell.model;

        const key: string = setting.key;

        return Localizer.get(`KnownSettings.${key}`);
    }

    private async handleSettingsSubmitAsync(): Promise<void> {

        await this.postAsync("/api/admin/saveSettings", this.settings);

        await this.setState({settingsModified: false});

        await this.alertMessageAsync(Localizer.adminSettingsSaved, true);
    }

    private async getSettingsAsync(grid: Grid<Setting>): Promise<Setting[]> {
        return await grid.getAsync("/api/admin/listSettings");
    }

    private async onChangeSettingsAsync(cell: CellModel): Promise<void> {
        await this.setState({settingsModified: cell.grid.modified});
    }

    private get settingsGrid(): Grid<Setting> {
        return this._settingsGridRef.current!;
    }

    public getTitle(): string {
        return Localizer.applicationSettingsPageTitle;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.applicationSettings}>
                
                <PageHeader title={Localizer.applicationSettingsPageTitle} />

                <PageRow>
                    <div className="col">

                        <Form id="form" onSubmit={async () => await this.handleSettingsSubmitAsync()}>

                            <div>

                                <Grid id="settings" ref={this._settingsGridRef}
                                      minWidth={400}
                                      readonly={!this.hasSettingsAccess}
                                      columns={this._columns}
                                      fetchData={async (sender) => await this.getSettingsAsync(sender)}
                                />

                            </div>

                            <ButtonContainer>

                                {
                                    (this.hasSettingsAccess) &&
                                    (
                                        <Button disabled={!this.state.settingsModified} type={ButtonType.Orange} icon={{name: "far save"}} label={Localizer.formSave} submit/>
                                    )
                                }

                            </ButtonContainer>

                        </Form>

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}