import React from "react";
import {Form, TextInput, FileInput, RegexValidator} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationFonts from "@/models/server/infraautomation/CustomerApplicationFonts";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";
import styles from "./Fonts.module.scss"

export interface IFontsProps {
}

interface IFontsState {
}

export default class Fonts extends CustomerApplicationWizardPage<IFontsProps, IFontsState> {

    state: IFontsState = {
    };
    
    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _primaryFileInputRef: React.RefObject<FileInput> = React.createRef();
    private readonly _secondaryFileInputRef: React.RefObject<FileInput> = React.createRef();
    
    private getFileNameWithoutExtension(file: FileModel): string {
        return file.name.split(".")[0];
    }

    private get primaryFileInputValue(): FileModel | null {
        return (this.customerFonts.primaryFontSourceFile) 
            ? new FileModel(this.customerFonts.primaryFontSourceFile)
            : null;
    }

    private get secondaryFileInputValue(): FileModel | null {
        return (this.customerFonts.secondaryFontSourceFile)
            ? new FileModel(this.customerFonts.secondaryFontSourceFile)
            : null;
    }

    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync();
    }
    
    protected get customerFonts(): CustomerApplicationFonts {
        return PortalController.wizard.customerParameters.fonts!;
    }

    public getTitle(): string {
        return Localizer.wizardFontsStepTitle;
    }

    public async setPrimaryFontNameAsync(value: string): Promise<void> {
        this.customerFonts.primaryFontName = value;
        this.saveContext();
    }

    public async setPrimaryFontSourceFileAsync(value: FileModel | null): Promise<void> {
        this.customerFonts.primaryFontSourceFile = value?.src ?? null;
        if (value) {
            this.customerFonts.primaryFontName = this.getFileNameWithoutExtension(value);
        }
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setSecondaryFontNameAsync(value: string): Promise<void> {
        this.customerFonts.secondaryFontName = value;
        this.saveContext();
    }

    public async setSecondaryFontSourceFileAsync(value: FileModel | null): Promise<void> {
        this.customerFonts.secondaryFontSourceFile = value?.src ?? null;
        if (value) {
            this.customerFonts.secondaryFontName = this.getFileNameWithoutExtension(value);
        }
        this.saveContext();
        await this.reRenderAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={this.css(wizardStyles.wizardStepForm, styles.fonts)}>
                
                <TextInput className={wizardStyles.item}
                           required={!!this.customerFonts.primaryFontSourceFile}
                           label={Localizer.wizardFontsStepPrimaryFontNamePlace}
                           value={this.customerFonts.primaryFontName || undefined}
                           validators={[RegexValidator.validator(PortalConstants.fontNameValidationRegex, Localizer.wizardApplicationStepAppTitleValidator)]}
                           onChange={(sender, value) => this.setPrimaryFontNameAsync(value)}
                />
                
                <FileInput ref={this._primaryFileInputRef} 
                           className={wizardStyles.item}
                           label={Localizer.wizardFontsStepPrimaryFontSourceFilePlace}
                           maxSize={PortalConstants.maxFontSizeInBytes}
                           fileTypes={PortalConstants.fontFileTypes}
                           value={this.primaryFileInputValue}
                           onChange={(sender, value) => this.setPrimaryFontSourceFileAsync(value as FileModel | null)}
                           onRemove={() => this.setPrimaryFontSourceFileAsync(null)}
                />
                
                <hr/>
                
                <TextInput className={wizardStyles.item}
                           required={!!this.customerFonts.secondaryFontSourceFile}
                           label={Localizer.wizardFontsStepSecondaryFontNamePlace}
                           value={this.customerFonts.secondaryFontName || undefined}
                           validators={[RegexValidator.validator(PortalConstants.fontNameValidationRegex, Localizer.wizardApplicationStepAppTitleValidator)]}
                           onChange={(sender, value) => this.setSecondaryFontNameAsync(value)}
                />
                
                <FileInput ref={this._secondaryFileInputRef} 
                           className={wizardStyles.item}
                           label={Localizer.wizardFontsStepSecondaryFontSourceFilePlace}
                           fileTypes={PortalConstants.fontFileTypes}
                           maxSize={PortalConstants.maxFontSizeInBytes}
                           value={this.secondaryFileInputValue}
                           onChange={(sender, value) => this.setSecondaryFontSourceFileAsync(value as FileModel | null)}
                           onRemove={() => this.setSecondaryFontSourceFileAsync(null)}
                />
                
            </Form>
        );
    }
}