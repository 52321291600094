import React from "react";
import {BasePageParameters} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import User from "../../models/server/User";
import {Button, ButtonContainer, EmailInput, Form, IStringInputModel, PageContainer, TextAreaInput, TextInput, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import PortalController from "@/pages/PortalController";
import Localizer from "../../localization/Localizer";

import portalStyles from "@/portal.module.scss";
import contactSupportStyles from "@/pages/ContactSupport/ContactSupport.module.scss";

export interface IContactSupportParameters extends BasePageParameters {
    requestId: string | null;
}

export default class ContactSupport extends AnonymousPage<IContactSupportParameters> {
    
    public email: IStringInputModel = { value: "" };
    public phone: IStringInputModel = { value: "" };
    public firstname: IStringInputModel = { value: "" };
    public lastname: IStringInputModel = {value: ""};
    public message: IStringInputModel = {value: ""};
    public formRef: React.RefObject<any> = React.createRef();
     
    public async initializeAsync(): Promise<void> {
        const user: User | null = this.findUser();
        if (user) {
            this.email.value = user.email || "";
            this.phone.value = user.phone || "";
            this.firstname.value = user.firstname || "";
            this.lastname.value = user.lastName || "";
        }
    }

    public async handleSubmitAsync(data: Dictionary<string, any>): Promise<void> {
        const contactSupportRequest = {} as any;
        data.keys().map(key => {
            return contactSupportRequest[key] = data.getValue(key);
        });
        
        await this.postAsync("/api/ContactSupport/ContactSupport", contactSupportRequest);
    }

    public get requestId(): string | null {
        return (this.props.parameters != null) ? this.props.parameters.requestId : null;
    }

    public getTitle(): string {
        return PortalController.getFullPageTitle(Localizer.topNavContactSupport);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(portalStyles.smallContainer, contactSupportStyles.container)}>

                <LdDataHeader canonical={"/support"} />

                <h1 className={portalStyles.title}>{Localizer.contactSupportPageTitle?.toUpperCase()}</h1>
                
                <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(data)} ref={this.formRef}>
                            
                    <EmailInput id="email" label={Localizer.formInputEmail} model={this.email} required readonly={!!this.email.value} />

                    <div className={contactSupportStyles.nameContainer}>
                        <TextInput id="firstname" className={contactSupportStyles.firstName} label={Localizer.formInputFirstname} model={this.firstname} />
                        <TextInput id="lastname" className={contactSupportStyles.lastName} label={Localizer.formInputLastname} model={this.lastname} />
                    </div>
                            
                    <TextInput id="phone" label={Localizer.formInputPhone} model={this.phone} />
                            
                    <TextAreaInput id="message" label={Localizer.formInputMessage} model={this.message} required rows={6} />
                            
                    <ButtonContainer>
                        <Button label={Localizer.formSend} submit />
                    </ButtonContainer>
                            
                </Form>
                
            </PageContainer>
        );
    }
}