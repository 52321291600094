// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class FindProductRequest extends ETagRequest {

    public pathOrId: string | null = null;

    public readonly isFindProductRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof FindProductRequest) || ((from as any).isFindProductRequest === true));
    }

    public static as(from?: object | null): FindProductRequest | null {
        return (FindProductRequest.is(from)) ? from as FindProductRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}