import React from "react";
import {
    Form,
    RegexValidator,
    TextAreaInput,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import PortalConstants from "@/PortalConstants";
import {ch} from "@reapptor-apps/reapptor-react-common";
import {MaxLengthValidator} from "@/helpers/MaxLengthValidator";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface IApplicationProps {
}

interface IApplicationState {
}

export default class Application extends CustomerApplicationWizardPage<IApplicationProps, IApplicationState> {

    state: IApplicationState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private async checkApplicationNameUniquenessAsync(): Promise<boolean> {

        if (this.wizard.isNewOrganization) {
            return true;
        }

        const applicationName: string = (this.customerParameters.applicationGroup)
            ? `${this.customerParameters.applicationGroup}.${this.customerParameters.applicationShortName}`
            : this.customerParameters.applicationShortName ?? "";
        
        const applicationFullName: string = `${this.customerParameters.organizationShortName}.${applicationName}`;

        const unique: boolean = await this.postAsync("/api/managementConsole/isApplicationFullNameUnique", applicationFullName);

        if (!unique) {
            const msg: string = Localizer.wizardApplicationStepCheckApplicationNameUniqueness.format(applicationName);
            await ch.alertErrorAsync(msg, true);
        }

        return unique;
    }
    
    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync() && await this.checkApplicationNameUniquenessAsync();
    }

    protected get customerParameters(): CustomerApplicationParameters {
        return PortalController.wizard.customerParameters;
    }

    protected get applicationShortNameMaxLength(): number {
        return (PortalConstants.maxCompanyAndApplicationShortNameLength -
            (PortalController.wizard.customerParameters.organizationShortName?.length ?? 0));
    }

    public getTitle(): string {
        return Localizer.wizardApplicationStepTitle;
    }

    public async setApplicationNameAsync(value: string): Promise<void> {
        this.customerParameters.applicationName = value;
        this.saveContext();
    }

    public async setApplicationGroupAsync(value: string): Promise<void> {
        this.customerParameters.applicationGroup = value;
        this.saveContext();
    }

    public async setApplicationShortNameAsync(value: string | null): Promise<void> {
        this.customerParameters.applicationShortName = value;
        this.saveContext();
    }

    public async setApplicationDescriptionAsync(value: string): Promise<void> {
        this.customerParameters.applicationDescription = value;
        this.saveContext();
    }

    public async autoFillApplicationShortNameAsync(): Promise<void> {
        if ((this.customerParameters.applicationName) && (!this.customerParameters.applicationShortName)) {
            const defaultApplicationShortName: string | null = this.customerParameters.applicationName.toPascalCase();
            await this.setApplicationShortNameAsync(defaultApplicationShortName);
            await this.reRenderAsync();
        }
    }

    public get canNext(): boolean {
        return (!!this.customerParameters.applicationShortName) && (!!this.customerParameters.applicationName);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={wizardStyles.wizardStepForm}>

                <TextInput id={"application.title"} required autoFocus noAutoComplete trim
                           label={Localizer.wizardApplicationStepAppTitleLanguageItemName}
                           className={wizardStyles.item}
                           value={this.customerParameters.applicationName || undefined}
                           validators={[RegexValidator.validator(PortalConstants.titleValidationRegex, Localizer.wizardApplicationStepAppTitleValidator)]}
                           onChange={(sender, value) => this.setApplicationNameAsync(value)}
                           onBlur={() => this.autoFillApplicationShortNameAsync()}
                />

                <TextInput id={"application.group"} noAutoComplete trim
                           label={Localizer.wizardApplicationStepAppGroupLanguageItemName}
                           className={wizardStyles.item}
                           validators={[RegexValidator.validator(PortalConstants.applicationShortNameValidationRegex, Localizer.wizardApplicationStepAppGroupValidator)]}
                           value={this.customerParameters.applicationGroup || undefined}
                           onChange={(sender, value) => this.setApplicationGroupAsync(value)}
                />

                <TextInput id={"application.shortName"} required noAutoComplete trim
                           label={Localizer.wizardApplicationStepAppNameLanguageItemName}
                           title={Localizer.wizardApplicationStepAppNameTitleLanguageItemName}
                           className={wizardStyles.item}
                           validators={[RegexValidator.validator(PortalConstants.applicationShortNameValidationRegex, Localizer.wizardApplicationStepAppNameValidator),
                                        MaxLengthValidator.validator(this.applicationShortNameMaxLength)]}
                           maxLength={this.applicationShortNameMaxLength}
                           value={this.customerParameters.applicationShortName || undefined}
                           onChange={(sender, value) => this.setApplicationShortNameAsync(value)}
                />

                <TextAreaInput id={"application.description"}
                               label={Localizer.wizardApplicationStepAppDescriptionLanguageItemName}
                               className={wizardStyles.item}
                               value={this.customerParameters.applicationDescription || undefined}
                               onChange={(sender, value) => this.setApplicationDescriptionAsync(value)}
                />

            </Form>
        );
    }
}