import React from "react";
import {ActionType, BaseComponent, IBaseComponent} from "@reapptor-apps/reapptor-react-common";
import ApplicationLink from "@/models/server/infraautomation/ApplicationLink";
import ListApplicationLinksRequest from "@/models/server/requests/ListApplicationLinksRequest";
import {CellAction, CellModel, ColumnActionDefinition, ColumnDefinition, Grid} from "@reapptor-apps/reapptor-react-components";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

interface IApplicationLinksPanelProps {
    customerApplicationId: string,
}

interface IApplicationLinksPanelState {
}

export default class ApplicationLinksPanel extends BaseComponent<IApplicationLinksPanelProps, IApplicationLinksPanelState> {

    state: IApplicationLinksPanelState = {
    };

    private readonly _appLinksGridRef: React.RefObject<Grid<ApplicationLink>> = React.createRef();
    
    private readonly _linksColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 40
        } as ColumnDefinition,
        {
            header: Localizer.applicationLinksPanelGridLinkTypeLanguageItemName,
            accessor: (model) => EnumProvider.getApplicationLinkTypeText(model.type),
            minWidth: 70,
        } as ColumnDefinition,
        {
            header: Localizer.applicationLinksPanelGridEnvironmentLanguageItemName,
            accessor: (model) => EnumProvider.getInfraEnvironmentTypeText(model.environmentType),
            minWidth: 70,
        } as ColumnDefinition,
        {
            header: Localizer.applicationLinksPanelGridApplicationLinkLanguageItemName,
            actions: [
                {
                    type: ActionType.Info,
                    render: (cell: CellModel<any>, action: CellAction<any>) => this.renderAppLink(cell),
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,        
    ]
    
    private async fetchApplicationLinksAsync(sender: IBaseComponent): Promise<ApplicationLink[]> {
        if (this.props.customerApplicationId != null) {
            const request = new ListApplicationLinksRequest();
            request.customerApplicationId = this.props.customerApplicationId;
            return await sender.postAsync("/api/managementConsole/getApplicationLinks", request);
        }

        return [];
    }
    
    private renderAppLink(cell: CellModel<any>): React.ReactNode {
        return (

            <span key={cell.model.id}>
                <a href={cell.model.url} target="_blank">
                    {cell.model.url}
                </a>
            </span>

        );
    }

    public render(): React.ReactNode {

        return (

            <div>

                <Grid ref={this._appLinksGridRef} responsive
                      columns={this._linksColumns}
                      noDataText={Localizer.applicationLinksPanelGridNoDataTextLanguageItemName}
                      fetchData={async (sender) => await this.fetchApplicationLinksAsync(sender)}
                />

            </div>

        );        
        
    }
}