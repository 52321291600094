// autogenerated

export default class CustomerApplicationLinks {

    public facebookUrl: string | null = null;

    public instagramUrl: string | null = null;

    public youtubeUrl: string | null = null;

    public linkedinUrl: string | null = null;

    public twitterUrl: string | null = null;

    public discordUrl: string | null = null;

    public readonly isCustomerApplicationLinks: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplicationLinks) || ((from as any).isCustomerApplicationLinks === true));
    }

    public static as(from?: object | null): CustomerApplicationLinks | null {
        return (CustomerApplicationLinks.is(from)) ? from as CustomerApplicationLinks : null;
    }

    // #region Placeholder

    constructor() {
        this.facebookUrl = "https://www.facebook.com/wearesolutionsfi";
        this.instagramUrl = "https://www.instagram.com/weare.fi/";
        this.youtubeUrl = "https://www.youtube.com/channel/UCrLm15Qby7foa-X48YxQV6A";
        this.linkedinUrl = "https://www.linkedin.com/company/weare-solutions-oy";
        this.twitterUrl = "https://www.twitter.com/user-name";
        this.discordUrl = "https://www.discordapp.com/users/user-id";
    }

    // #endregion
}