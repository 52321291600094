// autogenerated

export default class CheckOrganizationUniquenessRequest {

    public readonly isCheckOrganizationUniquenessRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CheckOrganizationUniquenessRequest) || ((from as any).isCheckOrganizationUniquenessRequest === true));
    }

    public static as(from?: object | null): CheckOrganizationUniquenessRequest | null {
        return (CheckOrganizationUniquenessRequest.is(from)) ? from as CheckOrganizationUniquenessRequest : null;
    }

    // #region Placeholder

    public organizationName: string = "";

    public organizationVatId: string = "";

    // #endregion
}