import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";

export default class ImageProvider {

    public static getImageStyle(image?: FileModel | string | null, defaultSrc?: string | null): object | null {
        const url: string | null = this.getImageSrc(image, defaultSrc);
        return (url) ? { background: `url(${url})` } : null;
    }

    public static getImageSrc(image?: FileModel | string | null, defaultSrc?: string | null): string | null {
        const endpoint: string = `/api/files/images/`
        return (image != null)
            ? (typeof image === "string")
                ? (Utility.isGuid(image))
                    ? `${endpoint}${image}`
                    : image || defaultSrc || null
                : (image.src)
                    ? image.src
                    : (image.id)
                        ? `${endpoint}${image.id}`
                        : defaultSrc || null
            : defaultSrc || null;
    }

    public static getRequiredImageSrc(image: FileModel | string | null | undefined, defaultSrc: string): string {
        return ImageProvider.getImageSrc(image, defaultSrc)!;
    }
}