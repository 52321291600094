import React from "react";
import {Form, ImageInput} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationImages from "@/models/server/infraautomation/CustomerApplicationImages";
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import PortalConstants from "@/PortalConstants";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";
import styles from "./Icons.module.scss";

import weAreSmallIcon from "./Images/weare-logox192.png";
import boatSmallIcon from "./Images/boat-logox192.png";
import mediqSmallIcon from "./Images/mediq-logox192.png";
import weAreFavicon from "./Images/weare-favicon.ico";
import boatFavicon from "./Images/boat-favicon.ico";
import mediqFavicon from "./Images/mediq-favicon.ico";
import ebisusFavicon from "./Images/ebisus-favicon.ico";

export interface IIconsProps {
}

interface IIconsState {
}

export default class CompanyLogo extends CustomerApplicationWizardPage<IIconsProps, IIconsState> {

    state: IIconsState = {
    };

    public getTitle(): string {
        return Localizer.wizardIconsStepTitle;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerImages(): CustomerApplicationImages {
        return PortalController.wizard.customerParameters.images!;
    }

    public async setFaviconAsync(value: FileModel[]): Promise<void> {
        this.customerImages.favicon = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setIconSmallAsync(value: FileModel[]): Promise<void> {
        this.customerImages.logoIconSmall = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setIconLargeAsync(value: FileModel[]): Promise<void> {
        this.customerImages.logoIconLarge = value[0]?.src
        this.saveContext();
        await this.reRenderAsync();
    }
    
    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <p>{Localizer.wizardIconsStepFaviconPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.favicon || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxFaviconSizeInBytes}
                            fileTypes={[".ico"]}
                            onChange={(sender, value) => this.setFaviconAsync(value)}
                />
                <p>{Localizer.wizardIconsStepExamplesPlace}</p>
                <div className={this.css(wizardStyles.imageItem, styles.faviconContainer)}>
                    <img src={weAreFavicon} alt={Localizer.wizardIconsStepCompanyFaviconAlt}/>
                    <img src={mediqFavicon} alt={Localizer.wizardIconsStepCompanyFaviconAlt}/>
                    <img src={ebisusFavicon} alt={Localizer.wizardIconsStepCompanyFaviconAlt}/>
                    <img src={boatFavicon} alt={Localizer.wizardIconsStepCompanyFaviconAlt}/>
                </div>
                <hr/>
                
                <p>{Localizer.wizardIconsStepLogoIconSmallPlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.logoIconSmall  || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxLogoIconSizeInBytes}
                            onChange={(sender, value) => this.setIconSmallAsync(value)}
                            convertImage={async (image: FileModel) => await PortalController.convertImageAsync(image)}
                />
                
                <p>{Localizer.wizardIconsStepLogoIconLargePlace}</p>
                <ImageInput minimizeOnEmpty
                            className={wizardStyles.item}
                            pictures={this.customerImages.logoIconLarge  || []}
                            maxImageRequestSizeInBytes={PortalConstants.maxLogoIconSizeInBytes}
                            onChange={(sender, value) => this.setIconLargeAsync(value)}
                            convertImage={async (image: FileModel) => await PortalController.convertImageAsync(image)}
                />
                <p>{Localizer.wizardIconsStepExamplesPlace}</p>
                <div className={this.css(wizardStyles.imageItem, styles.iconContainer)}>
                    <img src={weAreSmallIcon} alt={Localizer.wizardIconsStepCompanyLogoIconAlt}/>
                    <img src={mediqSmallIcon} alt={Localizer.wizardIconsStepCompanyLogoIconAlt}/>
                    <img src={boatSmallIcon} alt={Localizer.wizardIconsStepCompanyLogoIconAlt}/>
                </div>
                
            </Form>
        );
    }

}