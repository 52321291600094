// autogenerated

export default class Organization {

    public id: string = "";

    public name: string = "";

    public vatId: string = "";

    public country: string | null = null;

    public favorite: boolean = false;

    public readonly isOrganization: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Organization) || ((from as any).isOrganization === true));
    }

    public static as(from?: object | null): Organization | null {
        return (Organization.is(from)) ? from as Organization : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}