// autogenerated

export default class CustomerApplicationFonts {

    public primaryFontName: string | null = null;

    public secondaryFontName: string | null = null;

    public primaryFontSourceFile: string | null = null;

    public secondaryFontSourceFile: string | null = null;

    public readonly isCustomerApplicationFonts: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerApplicationFonts) || ((from as any).isCustomerApplicationFonts === true));
    }

    public static as(from?: object | null): CustomerApplicationFonts | null {
        return (CustomerApplicationFonts.is(from)) ? from as CustomerApplicationFonts : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}