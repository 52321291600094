import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    PageContainer,
    PageHeader,
    PageRow,
    Tab,
    TabContainer,
    TabRenderType,
} from "@reapptor-apps/reapptor-react-components";
import DailyResourcesCostsPanel from "@/pages/ResourcesCosts/DailyResourcesCostsPanel/DailyResourcesCostsPanel";
import ResourcesPanel from "./ResourcesPanel/ResourcesPanel";
import Localizer from "@/localization/Localizer";

import styles from "./ResourcesCosts.module.scss";

interface IResourcesCostsProps {
}

interface IResourcesCostsState {
}

export default class ResourcesCosts extends AuthorizedPage<IResourcesCostsProps, IResourcesCostsState> {

    state: IResourcesCostsState = {
    };
    
    public getTitle(): string {
        return Localizer.adminResourcesCostsPageTitle;
    }

    public render(): React.ReactNode {

        return (
            <PageContainer className={styles.resourcesCosts} fullHeight>
                <PageHeader title={Localizer.adminResourcesCostsPageTitle} />

                <PageRow className={styles.row}>

                    <TabContainer id="resourcesCostsTabs" renderType={TabRenderType.ActiveOnly}>

                        <Tab id="dailyCosts" title={Localizer.adminResourcesCostsPageTabDailyCosts}>

                            <DailyResourcesCostsPanel/>

                        </Tab>

                        <Tab id="resources" title={Localizer.adminResourcesCostsPageTabResources}>
                                
                            <ResourcesPanel/>
                            
                        </Tab>

                    </TabContainer>

                </PageRow>

            </PageContainer>
        );
    }
}