//Autogenerated

import {BaseEnumProvider, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {SelectListItem} from "@reapptor-apps/reapptor-react-components";
import {WebApplicationType, PasswordValidationError, TokenType} from "@reapptor-apps/reapptor-react-common";
import {MonitorEventType, ProductAccessoryType, ProductPeriodType, ProductInvoicingModel, ProductUnit, EnvironmentStatus, RepositoryType, AccessPermissions, BitbucketBranchName, TerraformVariableCategory, InfraEnvironmentType, InfraTaskType, InfraAutomationStepType, LoginResultStatus, OrganizationContractLevel, OrganizationContractType, UserRoleGroup, SavePasswordResultStatus, InvitationType, NotificationType, AuditTimestamp, AuthType, ApplicationLinkType, AwsResourceGroup, AwsResourceSharingStatus, ResourceEnvironment, MonitorType, DayOfWeek} from "@/models/Enums";

class EnumProvider extends BaseEnumProvider<SelectListItem> {

    // #region Private
    
    private readonly _types: string[] = ["MonitorEventType", "ProductAccessoryType", "ProductPeriodType", "ProductInvoicingModel", "ProductUnit", "EnvironmentStatus", "RepositoryType", "AccessPermissions", "BitbucketBranchName", "TerraformVariableCategory", "InfraEnvironmentType", "InfraTaskType", "InfraAutomationStepType", "LoginResultStatus", "OrganizationContractLevel", "OrganizationContractType", "UserRoleGroup", "SavePasswordResultStatus", "InvitationType", "NotificationType", "AuditTimestamp", "AuthType", "SortDirection", "ApplicationLinkType", "AwsResourceGroup", "AwsResourceSharingStatus", "ResourceEnvironment", "MonitorType", "WebApplicationType", "PasswordValidationError", "TokenType", "DayOfWeek"];

    protected get types(): readonly string[] {
        return this._types;
    }

    protected createSelectListItem(value: string, text: string, subtext: string): SelectListItem {
        return new SelectListItem(value, text, subtext);
    }
    
    // #endregion
    
    constructor() {
        super();
    }

    // #region MonitorEventType

    public getMonitorEventTypeItem(value: MonitorEventType): SelectListItem {
        return this.transform(MonitorEventType, "MonitorEventType", value);
    }

    public getMonitorEventTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MonitorEventType, "MonitorEventType", reverse);
    }

    public getMonitorEventTypeName(value: MonitorEventType): string {
        return this.getMonitorEventTypeItem(value).text;
    }

    public getMonitorEventTypeText(value: MonitorEventType): string {
        return this.localizer.get(this.getMonitorEventTypeName(value));
    }

    public getMonitorEventTypeDescription(value: MonitorEventType): string {
        return this.getMonitorEventTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductAccessoryType

    public getProductAccessoryTypeItem(value: ProductAccessoryType): SelectListItem {
        return this.transform(ProductAccessoryType, "ProductAccessoryType", value);
    }

    public getProductAccessoryTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductAccessoryType, "ProductAccessoryType", reverse);
    }

    public getProductAccessoryTypeName(value: ProductAccessoryType): string {
        return this.getProductAccessoryTypeItem(value).text;
    }

    public getProductAccessoryTypeText(value: ProductAccessoryType): string {
        return this.localizer.get(this.getProductAccessoryTypeName(value));
    }

    public getProductAccessoryTypeDescription(value: ProductAccessoryType): string {
        return this.getProductAccessoryTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductPeriodType

    public getProductPeriodTypeItem(value: ProductPeriodType): SelectListItem {
        return this.transform(ProductPeriodType, "ProductPeriodType", value);
    }

    public getProductPeriodTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductPeriodType, "ProductPeriodType", reverse);
    }

    public getProductPeriodTypeName(value: ProductPeriodType): string {
        return this.getProductPeriodTypeItem(value).text;
    }

    public getProductPeriodTypeText(value: ProductPeriodType): string {
        return this.localizer.get(this.getProductPeriodTypeName(value));
    }

    public getProductPeriodTypeDescription(value: ProductPeriodType): string {
        return this.getProductPeriodTypeItem(value).subtext;
    }

    // #endregion

    // #region ProductInvoicingModel

    public getProductInvoicingModelItem(value: ProductInvoicingModel): SelectListItem {
        return this.transform(ProductInvoicingModel, "ProductInvoicingModel", value);
    }

    public getProductInvoicingModelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductInvoicingModel, "ProductInvoicingModel", reverse);
    }

    public getProductInvoicingModelName(value: ProductInvoicingModel): string {
        return this.getProductInvoicingModelItem(value).text;
    }

    public getProductInvoicingModelText(value: ProductInvoicingModel): string {
        return this.localizer.get(this.getProductInvoicingModelName(value));
    }

    public getProductInvoicingModelDescription(value: ProductInvoicingModel): string {
        return this.getProductInvoicingModelItem(value).subtext;
    }

    // #endregion

    // #region ProductUnit

    public getProductUnitItem(value: ProductUnit): SelectListItem {
        return this.transform(ProductUnit, "ProductUnit", value);
    }

    public getProductUnitItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ProductUnit, "ProductUnit", reverse);
    }

    public getProductUnitName(value: ProductUnit): string {
        return this.getProductUnitItem(value).text;
    }

    public getProductUnitText(value: ProductUnit): string {
        return this.localizer.get(this.getProductUnitName(value));
    }

    public getProductUnitDescription(value: ProductUnit): string {
        return this.getProductUnitItem(value).subtext;
    }

    // #endregion

    // #region EnvironmentStatus

    public getEnvironmentStatusItem(value: EnvironmentStatus): SelectListItem {
        return this.transform(EnvironmentStatus, "EnvironmentStatus", value);
    }

    public getEnvironmentStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(EnvironmentStatus, "EnvironmentStatus", reverse);
    }

    public getEnvironmentStatusName(value: EnvironmentStatus): string {
        return this.getEnvironmentStatusItem(value).text;
    }

    public getEnvironmentStatusText(value: EnvironmentStatus): string {
        return this.localizer.get(this.getEnvironmentStatusName(value));
    }

    public getEnvironmentStatusDescription(value: EnvironmentStatus): string {
        return this.getEnvironmentStatusItem(value).subtext;
    }

    // #endregion

    // #region RepositoryType

    public getRepositoryTypeItem(value: RepositoryType): SelectListItem {
        return this.transform(RepositoryType, "RepositoryType", value);
    }

    public getRepositoryTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(RepositoryType, "RepositoryType", reverse);
    }

    public getRepositoryTypeName(value: RepositoryType): string {
        return this.getRepositoryTypeItem(value).text;
    }

    public getRepositoryTypeText(value: RepositoryType): string {
        return this.localizer.get(this.getRepositoryTypeName(value));
    }

    public getRepositoryTypeDescription(value: RepositoryType): string {
        return this.getRepositoryTypeItem(value).subtext;
    }

    // #endregion

    // #region AccessPermissions

    public getAccessPermissionsItem(value: AccessPermissions): SelectListItem {
        return this.transform(AccessPermissions, "AccessPermissions", value);
    }

    public getAccessPermissionsItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AccessPermissions, "AccessPermissions", reverse);
    }

    public getAccessPermissionsName(value: AccessPermissions): string {
        return this.getAccessPermissionsItem(value).text;
    }

    public getAccessPermissionsText(value: AccessPermissions): string {
        return this.localizer.get(this.getAccessPermissionsName(value));
    }

    public getAccessPermissionsDescription(value: AccessPermissions): string {
        return this.getAccessPermissionsItem(value).subtext;
    }

    // #endregion

    // #region BitbucketBranchName

    public getBitbucketBranchNameItem(value: BitbucketBranchName): SelectListItem {
        return this.transform(BitbucketBranchName, "BitbucketBranchName", value);
    }

    public getBitbucketBranchNameItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(BitbucketBranchName, "BitbucketBranchName", reverse);
    }

    public getBitbucketBranchNameName(value: BitbucketBranchName): string {
        return this.getBitbucketBranchNameItem(value).text;
    }

    public getBitbucketBranchNameText(value: BitbucketBranchName): string {
        return this.localizer.get(this.getBitbucketBranchNameName(value));
    }

    public getBitbucketBranchNameDescription(value: BitbucketBranchName): string {
        return this.getBitbucketBranchNameItem(value).subtext;
    }

    // #endregion

    // #region TerraformVariableCategory

    public getTerraformVariableCategoryItem(value: TerraformVariableCategory): SelectListItem {
        return this.transform(TerraformVariableCategory, "TerraformVariableCategory", value);
    }

    public getTerraformVariableCategoryItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TerraformVariableCategory, "TerraformVariableCategory", reverse);
    }

    public getTerraformVariableCategoryName(value: TerraformVariableCategory): string {
        return this.getTerraformVariableCategoryItem(value).text;
    }

    public getTerraformVariableCategoryText(value: TerraformVariableCategory): string {
        return this.localizer.get(this.getTerraformVariableCategoryName(value));
    }

    public getTerraformVariableCategoryDescription(value: TerraformVariableCategory): string {
        return this.getTerraformVariableCategoryItem(value).subtext;
    }

    // #endregion

    // #region InfraEnvironmentType

    public getInfraEnvironmentTypeItem(value: InfraEnvironmentType): SelectListItem {
        return this.transform(InfraEnvironmentType, "InfraEnvironmentType", value);
    }

    public getInfraEnvironmentTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InfraEnvironmentType, "InfraEnvironmentType", reverse);
    }

    public getInfraEnvironmentTypeName(value: InfraEnvironmentType): string {
        return this.getInfraEnvironmentTypeItem(value).text;
    }

    public getInfraEnvironmentTypeText(value: InfraEnvironmentType): string {
        return this.localizer.get(this.getInfraEnvironmentTypeName(value));
    }

    public getInfraEnvironmentTypeDescription(value: InfraEnvironmentType): string {
        return this.getInfraEnvironmentTypeItem(value).subtext;
    }

    // #endregion

    // #region InfraTaskType

    public getInfraTaskTypeItem(value: InfraTaskType): SelectListItem {
        return this.transform(InfraTaskType, "InfraTaskType", value);
    }

    public getInfraTaskTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InfraTaskType, "InfraTaskType", reverse);
    }

    public getInfraTaskTypeName(value: InfraTaskType): string {
        return this.getInfraTaskTypeItem(value).text;
    }

    public getInfraTaskTypeText(value: InfraTaskType): string {
        return this.localizer.get(this.getInfraTaskTypeName(value));
    }

    public getInfraTaskTypeDescription(value: InfraTaskType): string {
        return this.getInfraTaskTypeItem(value).subtext;
    }

    // #endregion

    // #region InfraAutomationStepType

    public getInfraAutomationStepTypeItem(value: InfraAutomationStepType): SelectListItem {
        return this.transform(InfraAutomationStepType, "InfraAutomationStepType", value);
    }

    public getInfraAutomationStepTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InfraAutomationStepType, "InfraAutomationStepType", reverse);
    }

    public getInfraAutomationStepTypeName(value: InfraAutomationStepType): string {
        return this.getInfraAutomationStepTypeItem(value).text;
    }

    public getInfraAutomationStepTypeText(value: InfraAutomationStepType): string {
        return this.localizer.get(this.getInfraAutomationStepTypeName(value));
    }

    public getInfraAutomationStepTypeDescription(value: InfraAutomationStepType): string {
        return this.getInfraAutomationStepTypeItem(value).subtext;
    }

    // #endregion

    // #region LoginResultStatus

    public getLoginResultStatusItem(value: LoginResultStatus): SelectListItem {
        return this.transform(LoginResultStatus, "LoginResultStatus", value);
    }

    public getLoginResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(LoginResultStatus, "LoginResultStatus", reverse);
    }

    public getLoginResultStatusName(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).text;
    }

    public getLoginResultStatusText(value: LoginResultStatus): string {
        return this.localizer.get(this.getLoginResultStatusName(value));
    }

    public getLoginResultStatusDescription(value: LoginResultStatus): string {
        return this.getLoginResultStatusItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractLevel

    public getOrganizationContractLevelItem(value: OrganizationContractLevel): SelectListItem {
        return this.transform(OrganizationContractLevel, "OrganizationContractLevel", value);
    }

    public getOrganizationContractLevelItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractLevel, "OrganizationContractLevel", reverse);
    }

    public getOrganizationContractLevelName(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).text;
    }

    public getOrganizationContractLevelText(value: OrganizationContractLevel): string {
        return this.localizer.get(this.getOrganizationContractLevelName(value));
    }

    public getOrganizationContractLevelDescription(value: OrganizationContractLevel): string {
        return this.getOrganizationContractLevelItem(value).subtext;
    }

    // #endregion

    // #region OrganizationContractType

    public getOrganizationContractTypeItem(value: OrganizationContractType): SelectListItem {
        return this.transform(OrganizationContractType, "OrganizationContractType", value);
    }

    public getOrganizationContractTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(OrganizationContractType, "OrganizationContractType", reverse);
    }

    public getOrganizationContractTypeName(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).text;
    }

    public getOrganizationContractTypeText(value: OrganizationContractType): string {
        return this.localizer.get(this.getOrganizationContractTypeName(value));
    }

    public getOrganizationContractTypeDescription(value: OrganizationContractType): string {
        return this.getOrganizationContractTypeItem(value).subtext;
    }

    // #endregion

    // #region UserRoleGroup

    public getUserRoleGroupItem(value: UserRoleGroup): SelectListItem {
        return this.transform(UserRoleGroup, "UserRoleGroup", value);
    }

    public getUserRoleGroupItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(UserRoleGroup, "UserRoleGroup", reverse);
    }

    public getUserRoleGroupName(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).text;
    }

    public getUserRoleGroupText(value: UserRoleGroup): string {
        return this.localizer.get(this.getUserRoleGroupName(value));
    }

    public getUserRoleGroupDescription(value: UserRoleGroup): string {
        return this.getUserRoleGroupItem(value).subtext;
    }

    // #endregion

    // #region SavePasswordResultStatus

    public getSavePasswordResultStatusItem(value: SavePasswordResultStatus): SelectListItem {
        return this.transform(SavePasswordResultStatus, "SavePasswordResultStatus", value);
    }

    public getSavePasswordResultStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SavePasswordResultStatus, "SavePasswordResultStatus", reverse);
    }

    public getSavePasswordResultStatusName(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).text;
    }

    public getSavePasswordResultStatusText(value: SavePasswordResultStatus): string {
        return this.localizer.get(this.getSavePasswordResultStatusName(value));
    }

    public getSavePasswordResultStatusDescription(value: SavePasswordResultStatus): string {
        return this.getSavePasswordResultStatusItem(value).subtext;
    }

    // #endregion

    // #region InvitationType

    public getInvitationTypeItem(value: InvitationType): SelectListItem {
        return this.transform(InvitationType, "InvitationType", value);
    }

    public getInvitationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(InvitationType, "InvitationType", reverse);
    }

    public getInvitationTypeName(value: InvitationType): string {
        return this.getInvitationTypeItem(value).text;
    }

    public getInvitationTypeText(value: InvitationType): string {
        return this.localizer.get(this.getInvitationTypeName(value));
    }

    public getInvitationTypeDescription(value: InvitationType): string {
        return this.getInvitationTypeItem(value).subtext;
    }

    // #endregion

    // #region NotificationType

    public getNotificationTypeItem(value: NotificationType): SelectListItem {
        return this.transform(NotificationType, "NotificationType", value);
    }

    public getNotificationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(NotificationType, "NotificationType", reverse);
    }

    public getNotificationTypeName(value: NotificationType): string {
        return this.getNotificationTypeItem(value).text;
    }

    public getNotificationTypeText(value: NotificationType): string {
        return this.localizer.get(this.getNotificationTypeName(value));
    }

    public getNotificationTypeDescription(value: NotificationType): string {
        return this.getNotificationTypeItem(value).subtext;
    }

    // #endregion

    // #region AuditTimestamp

    public getAuditTimestampItem(value: AuditTimestamp): SelectListItem {
        return this.transform(AuditTimestamp, "AuditTimestamp", value);
    }

    public getAuditTimestampItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuditTimestamp, "AuditTimestamp", reverse);
    }

    public getAuditTimestampName(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).text;
    }

    public getAuditTimestampText(value: AuditTimestamp): string {
        return this.localizer.get(this.getAuditTimestampName(value));
    }

    public getAuditTimestampDescription(value: AuditTimestamp): string {
        return this.getAuditTimestampItem(value).subtext;
    }

    // #endregion

    // #region AuthType

    public getAuthTypeItem(value: AuthType): SelectListItem {
        return this.transform(AuthType, "AuthType", value);
    }

    public getAuthTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AuthType, "AuthType", reverse);
    }

    public getAuthTypeName(value: AuthType): string {
        return this.getAuthTypeItem(value).text;
    }

    public getAuthTypeText(value: AuthType): string {
        return this.localizer.get(this.getAuthTypeName(value));
    }

    public getAuthTypeDescription(value: AuthType): string {
        return this.getAuthTypeItem(value).subtext;
    }

    // #endregion

    // #region SortDirection

    public getSortDirectionItem(value: SortDirection): SelectListItem {
        return this.transform(SortDirection, "SortDirection", value);
    }

    public getSortDirectionItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(SortDirection, "SortDirection", reverse);
    }

    public getSortDirectionName(value: SortDirection): string {
        return this.getSortDirectionItem(value).text;
    }

    public getSortDirectionText(value: SortDirection): string {
        return this.localizer.get(this.getSortDirectionName(value));
    }

    public getSortDirectionDescription(value: SortDirection): string {
        return this.getSortDirectionItem(value).subtext;
    }

    // #endregion

    // #region ApplicationLinkType

    public getApplicationLinkTypeItem(value: ApplicationLinkType): SelectListItem {
        return this.transform(ApplicationLinkType, "ApplicationLinkType", value);
    }

    public getApplicationLinkTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ApplicationLinkType, "ApplicationLinkType", reverse);
    }

    public getApplicationLinkTypeName(value: ApplicationLinkType): string {
        return this.getApplicationLinkTypeItem(value).text;
    }

    public getApplicationLinkTypeText(value: ApplicationLinkType): string {
        return this.localizer.get(this.getApplicationLinkTypeName(value));
    }

    public getApplicationLinkTypeDescription(value: ApplicationLinkType): string {
        return this.getApplicationLinkTypeItem(value).subtext;
    }

    // #endregion

    // #region AwsResourceGroup

    public getAwsResourceGroupItem(value: AwsResourceGroup): SelectListItem {
        return this.transform(AwsResourceGroup, "AwsResourceGroup", value);
    }

    public getAwsResourceGroupItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AwsResourceGroup, "AwsResourceGroup", reverse);
    }

    public getAwsResourceGroupName(value: AwsResourceGroup): string {
        return this.getAwsResourceGroupItem(value).text;
    }

    public getAwsResourceGroupText(value: AwsResourceGroup): string {
        return this.localizer.get(this.getAwsResourceGroupName(value));
    }

    public getAwsResourceGroupDescription(value: AwsResourceGroup): string {
        return this.getAwsResourceGroupItem(value).subtext;
    }

    // #endregion

    // #region AwsResourceSharingStatus

    public getAwsResourceSharingStatusItem(value: AwsResourceSharingStatus): SelectListItem {
        return this.transform(AwsResourceSharingStatus, "AwsResourceSharingStatus", value);
    }

    public getAwsResourceSharingStatusItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(AwsResourceSharingStatus, "AwsResourceSharingStatus", reverse);
    }

    public getAwsResourceSharingStatusName(value: AwsResourceSharingStatus): string {
        return this.getAwsResourceSharingStatusItem(value).text;
    }

    public getAwsResourceSharingStatusText(value: AwsResourceSharingStatus): string {
        return this.localizer.get(this.getAwsResourceSharingStatusName(value));
    }

    public getAwsResourceSharingStatusDescription(value: AwsResourceSharingStatus): string {
        return this.getAwsResourceSharingStatusItem(value).subtext;
    }

    // #endregion

    // #region ResourceEnvironment

    public getResourceEnvironmentItem(value: ResourceEnvironment): SelectListItem {
        return this.transform(ResourceEnvironment, "ResourceEnvironment", value);
    }

    public getResourceEnvironmentItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(ResourceEnvironment, "ResourceEnvironment", reverse);
    }

    public getResourceEnvironmentName(value: ResourceEnvironment): string {
        return this.getResourceEnvironmentItem(value).text;
    }

    public getResourceEnvironmentText(value: ResourceEnvironment): string {
        return this.localizer.get(this.getResourceEnvironmentName(value));
    }

    public getResourceEnvironmentDescription(value: ResourceEnvironment): string {
        return this.getResourceEnvironmentItem(value).subtext;
    }

    // #endregion

    // #region MonitorType

    public getMonitorTypeItem(value: MonitorType): SelectListItem {
        return this.transform(MonitorType, "MonitorType", value);
    }

    public getMonitorTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(MonitorType, "MonitorType", reverse);
    }

    public getMonitorTypeName(value: MonitorType): string {
        return this.getMonitorTypeItem(value).text;
    }

    public getMonitorTypeText(value: MonitorType): string {
        return this.localizer.get(this.getMonitorTypeName(value));
    }

    public getMonitorTypeDescription(value: MonitorType): string {
        return this.getMonitorTypeItem(value).subtext;
    }

    // #endregion

    // #region WebApplicationType

    public getWebApplicationTypeItem(value: WebApplicationType): SelectListItem {
        return this.transform(WebApplicationType, "WebApplicationType", value);
    }

    public getWebApplicationTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(WebApplicationType, "WebApplicationType", reverse);
    }

    public getWebApplicationTypeName(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).text;
    }

    public getWebApplicationTypeText(value: WebApplicationType): string {
        return this.localizer.get(this.getWebApplicationTypeName(value));
    }

    public getWebApplicationTypeDescription(value: WebApplicationType): string {
        return this.getWebApplicationTypeItem(value).subtext;
    }

    // #endregion

    // #region PasswordValidationError

    public getPasswordValidationErrorItem(value: PasswordValidationError): SelectListItem {
        return this.transform(PasswordValidationError, "PasswordValidationError", value);
    }

    public getPasswordValidationErrorItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(PasswordValidationError, "PasswordValidationError", reverse);
    }

    public getPasswordValidationErrorName(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).text;
    }

    public getPasswordValidationErrorText(value: PasswordValidationError): string {
        return this.localizer.get(this.getPasswordValidationErrorName(value));
    }

    public getPasswordValidationErrorDescription(value: PasswordValidationError): string {
        return this.getPasswordValidationErrorItem(value).subtext;
    }

    // #endregion

    // #region TokenType

    public getTokenTypeItem(value: TokenType): SelectListItem {
        return this.transform(TokenType, "TokenType", value);
    }

    public getTokenTypeItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(TokenType, "TokenType", reverse);
    }

    public getTokenTypeName(value: TokenType): string {
        return this.getTokenTypeItem(value).text;
    }

    public getTokenTypeText(value: TokenType): string {
        return this.localizer.get(this.getTokenTypeName(value));
    }

    public getTokenTypeDescription(value: TokenType): string {
        return this.getTokenTypeItem(value).subtext;
    }

    // #endregion

    // #region DayOfWeek

    public getDayOfWeekItem(value: DayOfWeek): SelectListItem {
        return this.transform(DayOfWeek, "DayOfWeek", value);
    }

    public getDayOfWeekItems(reverse: boolean = false): SelectListItem[] {
        return this.getItems(DayOfWeek, "DayOfWeek", reverse);
    }

    public getDayOfWeekName(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).text;
    }

    public getDayOfWeekText(value: DayOfWeek): string {
        return this.localizer.get(this.getDayOfWeekName(value));
    }

    public getDayOfWeekDescription(value: DayOfWeek): string {
        return this.getDayOfWeekItem(value).subtext;
    }

    // #endregion
}

//Singleton
export default new EnumProvider();