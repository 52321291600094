import React from "react";
import {ApiProvider, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, Form, IconStyle, IStringInputModel, PageContainer, PasswordInput, TextInput, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import LoginRequest from "../../models/server/requests/LoginRequest";
import { LoginResultStatus } from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import EnumProvider from "@/providers/EnumProvider";
import PortalController from "@/pages/PortalController";
import Localizer from "../../localization/Localizer";

import styles from "./Login.module.scss";
import portalStyles from "../../portal.module.scss";

export default class Login extends AnonymousPage {

    private _usernameInput: IStringInputModel = { value: "" };
    private _passwordInput: IStringInputModel = { value: "" };
    public _formRef: React.RefObject<any> = React.createRef();
     
    private async loginAsync(username: string, password: string): Promise<void> {
        
        const login = new LoginRequest(username, password);
        const loginResult: LoginResultStatus = await this.postAsync("/api/Application/Login", login);
        
        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }
    }

    public getTitle(): string {
        return PortalController.getFullPageTitle(Localizer.loginPageTitle);
    }

    private async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._usernameInput.value, this._passwordInput.value);
    }
    
    private async redirectToPasswordPage() {
        await PageRouteProvider.redirectAsync(PageDefinitions.forgotPasswordRoute);
    }

    private async onAzureClickAsync(): Promise<void> {
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>("/api/Application/GetAzureSsoLogin"), true);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.container}>

                <LdDataHeader canonical={"/login"} />

                <h1 className={portalStyles.title}>{Localizer.loginPageTitle}</h1>
                
                <Form id="form" onSubmit={async () => await this.handleSubmitAsync()} ref={this._formRef}>
                    
                    <TextInput id="username" label={Localizer.loginPageEmailInput} model={this._usernameInput} required />

                    <PasswordInput id="password" label={Localizer.loginPagePasswordInput} model={this._passwordInput} required />

                    <div className={styles.buttonsContainer}>

                        <Button submit
                                label={Localizer.loginPageLoginButton}
                        />

                        {
                            (!this.mobileApp) &&
                            (
                                <Button icon={{ name: "windows", style: IconStyle.Brands }}
                                        label={Localizer.loginPageSsoButton}
                                        onClick={async () => await this.onAzureClickAsync()}
                                />
                            )
                        }
                        
                        <a onClick={() => this.redirectToPasswordPage()}>{Localizer.loginPageForgotPasswordButton}</a>

                    </div>
                    
                </Form>

            </PageContainer>
        );
    }
}