import React from "react";
import InfraResource from "@/models/server/InfraResource";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {ColumnDefinition, Grid} from "@reapptor-apps/reapptor-react-components";
import {AwsResourceGroup, AwsResourceSharingStatus, InfraEnvironmentType, ResourceEnvironment} from "@/models/Enums";
import GetInfraResourcesRequest from "@/models/server/requests/GetInfraResourcesRequest";
import Localizer from "@/localization/Localizer";

import styles from "./ResourcesPanel.module.scss";

interface IResourcesPanelProps {
}

interface IResourcesPanelState {
    resources: InfraResource[];
}

export default class ResourcesPanel extends BaseComponent<IResourcesPanelProps, IResourcesPanelState> {

    state: IResourcesPanelState = {
        resources: [],
    };

    private readonly _resourcesCostsColumns: ColumnDefinition[] = [
        {
            header: Localizer.adminResourcesCostsPageUnknownLanguageItemName,
            accessor: (model)  => model.unknown ? "✓" : "",
            minWidth: 40,
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageObsoleteLanguageItemName,
            accessor: (model)  => model.obsolete ? "✓" : "",
            minWidth: 40,
            textAlign: TextAlign.Center
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageTypeLanguageItemName,
            accessor: nameof<InfraResource>(o => o.type),
            minWidth: 40,
            format: nameof<AwsResourceGroup>(),
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageCustomerApplicationLanguageItemName,
            accessor: nameof<InfraResource>(o => o.organizationName),
            minWidth: 50,
            settings: {
                infoAccessor: nameof<InfraResource>(o => o.applicationName),
            },
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageEnvironmentTypeLanguageItemName,
            accessor: nameof<InfraResource>(o => o.environmentType),
            minWidth: 40,
            format: nameof<InfraEnvironmentType>(),
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageSharedLanguageItemName,
            accessor: nameof<InfraResource>(o => o.sharingStatus),
            minWidth: 40,
            format: nameof<AwsResourceSharingStatus>(),
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageAwsResourceLanguageItemName,
            accessor: nameof<InfraResource>(o => o.awsResourceId),
            minWidth: 90,
            settings: {
                infoAccessor: nameof<InfraResource>(o => o.awsResourceName),
            },
        } as ColumnDefinition,
        {
            header: Localizer.adminResourcesCostsPageServiceLanguageItemName,
            accessor: nameof<InfraResource>(o => o.awsResourceService),
            minWidth: 90
        } as ColumnDefinition,
    ];

    private get resources(): InfraResource[] {
        return this.state.resources;
    }

    public async initializeAsync(): Promise<void> {
        const request = new GetInfraResourcesRequest();

        const resources: InfraResource[] = await this.postAsync("/api/costExplorer/getResources", request);

        await this.setState({resources});
    }

    public render(): React.ReactNode {

        return (
            <div className={styles.grid}>

                <Grid responsive 
                      columns={this._resourcesCostsColumns}
                      data={this.resources}
                      noDataText={Localizer.genericNoData}
                />

            </div>
        );
    }
}