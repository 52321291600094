import React from "react";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import {Dropdown, DropdownRequiredType, Form} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import {ILanguage} from "@reapptor-apps/reapptor-toolkit";
import PortalController from "@/pages/PortalController";
import Localizer from "@/localization/Localizer";

import wizardStyles from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage.module.scss";

interface ILanguagesProps {
}

interface ILanguagesState {
}

export default class Languages extends CustomerApplicationWizardPage<ILanguagesProps, ILanguagesState> {

    state: ILanguagesState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get customerParameters(): CustomerApplicationParameters {
        return PortalController.wizard.customerParameters;
    }

    private async setLanguagesAsync(languageDropdown: Dropdown<ILanguage>): Promise<void> {
        this.customerParameters.languages = languageDropdown.selectedValues;
        this.saveContext();
        await this.reRenderAsync();
    }

    private async setDefaultLanguagesAsync(code: string): Promise<void> {
        this.customerParameters.defaultLanguage = code;
        this.saveContext();
    }

    private get selectedLanguages(): ILanguage[] {
        return Localizer.supportedLanguages.where(item => this.customerParameters.languages!.includes(item.code));
    }

    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync();
    }

    public getTitle(): string {
        return Localizer.wizardLanguagesStepTitle;
    }

    public get canNext(): boolean {
        return (!!this.customerParameters.languages?.length);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={wizardStyles.wizardStepForm}>

                <Dropdown id="languages" multiple required
                          requiredType={DropdownRequiredType.Restricted}
                          label={Localizer.wizardLanguagesStepDropdownLabel}
                          items={Localizer.supportedLanguages}
                          selectedItems={this.customerParameters.languages ?? []}
                          onChange={(sender) => this.setLanguagesAsync(sender)}
                />

                <Dropdown id="defaultLanguage" required selectedTextFormat
                          label={Localizer.wizardLanguagesStepDropdownDefaultLanguageLabel}
                          items={this.selectedLanguages}
                          selectedItem={this.customerParameters.defaultLanguage}
                          onChange={(sender, value) => this.setDefaultLanguagesAsync(value!.code)}
                />
                
            </Form>
        );
    }
}