import React from "react";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import {
    AddressDivider,
    AddressDividerColumns,
    AddressHelper,
    AutoSuggest,
    AutoSuggestItem,
    Form,
    RegexValidator,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import CustomerApplicationParameters from "@/models/server/infraautomation/CustomerApplicationParameters";
import CreateOrganizationRequest from "@/models/server/requests/CreateOrganizationRequest";
import CheckOrganizationUniquenessRequest from "@/models/server/requests/CheckOrganizationUniquenessRequest";
import {OrganizationUniquenessResponse} from "@/models/server/responses/OrganizationUniquenessResponse";
import PortalConstants from "@/PortalConstants";
import {ch} from "@reapptor-apps/reapptor-react-common";
import AvoinDataClient, {AvoinDataResponse, AvoinDataResponseItem} from "@/helpers/AvoinDataClient";
import {MaxLengthValidator} from "@/helpers/MaxLengthValidator";
import Localizer from "@/localization/Localizer";

import wizardStyles from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage.module.scss";
import styles from "@/pages/CustomerApplicationWizard/CreateOrganization/CreateOrganization.module.scss";

export interface ICreateOrganizationProps {
}

interface ICreateOrganizationState {
    autoSuggestedNames: AutoSuggestItem[];
    autoSuggestedVatIds: AutoSuggestItem[];
    avoinDataResponseItems: AvoinDataResponseItem[];
    location: GeoLocation | null,
    isModified: boolean;
}

export default class CreateOrganization extends CustomerApplicationWizardPage<ICreateOrganizationProps, ICreateOrganizationState> {

    state: ICreateOrganizationState = {
        autoSuggestedNames: [],
        autoSuggestedVatIds: [],
        avoinDataResponseItems: [],
        location: AddressHelper.toLocation(this.organization.formattedAddress),
        isModified: false,
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _autoSuggestNamesRef: React.RefObject<AutoSuggest> = React.createRef();

    private async checkOrganizationUniquenessAsync(): Promise<boolean> {
        const request = new CheckOrganizationUniquenessRequest();
        request.organizationName = this.organization.name!;
        request.organizationVatId = this.organization.vatId!;
        const response: OrganizationUniquenessResponse = await this.postAsync("/api/createOrganization/isOrganizationUnique", request);
        
        if (response == OrganizationUniquenessResponse.OrganizationNameExists) {
            const msg: string = Localizer.wizardCreateOrganizationStepCheckOrganizationNameUniqueness.format(this.organization.name);
            await ch.alertErrorAsync(msg, true);
        }

        if (response == OrganizationUniquenessResponse.OrganizationVatIdExists) {
            const msg: string = Localizer.wizardCreateOrganizationStepCheckOrganizationVatIdUniqueness.format(this.organization.vatId);
            await ch.alertErrorAsync(msg, true);
        }

        if (response == OrganizationUniquenessResponse.OrganizationExists) {
            const msg: string = Localizer.wizardCreateOrganizationStepCheckOrganizationNameAndVatIdUniqueness.format(this.organization.name, this.organization.vatId);
            await ch.alertErrorAsync(msg, true);
        }        

        return response == OrganizationUniquenessResponse.OrganizationNotExists;
    }

    public async autoFillOrganizationShortNameAsync(reRender: boolean = true): Promise<void> {
        if (!this.customerParameters.organizationShortName) {
            const defaultShortName = (this.customerParameters.organizationName)
                ? this.customerParameters.organizationName.replace(/ oy| ab | (?!a-zA-Z_0-9) /ig, "").toPascalCase()
                : null;
            
            await this.setShortNameAsync(defaultShortName);
            
            if (reRender) {
                await this.reRenderAsync();
            }
        }
    }

    private async searchOrganizationNameAsync(value: string): Promise<void> {
        if (value.trim() != "") {

            const result: AvoinDataResponse = await AvoinDataClient.listCompaniesByNameAsync(value);

            if (result != null) {
                if (result.results.length > 0) {
                    this.state.avoinDataResponseItems = result.results;
                    this.state.autoSuggestedNames = result.results.map(item => new AutoSuggestItem(item.name));

                    if ((this._autoSuggestNamesRef.current) && (!this._autoSuggestNamesRef.current.isOpen)) {
                        await this._autoSuggestNamesRef.current.toggleAsync();
                    }

                    await this.reRenderAsync();
                }
            }
        }
    }

    private async fillOrganizationAsync(value: string): Promise<void> {
        const item: AutoSuggestItem | undefined = this.state.autoSuggestedNames.find(item => item.value == value);
        if (item != null) {
            const index: number = this.state.autoSuggestedNames.indexOf(item);
            const avoinItem: AvoinDataResponseItem = this.state.avoinDataResponseItems[index];
            
            if (this.customerParameters.organizationName != avoinItem.name) {
                
                const address: string | null = await AvoinDataClient.extractAddressAsync(avoinItem);
                let location: GeoLocation | null = (address)
                    ? await this.postAsync("/api/geoLocation/findLocation", address)
                    : null;
                
                await this.setLocationAsync(location);
                await this.setShortNameAsync("");
                await this.setVatIdAsync(avoinItem.businessId);
                await this.setOrganizationNameAsync(avoinItem.name, false);
                await this.autoFillOrganizationShortNameAsync(false);
                
                await this.reRenderAsync();
            }
        }
    }

    private async setOrganizationNameAsync(value: string, search: boolean = true): Promise<void> {
        this.customerParameters.organizationName = value.trimLeft();
        this.organization.name = value;
        this.saveContext();

        if ((search) && (this.organization.country == PortalConstants.finlandCode)) {
            await this.searchOrganizationNameAsync(value);
        }
    }

    private async setVatIdAsync(vatId: string): Promise<void> {
        this.organization.vatId = vatId
        this.saveContext();
    }

    private async setShortNameAsync(value: string | null): Promise<void> {
        this.customerParameters.organizationShortName = value;
        this.saveContext();
    }

    private async setLocationAsync(location: GeoLocation | null): Promise<void> {
        this.state.location = location;
        this.organization.formattedAddress = (location)
            ? location.formattedAddress
            : null;
        this.saveContext();
    }

    protected async validateFormAsync(): Promise<boolean> {
        return (this._formRef.current != null) && (await this._formRef.current.validateAsync()) && (await this.checkOrganizationUniquenessAsync());
    }

    protected async validateAsync(): Promise<boolean> {
        return await this.validateFormAsync() && await super.validateAsync();
    }

    private get customerParameters(): CustomerApplicationParameters {
        return this.wizard.customerParameters;
    }

    private get organization(): CreateOrganizationRequest {
        return this.wizard.newOrganization;
    }

    public getTitle(): string {
        return Localizer.wizardCreateOrganizationStepTitle;
    }

    public async nextAsync(): Promise<void> {
        this.saveContext();
        await super.nextAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form ref={this._formRef} className={wizardStyles.wizardStepForm}>

                <TextInput id={"name"} required autoFocus noAutoComplete
                           validators={[RegexValidator.validator(PortalConstants.titleValidationRegex, Localizer.wizardCreateOrganizationStepOrganizationNameValidator)]}
                           label={Localizer.wizardCreateOrganizationStepOrganizationNamePlace}
                           className={wizardStyles.item}
                           value={this.organization.name || undefined}
                           onChange={(sender, value) => this.setOrganizationNameAsync(value)}
                           onBlur={() => this.autoFillOrganizationShortNameAsync()}
                />
                
                {(this.organization.country == PortalConstants.finlandCode) && this.renderNameAutoSuggest()}
                
                <TextInput id={"shortName"} required noAutoComplete trim
                           label={Localizer.wizardCreateOrganizationStepOrganizationShortName}
                           className={wizardStyles.item}
                           validators={[RegexValidator.validator(PortalConstants.organizationShortNameValidationRegex, Localizer.wizardCreateOrganizationStepOrganizationShortNameValidator),
                                        MaxLengthValidator.validator(PortalConstants.maxCompanyShortNameLength)]}
                           maxLength={PortalConstants.maxCompanyShortNameLength}
                           value={this.customerParameters.organizationShortName || undefined}
                           onChange={(sender, value) => this.setShortNameAsync(value)}
                />
                
                <TextInput id={"vatId"} required trim
                           label={Localizer.wizardCreateOrganizationStepOrganizationVatIdPlace}
                           className={wizardStyles.item}
                           value={this.organization.vatId || undefined}
                           onChange={(sender, value) => this.setVatIdAsync(value)}
                />
                
                <AddressDivider id={"address"} required
                                columns={AddressDividerColumns.One}
                                className={wizardStyles.item}
                                location={this.state.location || undefined}
                                country={this.organization.country || undefined}
                                onChange={async (sender, location) => this.setLocationAsync(location)}
                />

            </Form>
        );
    }

    private renderNameAutoSuggest(): React.ReactNode {
        if (this.state.autoSuggestedNames.length > 0) {
            return (
                <div className={styles.createOrganization}>
                    <AutoSuggest ref={this._autoSuggestNamesRef} isOpen
                                 items={this.state.autoSuggestedNames!}
                                 toggleButtonId={"toggle.name.id"}
                                 onSelect={(sender, value) => this.fillOrganizationAsync(value)}
                    />
                </div>
            )
        }
    }
}