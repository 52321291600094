import React from "react";
import {ColorInput, Form} from "@reapptor-apps/reapptor-react-components";
import CustomerApplicationWizardPage from "@/pages/CustomerApplicationWizard/CustomerApplicationWizardPage";
import CustomerApplicationColors from "@/models/server/infraautomation/CustomerApplicationColors";
import PortalController from "@/pages/PortalController";
import Localizer from "../../../localization/Localizer";

import wizardStyles from "./../CustomerApplicationWizardPage.module.scss";

export interface IColorsProps {
}

interface IColorsState {
}

export default class Colors extends CustomerApplicationWizardPage<IColorsProps, IColorsState> {

    state: IColorsState = {
    };
    
    public getTitle(): string {
        return Localizer.wizardColorsStepTitle;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    protected get customerColors(): CustomerApplicationColors {
        return PortalController.wizard.customerParameters.colors!;
    }

    public async setPickedPrimaryColorAsync(value: string): Promise<void> {
        this.customerColors.primaryColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPickedPrimaryHoverColorAsync(value: string): Promise<void> {
        this.customerColors.primaryHoverColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPickedPrimaryDisableColorAsync(value: string): Promise<void> {
        this.customerColors.primaryDisableColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPickedSecondaryColorAsync(value: string): Promise<void> {
        this.customerColors.secondaryColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPickedSecondaryHoverColorAsync(value: string): Promise<void> {
        this.customerColors.secondaryHoverColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public async setPickedSecondaryDisableColorAsync(value: string): Promise<void> {
        this.customerColors.secondaryDisableColor = value;
        this.saveContext();
        await this.reRenderAsync();
    }

    public renderContent(): React.ReactNode {
        return (
            <Form className={wizardStyles.wizardStepForm}>
                
                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.primaryColor}
                            label={Localizer.wizardColorsStepPrimaryColorPlace}
                            onChange={(sender, value) => this.setPickedPrimaryColorAsync(value)}
                />

                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.primaryHoverColor}
                            label={Localizer.wizardColorsStepPrimaryHoverColorPlace}
                            onChange={(sender, value) => this.setPickedPrimaryHoverColorAsync(value)}
                />                

                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.primaryDisableColor}
                            label={Localizer.wizardColorsStepPrimaryDisableColorPlace}
                            onChange={(sender, value) => this.setPickedPrimaryDisableColorAsync(value)}
                />

                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.secondaryColor}
                            label={Localizer.wizardColorsStepSecondaryColorPlace}
                            onChange={(sender, value) => this.setPickedSecondaryColorAsync(value)}
                />                

                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.secondaryHoverColor}
                            label={Localizer.wizardColorsStepSecondaryHoverColorPlace}
                            onChange={(sender, value) => this.setPickedSecondaryHoverColorAsync(value)}
                />

                <ColorInput className={this.css(wizardStyles.item, wizardStyles.colorPicker)}
                            value={this.customerColors.secondaryDisableColor}
                            label={Localizer.wizardColorsStepSecondaryDisableColorPlace}
                            onChange={(sender, value) => this.setPickedSecondaryDisableColorAsync(value)}
                />                

            </Form>
        );
    }
}