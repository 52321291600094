import React from "react";
import {BasePageParameters, ch, LdData, LocalizationString, PageRoute} from "@reapptor-apps/reapptor-react-common";
import {Icon, IconSize, PageContainer, PageRow, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import AnonymousPage from "@/models/base/AnonymousPage";
import ProductCard, {ProductCardMode} from "@/components/ProductCard/ProductCard";
import PageDefinitions from "@/providers/PageDefinitions";
import FindProductRequest from "@/models/server/requests/FindProductRequest";
import FindProductResponse from "@/models/server/responses/FindProductResponse";
import UserContext from "@/models/server/UserContext";
import Product from "@/models/server/products/Product";
import PortalController from "@/pages/PortalController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductDetails.module.scss";

export interface IProductDetailsProps extends BasePageParameters {
}

interface IProductDetailsState {
    product: Product | null;
    productLdData: LdData | null;
    edit: boolean;
}

export default class ProductDetails extends AnonymousPage<IProductDetailsProps, IProductDetailsState> {

    public state: IProductDetailsState = {
        product: null,
        productLdData: null,
        edit: false
    };

    private get userContext(): Readonly<UserContext> {
        return ch.getContext() as UserContext;
    }

    private get isAdmin(): boolean {
        return (this.userContext.isAdmin);
    }

    private get product(): Product | null {
        return this.state.product;
    }

    private get edit(): boolean {
        return this.state.edit;
    }

    private get productLdData(): LdData | null {
        return this.state.productLdData;
    }

    public async setEditAsync(): Promise<void> {
        await this.setState({edit: !this.edit});
    }

    public async getProductAsync(productIdOrPath: string): Promise<void> {

        const request = new FindProductRequest();
        request.pathOrId = productIdOrPath;

        const response: FindProductResponse = await this.postAsync("/api/product/findProduct", request);

        if (response.product == null) {
            await this.redirectHomeAsync();
            return;
        }

        await this.setState({product: response.product, productLdData: response.productLdData});

        window.document.title = this.getTitle();
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const productIdOrPath: string | null = this.routeId;

        if (!productIdOrPath) {
            await this.redirectHomeAsync();
            return;
        }

        await this.getProductAsync(productIdOrPath);
    }

    public getTitle(): string {
        const title: string = (this.product)
            ? LocalizationString.value(this.product.name)
            : Localizer.productDetailsPageTitle;
        
        return PortalController.getFullPageTitle(title);
    }

    public static routeResolver(pathname: string): PageRoute | null {
        const prefix: string = "/products/";

        if (pathname.toLowerCase().startsWith(prefix)) {
            const path: string = pathname.substring(prefix.length);
            return PageDefinitions.productDetails(path);
        }

        return null;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer fullHeight
                           className={styles.productDetails}
            >

                <LdDataHeader data={this.productLdData}
                              canonical={this.product?.path}
                />

                <PageRow>

                    <div className="col">

                        {
                            (this.isAdmin) &&
                            (
                                <Icon name={"fad fa-edit"}
                                      className={styles.editIcon}
                                      size={IconSize.X2}
                                      tooltip={Localizer.genericEdit}
                                      onClick={() => this.setEditAsync()}
                                />
                            )
                        }

                        {
                            (this.product) &&
                            (
                                <ProductCard mode={(!this.edit) ? ProductCardMode.Details : ProductCardMode.Management}
                                             product={this.product}
                                             onFinishEdit={() => this.setEditAsync()}
                                />
                            )
                        }

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}